import { createContext, useContext } from "react"
import { StoreApi, createStore, useStore } from "zustand"

interface ModalContent {
  title: string
  content: React.ReactNode | null
  closeLabel: string
  confirmLabel?: string
  onClose?: () => void
  onConfirm?: () => void | Promise<void>
}

export const AlertModalContext = createContext<StoreApi<AlertModalStore> | null>(null)

export interface AlertModalStore {
  currentModalId: string | null
  modalContent: Record<string, ModalContent>
  isLoading: boolean
  setCurrentModalId: (currentModalId: string | null) => void
  setModalContent: (key: string, modalContent: ModalContent) => void
}

export const createAlertModalStore = () =>
  createStore<AlertModalStore>(set => ({
    currentModalId: null,
    modalContent: {},
    isLoading: false,
    setCurrentModalId: currentModalId => set({ currentModalId }),
    setModalContent: (key, modalContent) =>
      set(state => ({
        modalContent: {
          ...state.modalContent,
          [key]: modalContent,
        },
      })),
  }))

export function useAlertModalStore<T>(selector: (store: AlertModalStore) => T) {
  const store = useContext(AlertModalContext)
  if (!store) {
    throw new Error("missing AlertModalProvider")
  }
  return useStore(store, selector)
}
