import { BaseAnalyticsEvent } from "infrastructure/apm/types"

export enum CaseAnalyticsEventTypes {
  ViewedCase = "Viewed Case",
  CaseDetailsTabClicked = "Case Details Tab Clicked",
  ListFetched = "Case List Fetched",
  CaseOpened = "Case Opened from List",
  CaseInsightsFormViewed = "Case Insights Form Viewed",
  CaseInsightsPublishClicked = "Case Insights Publish Clicked",
  PlaintiffChanged = "Case View Plaintiff Changed",
  MissingDocsListSort = "Missing Docs List Sort",
}

export class CaseAnalyticEvent<T extends CaseAnalyticsEventTypes> extends BaseAnalyticsEvent {
  constructor(
    readonly name: T,
    readonly fields: CaseAnalyticsEventDetails[T]
  ) {
    super()
  }
}

export type CaseViewEventFields = {
  firm_id?: PrimaryKey
  firm_name?: string
  request_id?: PrimaryKey
  request_type?: string
}

export type CaseDetailTab =
  | "Overview"
  | "Plaintiff"
  | "Defendant"
  | "Injuries"
  | "Policy"
  | "Recipient"
  | "Notes"

export type CaseAnalyticsEventDetails = {
  [CaseAnalyticsEventTypes.ViewedCase]: CaseViewEventFields & {
    utm_campaign?: string
    utm_medium?: string
  }
  [CaseAnalyticsEventTypes.CaseDetailsTabClicked]: CaseViewEventFields & {
    tab: CaseDetailTab
  }
  [CaseAnalyticsEventTypes.ListFetched]: {
    page: number
    page_size: number
    search_query?: string
    document_type?: string
    firm_ids?: string
    case_type?: string
    attorneys?: string
    creators?: string
    orderBy: string
  }
  [CaseAnalyticsEventTypes.CaseOpened]: {
    request_id: PrimaryKey
  }
  [CaseAnalyticsEventTypes.CaseInsightsFormViewed]: CaseViewEventFields
  [CaseAnalyticsEventTypes.CaseInsightsPublishClicked]: CaseViewEventFields
  [CaseAnalyticsEventTypes.PlaintiffChanged]: CaseViewEventFields & {
    plaintiff_id: string // TODO UUID
  }
  [CaseAnalyticsEventTypes.MissingDocsListSort]: {
    sort_by: string
    direction: "asc" | "desc"
  }
}
