import { useCallback, useEffect, useState, useMemo } from "react"
import { FileError, FileRejection } from "react-dropzone"

import { HTML5Backend } from "react-dnd-html5-backend"
import { DndProvider } from "react-dnd"
import { Box } from "@mui/material"
import partition from "lodash/partition"
import { useWatch } from "react-hook-form"

import { processZipFile } from "common/file-uploader/zip-files/zipFileProcessor"
import { ACCEPT_ALL_TYPE } from "common/form-components/files/constants"
import { FileToUploadType } from "common/form-components/files/interfaces"
import FileDropzone from "common/form-components/files/FileDropzone"
import { UseFileUploaderReturn } from "common/file-uploader"
import { requestFileValidator, PASSWORD_WARNING } from "common/form-components/files/ValidateFileAlert"

import { RequestFile } from "requests/types"

import { FileStatus, FileToUpload, SaveFileMutation } from "./types"
import { ClientSideFilesList } from "./ClientSideFilesList"
import { ServerSideFilesList } from "./ServerSideFilesList"
import { convertDroppedFileToRequestFile, isZipFile, uploadFiles } from "./fileUtils"
import { RequiredHiddenInput } from "./RequiredHiddenInput"
import useUser from "hooks/useUser"

/**
 * Responsibilities:
 * - Owner of the list of files that are uploaded, uploading, pending, and in an error state
 * - Handling logic around uploading & deleting files
 * - Error handling logic for uploading files
 *
 * Children Responsibilities:
 * - Displaying files selected for upload
 * - Displaying component files already uploaded
 * - Selecting Files
 */

type FormFilesProps = {
  onDeleteFile: (file: RequestFile) => void // Formfile?
  onViewFile: (file: RequestFile) => void
  onDownloadFile: (file: RequestFile) => void
  fileUploader: UseFileUploaderReturn
  saveFileMutation: SaveFileMutation
  isUploadingFiles: boolean
  setIsUploadingFiles: (value: boolean) => void
}

const validateAddedFiles = (
  newFiles: FileToUploadType[] | File[],
  fileUploader: UseFileUploaderReturn
): [(FileToUploadType | File)[], FileRejection[]] => {
  // Necessary check due to unzippedFiles possibly containing unsupported files
  const [approvedFiles, rejectedFiles] = partition(newFiles, (file: FileToUploadType | File) => {
    if (file instanceof File) {
      return !requestFileValidator(file, fileUploader)
    } else if (file.file instanceof File) {
      return !requestFileValidator(file.file, fileUploader)
    }
    return false
  })

  const rejected = rejectedFiles.map((file: FileToUploadType | File) => ({
    file: (file instanceof File ? file : file.file) || new File([], "unknown"),
  }))

  const rejectedWithErrors = rejected.map(rejectedFile => ({
    file: rejectedFile.file,
    errors: [requestFileValidator(rejectedFile.file, fileUploader) as FileError],
  }))

  return [approvedFiles, rejectedWithErrors]
}

// TODO: If/when we start adding more file status errors, we should move out the
// error mapping from the ValidateFileAlert to a separate file
const FILE_STATUS_ERRORS = [FileStatus.UploadError, FileStatus.UnzippingError]

export default function FormFiles({
  onDeleteFile,
  onViewFile,
  onDownloadFile,
  fileUploader,
  saveFileMutation,
  isUploadingFiles,
  setIsUploadingFiles,
}: FormFilesProps) {
  const [clientSideFiles, setClientSideFiles] = useState<FileToUpload[]>([])
  const [additionalFileRejections, setAdditionalFileRejections] = useState<FileRejection[]>([])
  const clearAdditionalFileRejections = useCallback(() => setAdditionalFileRejections([]), [])

  const handleAddFile = useCallback(
    (newFiles: FileToUploadType[] | File[]) => {
      // Another validation is necessary here to validate unzipped files
      // This is because HTML validation happens strictly on front-end
      // TODO, make file validation more consistent
      const [approvedFiles, rejected] = validateAddedFiles(newFiles, fileUploader)
      setAdditionalFileRejections(rejected)

      setClientSideFiles(clientSideFiles => {
        const mappedFiles = approvedFiles.map(convertDroppedFileToRequestFile)
        return [...clientSideFiles, ...mappedFiles]
      })
    },
    [fileUploader]
  )

  const handleDeleteFile = useCallback((fileToDelete: FileToUpload) => {
    setClientSideFiles(clientSideFiles => {
      const files = clientSideFiles.filter(clientSideFile => clientSideFile.file !== fileToDelete.file)
      return files
    })
  }, [])

  const handleUpdateFiles = useCallback((updatedFiles: FileToUpload[]) => {
    setClientSideFiles(clientSideFiles =>
      clientSideFiles.map(clientSideFile => {
        const updatedFile = updatedFiles.find(updatedFile => updatedFile.file === clientSideFile.file)
        return updatedFile ?? clientSideFile
      })
    )
  }, [])

  useEffect(() => {
    if (isUploadingFiles) {
      return
    }

    clientSideFiles.forEach(clientSideFile => {
      // Delete files that have finished processing
      if ([FileStatus.UnzippingComplete, FileStatus.UploadComplete].includes(clientSideFile.status)) {
        handleDeleteFile(clientSideFile)
        return
      }

      // Try to unzip files
      if (clientSideFile.status === FileStatus.Processing && isZipFile(clientSideFile.file)) {
        handleUpdateFiles([{ ...clientSideFile, status: FileStatus.Unzipping }])
        processZipFile(clientSideFile.file)
          .then(unzippedFiles => {
            handleAddFile(unzippedFiles)
            handleUpdateFiles([{ ...clientSideFile, status: FileStatus.UnzippingComplete }])
          })
          .catch(reason => {
            handleUpdateFiles([
              {
                ...clientSideFile,
                status: FileStatus.UnzippingError,
                error: `Failed to unzip. ${reason.toString()}`,
              },
            ])
          })
        return
      }

      if (clientSideFile.status === FileStatus.Processing) {
        handleUpdateFiles([{ ...clientSideFile, status: FileStatus.ReadyToUpload }])
      }
    })

    // Try to upload files
    if (!isUploadingFiles) {
      const filesToUpload = clientSideFiles.filter(file => file.status === FileStatus.ReadyToUpload)

      if (filesToUpload.length) {
        uploadFiles(filesToUpload, saveFileMutation, fileUploader, handleUpdateFiles, setIsUploadingFiles)
        handleUpdateFiles(filesToUpload.map(file => ({ ...file, status: FileStatus.Uploading })))
      }
    }
  }, [
    fileUploader,
    clientSideFiles,
    saveFileMutation,
    isUploadingFiles,
    handleAddFile,
    handleDeleteFile,
    handleUpdateFiles,
    setIsUploadingFiles,
  ])

  const files: RequestFile[] = useWatch({ name: "files", defaultValue: [] })

  // Only show files uploaded via the Request Form, not Missing Doc Uploads
  const uploadedFiles = useMemo(() => files.filter(file => file?.missing_exhibit_event === null), [files])
  const { user } = useUser()

  useEffect(() => {
    const rejectedFiles: FileRejection[] = clientSideFiles
      .filter(file => FILE_STATUS_ERRORS.includes(file.status))
      .map(({ file, error }) => {
        return {
          file,
          errors: [{ message: error, code: "400" }],
        }
      })

    if (rejectedFiles.length) {
      setAdditionalFileRejections(prev => {
        return [...prev, ...rejectedFiles]
      })

      // Remove files that are being handled by the new alert system
      setClientSideFiles(prev => {
        return prev.filter(file => !FILE_STATUS_ERRORS.includes(file.status))
      })
    }
  }, [clientSideFiles])

  const passwordProtectedFiles = uploadedFiles
    .filter(file => file.upload?.upload_processing_info === PASSWORD_WARNING)
    .map(file => ({
      file: new File([], file.name, { lastModified: new Date(file.date_created).getTime() }),
      errors: [{ message: PASSWORD_WARNING, code: "400" }],
    }))

  // Pins password protected alerts to be present all the time
  const allRejections = [...additionalFileRejections, ...passwordProtectedFiles]

  const requestFormFileValidator = useMemo(() => {
    return (file: File) => requestFileValidator(file, fileUploader)
  }, [fileUploader])

  return (
    <>
      <Box gridColumn="1/3">
        <DndProvider backend={HTML5Backend}>
          <FileDropzone
            onDrop={handleAddFile}
            acceptedFileTypes={ACCEPT_ALL_TYPE}
            disabled={isUploadingFiles}
            validator={requestFormFileValidator}
            additionalFileRejections={allRejections}
            clearAdditionalFileRejections={clearAdditionalFileRejections}
            showHelperText={false}
          >
            {clientSideFiles.length > 0 && (
              <ClientSideFilesList
                files={clientSideFiles}
                onDeleteFile={handleDeleteFile}
                handleUpdateFiles={handleUpdateFiles}
              />
            )}
          </FileDropzone>
        </DndProvider>
        {user.isExternal && (
          <RequiredHiddenInput
            hasInput={uploadedFiles.length > 0}
            errorMessage="Please upload at least one file to submit your request."
          />
        )}
      </Box>
      {uploadedFiles.length > 0 && (
        <ServerSideFilesList
          files={uploadedFiles}
          onDeleteFile={onDeleteFile}
          onViewFile={onViewFile}
          onDownloadFile={onDownloadFile}
          fileUploader={fileUploader}
        />
      )}
    </>
  )
}
