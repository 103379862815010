import { CASE_SECTIONS } from "common/types/sections"
import { CASE_SECTION_TO_FIELDS_MAPPING } from "api/services/case/serializers"

export const getBannerLabel = ({
  areSectionsUpdated,
  newTemplatesIntroduced,
  caseTemplatedSections,
}: {
  areSectionsUpdated?: boolean
  newTemplatesIntroduced?: boolean
  caseTemplatedSections?: CASE_SECTIONS[]
}) => {
  const caseTemplatedSectionsLabels = caseTemplatedSections?.map(
    section => CASE_SECTION_TO_FIELDS_MAPPING[section]?.display || section
  ) || ["Section"]

  const onlyOneSectionUpdated = caseTemplatedSectionsLabels?.length === 1

  return areSectionsUpdated
    ? `${caseTemplatedSectionsLabels?.join(", ")} template${onlyOneSectionUpdated ? " has" : "s have"} been updated${newTemplatesIntroduced ? ", and a new Document Structure is available" : ""}`
    : "A new Document Structure is available"
}
