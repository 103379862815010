import { UseMutationResult } from "@tanstack/react-query"
import { EmptyResponse } from "apiHelper"
import { FILE_CATEGORY_OPTIONS } from "common/constants"
import { RequestFile } from "requests/types"

export type SaveFileMutationData = {
  data: FormData
  name: string
}
export type SaveFileMutation = UseMutationResult<Response, unknown, SaveFileMutationData, unknown>

export type DeleteFileMutationData = {
  fileId: number
}
export type DeleteFileMutation = UseMutationResult<EmptyResponse, unknown, DeleteFileMutationData, unknown>

export enum FileStatus {
  "Processing" = "Processing", // We're figuring out what to do with it

  // Unzipping
  "Unzipping" = "Unzipping",
  "UnzippingComplete" = "UnzippingComplete",
  "UnzippingError" = "UnzippingError",

  "ReadyToUpload" = "ReadyToUpload", // Enqueued for Upload
  "Uploading" = "Uploading", // Trying to upload it
  "UploadError" = "UploadError", // There was an error trying to upload it
  "UploadComplete" = "UploadComplete",
}

export type FileToUpload = {
  file: File
  fileType: FILE_CATEGORY_OPTIONS
  status: FileStatus
  error: string
}

// TODO: RequestFile is used in a bunch of places; we should move the type here
// and updates all the instances
export type FileMetadata = RequestFile
