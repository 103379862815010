import { SummaryItemKey } from "documents/store/types"
import { UserExhibit } from "exhibit-builder/store/types"
import { Exhibit } from "documents/types"
import { CitationElement } from "common/form-components/rich-text/CustomEditor"
import { UseExhibitsPreviewReturn } from "documents/exhibits/useExhibitPreview"
import { UseUserExhibitPreviewReturn } from "documents/exhibits/useUserExhibitPreview"
import { CitationSource } from "common/form-components/rich-text/features/citations/types"

export function getSummaryItem(key: SummaryItemKey) {
  return key.split(":") as ["provider", string] | ["appointment", string, string]
}

export const previewCitationReference = ({
  citation,
  page,
  previewExhibit,
  previewUserExhibit,
}: {
  citation: CitationElement
  page: Nullable<number>
  previewExhibit: UseExhibitsPreviewReturn
  previewUserExhibit: UseUserExhibitPreviewReturn
}) => {
  // Citation body may have 2 ways of referencing:
  // 1. Exhibit reference (when Exhibit Builder Toggle is off)
  // 2. User exhibit reference (when Exhibit Builder Toggle is on)
  const { exhibit, pages, user_exhibit_id, user_exhibit_pages } = citation

  if (user_exhibit_id) return previewUserExhibit(user_exhibit_id, page ? page : user_exhibit_pages?.[0])

  if (exhibit) return previewExhibit(exhibit, page ? page : pages[0])
}

export const getCitationReferences = ({
  exhibits,
  userExhibits,
}: {
  exhibits: Exhibit[]
  userExhibits: UserExhibit[]
}): CitationSource[] => {
  const exhibitRefs = exhibits.map(({ id, pageCount, name }, idx) => ({
    exhibitId: id,
    index: idx,
    name: `Exhibit ${idx + 1} - ${name}`,
    pagesCount: typeof pageCount === "number" ? pageCount : 0,
  }))

  const userExhibitRefs = userExhibits.map(({ id, pageCount, name }, idx) => ({
    userExhibitId: id,
    name: `Exhibit ${idx + 1} - ${name}`,
    index: idx,
    pagesCount: typeof pageCount === "number" ? pageCount : 0,
  }))

  return [...exhibitRefs, ...userExhibitRefs]
}
