import { apiService } from "api/services/ApiService"
import { ApiServiceType } from "api/services/types"
import { withRequestSerializer, withResponseSerializer } from "api/withSerializers"
import { isUndefined } from "lodash"
import { ExhibitFile, ReorderExhibitPartitionPayload, UserExhibit } from "../store/types"
import { ExhibitBuilderDeserializer, ExhibitBuilderSerializer } from "./serializers"
import { handleEmptyResponse } from "api/utils"
import {
  MedicalBillDto,
  MedicalRecordDto,
  ProviderDto,
  CombineActionPayloadDto,
  UserExhibitDto,
  DeleteActionPayloadDto,
  ArrangeExhibitOptionsDto,
} from "./types"
import { create } from "@yornaath/batshit"
import { getUrl } from "apiHelper"
import { getQuery } from "api/services/utils"

type ExhibitBuilderOptions = {
  documentId: string
}

enum PATHS {
  BASE = "documents",
  INTAKE_FILES = "intake_files",
  USER_EXHIBIT = "user-exhibit",
  SORT = "sort",
  EXTRACT = "extract",
  PROVIDER = "matter-provider",
  MEDICAL_RECORD = "medical-record",
  MEDICAL_BILL = "medical-bill",
  COMBINE = "combine",
  DELETE_PARTITION = "delete_partition",
  EXHIBIT_PARTITION = "exhibit-partition",
  DUPLICATE = "duplicate",
  REGENERATE_APPOINTMENTS = "regenerate_appointments",
  SHOULD_REGENERATE_APPOINTMENTS = "should_regenerate_appointments",
}

export class ExhibitBuilder {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(
    options?: ExhibitBuilderOptions | null,
    paths: PATHS | (PATHS | PrimaryKey | string)[] = []
  ): string {
    const pathParts = ["", PATHS.BASE, options?.documentId, ...(Array.isArray(paths) ? paths : [paths])]

    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  getExhibitBuilderData = withResponseSerializer(
    ExhibitBuilderDeserializer.fromJSON,
    (options: ExhibitBuilderOptions) => {
      return handleEmptyResponse(this.apiService.get(null, this.getPath(options, PATHS.INTAKE_FILES)))
    }
  )

  updateMedicalRecord = withResponseSerializer(
    ExhibitBuilderDeserializer.recordWithoutUserExhibitPagesFromJSON,
    withRequestSerializer(
      ExhibitBuilderSerializer.medicalRecordToJSON,
      ({
        data,
        documentId,
        userExhibitId,
      }: {
        data: MedicalRecordDto
        documentId: string
        userExhibitId: string
      }) => {
        return handleEmptyResponse(
          this.apiService.update(
            data,
            this.getPath({ documentId }, [PATHS.USER_EXHIBIT, userExhibitId, PATHS.MEDICAL_RECORD, data.id])
          )
        )
      }
    )
  )

  updateMedicalBill = withResponseSerializer(
    ExhibitBuilderDeserializer.billFromJSON,
    withRequestSerializer(
      ExhibitBuilderSerializer.medicalBillToJSON,
      ({
        data,
        documentId,
        userExhibitId,
      }: {
        data: MedicalBillDto
        documentId: string
        userExhibitId: string
      }) => {
        return handleEmptyResponse(
          this.apiService.update(
            data,
            this.getPath({ documentId }, [PATHS.USER_EXHIBIT, userExhibitId, PATHS.MEDICAL_BILL, data.id])
          )
        )
      }
    )
  )

  createProvider = withResponseSerializer(
    (response: { provider: ProviderDto }) => ExhibitBuilderDeserializer.providerFromJSON(response.provider),
    (data: { documentId: string; name: string }) => {
      return handleEmptyResponse(
        this.apiService.create(
          { name: data.name },
          this.getPath({ documentId: data.documentId }, [PATHS.PROVIDER])
        )
      )
    }
  )

  renameProvider = withResponseSerializer(
    (response: { provider: ProviderDto }) => ExhibitBuilderDeserializer.providerFromJSON(response.provider),
    (data: { documentId: string; name: string; providerId: ProviderDto["id"] }) => {
      return handleEmptyResponse(
        this.apiService.update(
          { name: data.name },
          this.getPath({ documentId: data.documentId }, [PATHS.PROVIDER, data.providerId, "rename"])
        )
      )
    }
  )

  updateUserExhibit = withResponseSerializer(
    ExhibitBuilderDeserializer.userExhibitFromJSON,
    withRequestSerializer(
      ExhibitBuilderSerializer.userExhibitToJSON,
      ({ data, documentId }: { data: UserExhibitDto; documentId: string }) => {
        return handleEmptyResponse(
          this.apiService.update(data, this.getPath({ documentId }, [PATHS.USER_EXHIBIT, data.id]))
        )
      }
    )
  )

  reorderUserExhibit = withResponseSerializer(
    ExhibitBuilderDeserializer.userExhibitOrderFromJSON,
    ({ id, newIndex, documentId }: { id: UserExhibit["id"]; newIndex: number; documentId: string }) =>
      handleEmptyResponse(
        this.apiService.update(
          { index: newIndex },
          this.getPath({ documentId }, [PATHS.USER_EXHIBIT, id, PATHS.SORT])
        )
      )
  )

  reorderExhibitPartition = withResponseSerializer(
    ExhibitBuilderDeserializer.exhibitPartitionOrderFromJSON,
    ({ id, newIndex, userExhibitId, documentId }: ReorderExhibitPartitionPayload) =>
      handleEmptyResponse(
        this.apiService.update(
          { index: newIndex },
          this.getPath({ documentId }, [
            PATHS.USER_EXHIBIT,
            userExhibitId,
            PATHS.EXHIBIT_PARTITION,
            id,
            PATHS.SORT,
          ])
        )
      )
  )

  extractPartition = withResponseSerializer(
    ExhibitBuilderDeserializer.extractActionFromJSON,
    (data: {
      documentId: string
      userExhibitId: UserExhibit["id"]
      partitionId: ExhibitFile["id"]
      pageRanges: { start_page: number; end_page: number }[]
      deleteOriginal: boolean
      combineExtraction: boolean
    }) => {
      return handleEmptyResponse(
        this.apiService.call(
          {
            partition_id: data.partitionId,
            page_ranges: data.pageRanges,
            delete_pages_from_main_file: data.deleteOriginal,
            combine_extraction_into_one_user_exhibit: data.combineExtraction,
          },
          this.getPath({ documentId: data.documentId }, [
            PATHS.USER_EXHIBIT,
            data.userExhibitId,
            PATHS.EXTRACT,
          ])
        )
      )
    }
  )

  combineExhibits = withResponseSerializer(
    ExhibitBuilderDeserializer.combineActionFromJSON,
    withRequestSerializer(
      ExhibitBuilderSerializer.combineActionToJSON,
      ({ data, documentId }: { data: CombineActionPayloadDto; documentId: string }) => {
        return handleEmptyResponse(
          this.apiService.call(
            data,
            this.getPath({ documentId }, [PATHS.USER_EXHIBIT, data.anchor_user_exhibit_id, PATHS.COMBINE])
          )
        )
      }
    )
  )

  deletePageRanges = withResponseSerializer(
    ExhibitBuilderDeserializer.deleteActionFromJSON,
    withRequestSerializer(
      ExhibitBuilderSerializer.deleteActionToJSON,
      ({
        data,
        documentId,
        userExhibitId,
      }: {
        data: DeleteActionPayloadDto
        documentId: string
        userExhibitId: string
      }) => {
        return handleEmptyResponse(
          this.apiService.delete(
            data,
            this.getPath({ documentId }, [PATHS.USER_EXHIBIT, userExhibitId, PATHS.DELETE_PARTITION])
          )
        )
      }
    )
  )

  useExhibitBuilder = async ({
    caseId,
    useExhibitBuilder,
  }: {
    caseId: string
    useExhibitBuilder: boolean
  }) => {
    return handleEmptyResponse(
      this.apiService.call({ use_exhibit_builder: useExhibitBuilder }, `/case/${caseId}/use_exhibit_builder`)
    )
  }

  duplicateUserExhibit = withResponseSerializer(
    ExhibitBuilderDeserializer.duplicateActionFromJSON,
    ({ documentId, userExhibitId }: { documentId: string; userExhibitId: string }) => {
      return handleEmptyResponse(
        this.apiService.call(
          null,
          this.getPath({ documentId }, [PATHS.USER_EXHIBIT, userExhibitId, PATHS.DUPLICATE])
        )
      )
    }
  )

  getUserExhibitPDFStatus = create<
    Record<string, UserExhibit["processingStatus"]>,
    { documentId: string; userExhibitId: string },
    UserExhibit["processingStatus"]
  >({
    resolver: (items, query) => {
      return items[query.userExhibitId]
    },
    fetcher: data => {
      const ids = data.map(({ userExhibitId }) => userExhibitId)

      return handleEmptyResponse(
        this.apiService.call(
          { user_exhibit_ids: ids },
          this.getPath({ documentId: data[0].documentId }, [PATHS.USER_EXHIBIT, "processing_status"])
        )
      )
    },
  }).fetch

  createUserExhibit = withResponseSerializer(
    ExhibitBuilderDeserializer.createUserExhibitFromJSON,
    ({
      exhibitId,
      documentId,
      isNewExhibit,
    }: {
      exhibitId: ExhibitFile["id"]
      documentId: string
      isNewExhibit: boolean
    }) => {
      return handleEmptyResponse(
        this.apiService.create(
          { exhibit_id: exhibitId, is_new_exhibit: isNewExhibit },
          this.getPath({ documentId }, [PATHS.USER_EXHIBIT])
        )
      )
    }
  )

  resetChanges = ({ documentId }: { documentId: string }) => {
    return handleEmptyResponse(
      this.apiService.call(null, this.getPath({ documentId }, [PATHS.USER_EXHIBIT, "reset_exhibit_builder"]))
    )
  }

  arrangeExhibits = withResponseSerializer(
    ExhibitBuilderDeserializer.arrangeExhibitsFromJSON,
    withRequestSerializer(
      ExhibitBuilderSerializer.arrangeExhibitsToJSON,
      ({ data, documentId }: { data: ArrangeExhibitOptionsDto; documentId: string }) => {
        return handleEmptyResponse(
          this.apiService.call(data, this.getPath({ documentId }, ["arrange-exhibits"]))
        )
      }
    )
  )

  getExhibitUrl = (data: {
    caseId: string
    fileId: ExhibitFile["id"]
    asAttachment?: boolean
    startPage?: number
    endPage?: number
  }) => {
    return (
      getUrl(`/case/${data.caseId}/exhibit/${data.fileId}/download/`) +
      getQuery({ as_attachment: [data.asAttachment], start_page: [data.startPage], end_page: [data.endPage] })
    )
  }

  getMedchronExhibitUrl = (data: {
    fileId: ExhibitFile["id"]
    asAttachment?: boolean
    startPage?: number
    endPage?: number
  }) => {
    return (
      getUrl(`/exhibits/${data.fileId}/download/`) +
      getQuery({ as_attachment: [data.asAttachment], start_page: [data.startPage], end_page: [data.endPage] })
    )
  }

  reassignExhibitPartition = withResponseSerializer(
    ExhibitBuilderDeserializer.reassignExhibitPartitionFromJSON,
    (data: {
      documentId: string
      userExhibitId: string
      partitionId: string
      newExhibitId: string
      index: number
    }) => {
      return handleEmptyResponse(
        this.apiService.call(
          { user_exhibit_id: data.newExhibitId, index: data.index },
          this.getPath({ documentId: data.documentId }, [
            PATHS.USER_EXHIBIT,
            data.userExhibitId,
            PATHS.EXHIBIT_PARTITION,
            data.partitionId,
            "reassign-user-exhibit",
          ])
        )
      )
    }
  )

  deleteUserExhibit = withResponseSerializer(
    ExhibitBuilderDeserializer.deleteUserExhibitFromJSON,
    ({ documentId, userExhibitId }: { documentId: string; userExhibitId: string }) => {
      return handleEmptyResponse(
        this.apiService.delete(null, this.getPath({ documentId }, [PATHS.USER_EXHIBIT, userExhibitId]))
      )
    }
  )

  getShouldRegenerateAppointments = withResponseSerializer(
    ExhibitBuilderDeserializer.shouldRegenerateAppointmentsFromJSON,
    ({ documentId }: { documentId: string }) => {
      return handleEmptyResponse(
        this.apiService.get(null, this.getPath({ documentId }, [PATHS.SHOULD_REGENERATE_APPOINTMENTS]))
      )
    }
  )

  regenerateAppointments = withResponseSerializer(
    ExhibitBuilderDeserializer.regenerateAppointmentsFromJSON,
    ({ documentId }: { documentId: string }) => {
      return handleEmptyResponse(
        this.apiService.call(null, this.getPath({ documentId }, [PATHS.REGENERATE_APPOINTMENTS]))
      )
    }
  )
}

export const exhibitBuilderService = new ExhibitBuilder(apiService)
