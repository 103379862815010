import { BaseAnalyticsEvent } from "infrastructure/apm/types"
import { INTAKE_STATUSES } from "requests/constants"
import { DOCUMENT_TYPES } from "requests/enums"

export enum MedicalChronologyAnalyticsEventTypes {
  MedicalChronologySubmitted = "Medical Chronology Submitted",
  MedicalChronologyDownloaded = "Medical Chronology Downloaded",
  ViewedMedicalChronology = "Viewed Medical Chronology",
  MedicalSummaryPageTimeSpent = "Medical Summary Page Time Spent",
  ExhibitManagementPageTimeSpent = "Exhibit Management Page Time Spent",
  ExhibitManagementPageTimeSpentUntilGoToMedicalSummary = "Exhibit Management Page Time Spent Until Go To Medical Summary",
  WebViewMedicalChronologyViewed = "Web Viewable Medical Chronology Viewed",
  WebViewableMedicalChronologyFilteredByMedicalProfessional = "Web Viewable Medical Chronology Filtered By Medical Professionals",
  WebViewableMedicalChronologyFilteredByTimeRange = "Web Viewable Medical Chronology Filtered By Time Range",
  WebViewableMedicalChronologyFilteredByProvider = "Web Viewable Medical Chronology Filtered By Provider",
  WebViewableMedicalChronologyJumpedToSectionViaNavigation = "Web Viewable Medical Chronology Jumped To Section Via Navigation",
  WebViewableMedicalChronologySortedByProvider = "Web Viewable Medical Chronology Sorted By Provider",
  WebViewableMedicalChronologyPDFDownloaded = "Web Viewable Medical Chronology PDF Downloaded",
  WebViewableMedicalChronologyPDFShared = "Web Viewable Medical Chronology Shared",
  WebViewableMedicalChronologyExhibitReferenceClicked = "Web Viewable Medical Chronology Exhibit Reference Clicked",
  WebViewableMedicalChronologyDateInCalendarClicked = "Web Viewable Medical Chronology Date In Calendar Clicked",
}

export class MedicalChronologyAnalyticEvent<
  T extends MedicalChronologyAnalyticsEventTypes,
> extends BaseAnalyticsEvent {
  constructor(
    readonly name: T,
    readonly fields: MedicalChronologyAnalyticsEventDetails[T]
  ) {
    super()
  }
}

type BaseMedChronParams = {
  request_id: PrimaryKey
  document_id: string
}

type TimeSpentParams = BaseMedChronParams & {
  time: number
  request_status: INTAKE_STATUSES
}

type BaseWebViewableMedChronParams = BaseMedChronParams & {
  documentType: DOCUMENT_TYPES
}

// Add new request event types here
export type MedicalChronologyAnalyticsEventDetails = {
  [MedicalChronologyAnalyticsEventTypes.MedicalChronologySubmitted]: BaseMedChronParams
  [MedicalChronologyAnalyticsEventTypes.MedicalChronologyDownloaded]: BaseMedChronParams & {
    generation_id: number
  }
  [MedicalChronologyAnalyticsEventTypes.ViewedMedicalChronology]: BaseMedChronParams
  [MedicalChronologyAnalyticsEventTypes.ExhibitManagementPageTimeSpent]: TimeSpentParams
  [MedicalChronologyAnalyticsEventTypes.MedicalSummaryPageTimeSpent]: TimeSpentParams
  [MedicalChronologyAnalyticsEventTypes.ExhibitManagementPageTimeSpentUntilGoToMedicalSummary]: TimeSpentParams
  [MedicalChronologyAnalyticsEventTypes.WebViewMedicalChronologyViewed]: BaseWebViewableMedChronParams
  [MedicalChronologyAnalyticsEventTypes.WebViewableMedicalChronologyExhibitReferenceClicked]: BaseWebViewableMedChronParams
  [MedicalChronologyAnalyticsEventTypes.WebViewableMedicalChronologyDateInCalendarClicked]: BaseWebViewableMedChronParams
  [MedicalChronologyAnalyticsEventTypes.WebViewableMedicalChronologyFilteredByProvider]: BaseWebViewableMedChronParams
  [MedicalChronologyAnalyticsEventTypes.WebViewableMedicalChronologyFilteredByTimeRange]: BaseWebViewableMedChronParams
  [MedicalChronologyAnalyticsEventTypes.WebViewableMedicalChronologyFilteredByMedicalProfessional]: BaseWebViewableMedChronParams
  [MedicalChronologyAnalyticsEventTypes.WebViewableMedicalChronologySortedByProvider]: BaseWebViewableMedChronParams
  [MedicalChronologyAnalyticsEventTypes.WebViewableMedicalChronologyPDFDownloaded]: BaseWebViewableMedChronParams
  [MedicalChronologyAnalyticsEventTypes.WebViewableMedicalChronologyPDFShared]: BaseWebViewableMedChronParams
  [MedicalChronologyAnalyticsEventTypes.WebViewableMedicalChronologyJumpedToSectionViaNavigation]: BaseWebViewableMedChronParams
}
