import { memo, useEffect, useRef } from "react"
import { StyledAppointmentWrapper, StyledProviderDateCellWrapper } from "./styled"
import { SummaryCell } from "./components/SummaryCell"
import { ProviderCell } from "./components/ProviderCell"
import { ReviewActionCell } from "./components/ReviewActionCell"
import { Appointment, Provider } from "documents/types"
import { documentActions, useDocumentStore } from "documents/store"
import { appointmentsSelectors } from "documents/store/appointments"
import { useShallow } from "zustand/react/shallow"
import { isEmpty } from "lodash"
import { usePermissions } from "permissions/usePermissions"

interface ProviderSummaryAppointmentProps {
  appointmentId: Appointment["id"]
  providerId: Provider["id"]
  readOnly?: boolean
  isLast: boolean
  displayReviewActions?: boolean
}

export const ProviderSummaryAppointments = memo(function ProviderSummaryAppointments({
  appointmentId,
  providerId,
  isLast,
  readOnly,
  displayReviewActions,
}: ProviderSummaryAppointmentProps) {
  const selector = useShallow(
    appointmentsSelectors.getAppointmentFields(appointmentId, ["providerId", "dateOfService"])
  )
  const selectorRef = useRef(selector)
  const isActiveAppointment = useDocumentStore(state => state.currentAppointment === appointmentId)
  const wrapperRef = useRef<HTMLTableRowElement>(null)
  const { medchronAppointmentTagsEnabled } = usePermissions()

  useEffect(() => {
    const unsubscribe = useDocumentStore.subscribe(selectorRef.current, currentState => {
      if (!isEmpty(currentState)) {
        documentActions.setCurrentAppointment(appointmentId)
      }
    })
    return () => unsubscribe()
  }, [appointmentId])

  return (
    <StyledAppointmentWrapper
      data-test={`provider-appointment-${appointmentId}`}
      ref={wrapperRef}
      id={appointmentId}
      data-active={isActiveAppointment}
      style={{ borderBottom: isLast ? "none" : "1px dashed rgba(0, 0, 0, 0.12)" }}
    >
      <StyledProviderDateCellWrapper>
        <div>
          <ProviderCell readOnly={readOnly} providerId={providerId} appointmentId={appointmentId} />
        </div>
        <div>
          {medchronAppointmentTagsEnabled && displayReviewActions ? (
            <ReviewActionCell appointmentId={appointmentId} />
          ) : null}
        </div>
      </StyledProviderDateCellWrapper>
      <SummaryCell readOnly={readOnly} appointmentId={appointmentId} />
    </StyledAppointmentWrapper>
  )
})
