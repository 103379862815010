import styled from "@emotion/styled"
import { Alert, Box } from "@mui/material"
import TextButton from "common/buttons/TextButton"

export const Banner = styled(Alert)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))

export const BannerContent = styled(Box)({
  // To align the content with the icon
  marginTop: "-5px",
  marginBottom: "-5px",
})

export const SyncButton = styled(TextButton)({
  paddingTop: "0px",
  paddingBottom: "0px",
})
