import { EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { GENERATED_SUMMARY_STATUSES } from "api/services/documents/types"
import { MedicalProfessional } from "common/types/medicalProfessional"

export interface DocumentRootData {
  documentId: string
  exhibits: Pick<Exhibit, "id">[]
}

export interface Exhibit {
  id: number
  name: string
  fileName: Nullable<string>
  pageCount: number | "N/A"
  partitionIds: Partition["id"][]
  state?: "reordered" | "error" | null
}

export type ExhibitItem = Pick<Exhibit, "id"> & { children?: [] }

export interface DocumentContextValues {
  documentId: string
}

export interface Provider {
  id: string
  name: string
  color: string
}

export interface AppointmentCreatedBy {
  pk: PrimaryKey
  firstName: string
  lastName: string
  email: string
}

export interface AppointmentTag {
  action: APPOINTMENT_TAG_ACTIONS
  createdAt: string | Date
  createdBy: AppointmentCreatedBy
}

export interface Appointment {
  id: string
  dateOfService: string
  timeOfService: Nullable<string>
  providerId: Nullable<Provider["id"]>
  icdCodes: IcdCode["id"][]
  partitions: Partition["id"][]
  medicalProfessionals: MedicalProfessional["id"][]
  generatedSummary: Nullable<EditorRoot>
  summary: Nullable<EditorRoot>
  generatedSummaryStatus: GENERATED_SUMMARY_STATUSES
  tags: AppointmentTag[]
}

export interface Partition {
  id: PrimaryKey
  name: string
  startPage: number
  endPage: number
  appointmentIds: Appointment["id"][]
  exhibitId: Exhibit["id"]
}

export interface IcdCodeRelation {
  partitionId: Partition["id"]
  page: Nullable<number>
}

export interface IcdCode {
  id: string
  appointmentId: Appointment["id"]
  code: string
  description: string
  relations: IcdCodeRelation[]
}

export enum APPOINTMENT_TAG_ACTIONS {
  MARK_COMPLETED = "mark_completed",
  MARK_INCOMPLETE = "mark_incomplete",
  MARK_REVIEWED = "mark_reviewed",
}

export enum PROVIDER_RECONCILIATION_STATUSES {
  COMPLETE = "Complete",
  FAILED = "Failed",
  PENDING = "Pending",
}
