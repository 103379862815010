import { ReactNode } from "react"
import { VIEW_MED_CHRON_SECTIONS } from "./constants"
import { Box, Typography } from "@mui/material"
import { ExpandableSection } from "common/ExpandableSection"

import { ProviderSummaryTable } from "documents/medical-summary/ProviderSummaryTable"
import { FlagTableObject } from "documents/flags/types"
import { documentStoreSelectors, useDocumentStore } from "documents/store"
import {
  DiagnosticHighlights,
  PastMedicalVisits,
  ExhibitList,
  TreatmentCalendar,
  TreatmentTimeline,
} from "./components"
import { FlagsTable } from "documents/flags/FlagsTable"
import { FlagsController } from "documents/flags/FlagsController"

interface ViewMedChronSectionProps {
  type: VIEW_MED_CHRON_SECTIONS
  exhibitBuilderOn: boolean
}

const Section = ({
  type,
  flags,
  exhibitBuilderOn,
}: {
  type: VIEW_MED_CHRON_SECTIONS
  flags: FlagTableObject[]
  exhibitBuilderOn: boolean
}) => {
  switch (type) {
    case VIEW_MED_CHRON_SECTIONS.DIAGNOSTIC_HIGHLIGHTS:
      return <DiagnosticHighlights />
    case VIEW_MED_CHRON_SECTIONS.FLAGS:
      return <FlagsTable flags={flags} />
    case VIEW_MED_CHRON_SECTIONS.PAST_MEDICAL_VISITS:
      return <PastMedicalVisits />
    case VIEW_MED_CHRON_SECTIONS.EXHIBIT_LIST:
      return <ExhibitList exhibitBuilderOn={exhibitBuilderOn} />
    case VIEW_MED_CHRON_SECTIONS.MEDICAL_SUMMARIES:
      return (
        <Box mb={3.5}>
          <FlagsController readOnly>
            <ProviderSummaryTable readOnly />
          </FlagsController>
        </Box>
      )
    case VIEW_MED_CHRON_SECTIONS.TREATMENT_CALENDAR:
      return (
        <Box mb={3.5}>
          <TreatmentCalendar />
        </Box>
      )
    case VIEW_MED_CHRON_SECTIONS.TREATMENT_TIMELINE:
      return <TreatmentTimeline />

    default: {
      return null
    }
  }
}

export const ViewMedChronSection = ({ type, exhibitBuilderOn }: ViewMedChronSectionProps): ReactNode => {
  const flags = useDocumentStore(documentStoreSelectors.filteredFlags)

  return (
    <>
      <ExpandableSection
        title={
          <Box>
            <Typography id={type} fontWeight={600} fontSize="20px">
              {type}
            </Typography>
            {(type === VIEW_MED_CHRON_SECTIONS.TREATMENT_CALENDAR ||
              type === VIEW_MED_CHRON_SECTIONS.TREATMENT_TIMELINE) && (
              <Box mt={2} fontSize="12px">
                * Grey months represent dates prior to incident{" "}
              </Box>
            )}
          </Box>
        }
      >
        <Section type={type} flags={flags} exhibitBuilderOn={exhibitBuilderOn} />
      </ExpandableSection>
    </>
  )
}
