import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { Actions } from "./Actions/Actions"
import { useShallow } from "zustand/react/shallow"
import { CollapseButtons } from "./Collapse/CollapseButtons"
import { useExhibitBuilderStore, exhibitBuilderActions } from "./store"
import { UserExhibitList } from "./UserExhibitList/UserExhibitList"
import { CSSProperties, PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { ExhibitBuilderSkeleton } from "./ExhibitBuilderSkeleton"
import { DeleteFiles } from "./DeletedFiles/DeletedFiles"
import { UnableToParse } from "./UnableToParse/UnableToParse"
import { StickyHeaderActions, StyledHeaderContainer, StyledMedChronHeaderActions } from "./styled"
import { MedicalSummaryButton } from "documents/Buttons"
import { EB_DOC_TYPE } from "./types"
import { ErrorBoundary } from "@sentry/react"
import { GenericErrorForSuspense } from "common"
import { useListData } from "./UserExhibitList/useListData"
import { CollapseProvider } from "./Collapse/collapse"
import { AlertModal, AlertModalProvider } from "./AlertModal"
import { createPortal } from "react-dom"
import { UpdateSummariesButton } from "./UpdateSummariesButton"

type ContainerProps = {
  width: string
  position: CSSProperties["position"]
  ["data-test"]: string
  margin?: string
  maxWidth?: number
}

function ExhibitBuilder({
  caseId,
  documentId,
  type,
  children,
  headerActions,
}: PropsWithChildren<{ caseId?: string; documentId: string; type: EB_DOC_TYPE; headerActions?: ReactNode }>) {
  const userExhibitMap = useExhibitBuilderStore(useShallow(state => state.userExhibitMap))
  const userExhibitIds = useMemo(() => Object.keys(userExhibitMap), [userExhibitMap])
  const isLoading = useExhibitBuilderStore(state => state.document.loading)
  const listData = useListData(useExhibitBuilderStore(useShallow(state => state.filters)))

  useEffect(() => {
    exhibitBuilderActions.initialize({ caseId, documentId, type })
  }, [caseId, documentId, type])

  const containerProps: ContainerProps = {
    width: "100%",
    position: "relative",
    ["data-test"]: "exhibit-builder",
  }

  if (type === EB_DOC_TYPE.MEDCHRON) {
    containerProps.margin = "0"
  }

  if (type === EB_DOC_TYPE.DEMAND) {
    containerProps.margin = "0 auto"
    containerProps.maxWidth = 1200
  }

  return (
    <ErrorBoundary fallback={<GenericErrorForSuspense />}>
      <AlertModalProvider>
        <CollapseProvider ids={userExhibitIds}>
          <AlertModal />
          <Box {...containerProps}>
            <Box mb={2} data-test={`${type}-exhibit-builder`}>
              <StyledHeaderContainer>
                <div>
                  <Typography variant="h6" mb={1.5}>
                    Exhibit Builder
                  </Typography>
                  <Typography variant="body2" mb={1.5}>
                    File count: {userExhibitIds.length}
                  </Typography>
                </div>
                {headerActions}
              </StyledHeaderContainer>
              {children}
            </Box>

            {isLoading ? (
              <ExhibitBuilderSkeleton />
            ) : (
              <>
                <CollapseButtons />
                <UserExhibitList listData={listData} />
                <DeleteFiles />
                <UnableToParse />
              </>
            )}
          </Box>
        </CollapseProvider>
      </AlertModalProvider>
    </ErrorBoundary>
  )
}

export function DemandExhibitBuilder({ caseId, documentId }: { caseId: string; documentId: string }) {
  return (
    <ExhibitBuilder caseId={caseId} documentId={documentId} type={EB_DOC_TYPE.DEMAND}>
      <Actions caseId={caseId} type={EB_DOC_TYPE.DEMAND} />
    </ExhibitBuilder>
  )
}

export function MedchronExhibitBuilder({ documentId }: { documentId: string }) {
  const [displayHeader, setDisplayHeader] = useState<boolean>(false)
  const handleScroll = useCallback(() => {
    const scrollY = window.scrollY
    if (scrollY > 100) {
      if (!displayHeader) {
        setDisplayHeader(true)
      }
    } else {
      if (displayHeader) {
        setDisplayHeader(false)
      }
    }
  }, [displayHeader])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    exhibitBuilderActions.checkShouldRegenerateAppointments({ documentId })
  }, [documentId])

  return (
    <ExhibitBuilder
      documentId={documentId}
      type={EB_DOC_TYPE.MEDCHRON}
      headerActions={
        <>
          {createPortal(
            <StickyHeaderActions display={displayHeader}>
              <UpdateSummariesButton documentId={documentId} size="small" sx={{ marginRight: "20px" }} />
              <MedicalSummaryButton size="small" documentId={documentId} />
            </StickyHeaderActions>,
            document.querySelector(".sticky-header-container") as HTMLElement
          )}
          <StyledMedChronHeaderActions>
            <UpdateSummariesButton documentId={documentId} />
            <MedicalSummaryButton documentId={documentId} />
          </StyledMedChronHeaderActions>
        </>
      }
    >
      <Actions type={EB_DOC_TYPE.MEDCHRON} />
    </ExhibitBuilder>
  )
}
