import { ReactNode } from "react"
import { useQuery } from "@tanstack/react-query"
import { documentsService } from "api/services/documents"
import { useDocumentStore } from "documents/store"
import { queryKeys, SILENT_QUERY_PARAMS } from "react-query/constants"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import { MedicalProfessional } from "common/types/medicalProfessional"
import { Box } from "@mui/material"
import { StyledListWrapper } from "./styled"
import { theme } from "app/theme"

interface MedicalProfessionalsListProps {
  selectedIds: MedicalProfessional["id"][]
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
  readOnly?: boolean
}

export function MedicalProfessionalsList({
  selectedIds,
  onClick,
  readOnly,
}: MedicalProfessionalsListProps): ReactNode {
  const documentId = useDocumentStore(state => state.documentId)

  const { data } = useQuery(
    [queryKeys.documentMedicalProfessionals, documentId],
    () => documentsService.getMedicalProfessionals({ documentId }),
    {
      enabled: !!documentId,
      ...SILENT_QUERY_PARAMS,
    }
  )

  if (!data) return null

  const medicalProfessionals = selectedIds
    .map(id => data.medicalProfessionals.find(medicalProfessional => medicalProfessional.id === id))
    .filter(Boolean) as MedicalProfessional[]

  return (
    <StyledListWrapper onClick={onClick} readOnly={readOnly}>
      {!medicalProfessionals.length && (
        <Box display="flex" alignItems="center" gap={1} pr={1} color={theme.palette.disabled.main}>
          <AccountCircleIcon sx={{ fontSize: "20px" }} htmlColor={theme.palette.disabled.main} />
          Add medical professional
        </Box>
      )}
      {medicalProfessionals.map(medicalProfessional => (
        <Box key={medicalProfessional.id} display="flex" gap={1}>
          <AccountCircleIcon sx={{ fontSize: "20px" }} htmlColor="rgba(0, 0, 0, 0.56)" />
          <span>{medicalProfessional.name}</span>
        </Box>
      ))}
    </StyledListWrapper>
  )
}
