import { openWindow } from "common/windows"
import { useDocumentStore } from "documents/store"
import { exhibitSelectors } from "documents/store/exhibits"
import { Exhibit, Partition } from "documents/types"
import { MESSAGE_TOPIC } from "message-broker/topics"
import { PreviewExhibitPayload } from "message-broker/types"
import { usePublish } from "message-broker/usePublish"
import { getExhibitPreviewPath } from "pdf-preview/utils"
import { useCallback } from "react"

type UseExhibitPreviewReturn = (partition?: Partition, page?: Nullable<number>) => void

export function useExhibitPreview(exhibitId: Exhibit["id"]): UseExhibitPreviewReturn {
  const documentId = useDocumentStore(state => state.documentId)
  const exhibitsOrder = useDocumentStore(state => state.exhibitsOrder)
  const { name } = useDocumentStore(exhibitSelectors.getExhibitById(exhibitId))
  const index = exhibitsOrder.findIndex(id => exhibitId === id)
  const publish = usePublish()

  const openPreview = useCallback(
    (partition?: Partition, page?: Nullable<number>) => {
      const payload: PreviewExhibitPayload = {
        documentId,
        exhibitId,
        partitionId: partition?.id,
        fileName: partition?.name ?? `Exhibit ${index + 1} - ${name}`,
        page: page ?? partition?.startPage,
      }

      publish(MESSAGE_TOPIC.PREVIEW_EXHIBIT, payload).catch(() => {
        const path = getExhibitPreviewPath(payload)

        if (path) openWindow(`${path.base}/${path.route}`)
      })
    },
    [publish, name, documentId, exhibitId, index]
  )

  return openPreview
}

export type UseExhibitsPreviewReturn = (exhibitId: Exhibit["id"], page?: Nullable<number>) => void

export function useExhibitsPreview(): UseExhibitsPreviewReturn {
  const documentId = useDocumentStore(state => state.documentId)
  const publish = usePublish()

  const openPreview = useCallback<UseExhibitsPreviewReturn>(
    (exhibitId, page) => {
      const { name } = exhibitSelectors.getExhibitById(exhibitId)(useDocumentStore.getState())
      const { exhibitsOrder } = useDocumentStore.getState()
      const index = exhibitsOrder.findIndex(id => exhibitId === id)
      const payload: PreviewExhibitPayload = {
        documentId,
        exhibitId,
        fileName: `Exhibit ${index + 1} - ${name}`,
        page: page ?? 1,
      }

      publish(MESSAGE_TOPIC.PREVIEW_EXHIBIT, payload).catch(() => {
        const path = getExhibitPreviewPath(payload)

        if (path) openWindow(`${path.base}/${path.route}`)
      })
    },
    [publish, documentId]
  )

  return openPreview
}
