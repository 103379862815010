import { UserExhibit } from "exhibit-builder/store/types"
import { DocumentData, GetState, SetState } from "./types"

export type UserExhibitsSlice = Pick<
  DocumentData,
  "userExhibitMap" | "userExhibitOrder" | "userExhibitPartitionMap" | "userExhibitPartitionOrder"
>

export const userExhibitsSlice: UserExhibitsSlice = {
  userExhibitMap: {},
  userExhibitOrder: [],
  userExhibitPartitionMap: {},
  userExhibitPartitionOrder: {},
}

export const userExhibitsSelectors = {
  getUserExhibitById: (id: UserExhibit["id"]) => (state: UserExhibitsSlice) => state.userExhibitMap[id],
  getUserExhibitsList: (state: UserExhibitsSlice) =>
    state.userExhibitOrder.map(id => userExhibitsSelectors.getUserExhibitById(id)(state)),
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const userExhibitsActions = (set: SetState<UserExhibitsSlice>, get: GetState<UserExhibitsSlice>) => {
  const setUserExhibitData = (data: UserExhibitsSlice) => set(data)
  return {
    setUserExhibitData,
  }
}
