import { Box, Dialog, Typography } from "@mui/material"
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded"
import { useCallback, useState } from "react"
import Button from "evenup-ui/Button"
import { CircularProgress } from "@mui/material"
import { useShallow } from "zustand/react/shallow"
import { useAlertModalStore } from "./store"

export function AlertModal() {
  const modal = useAlertModalStore(
    useShallow(state => (state.currentModalId ? state.modalContent[state.currentModalId] : null))
  )
  const { setCurrentModalId } = useAlertModalStore(
    useShallow(({ setCurrentModalId }) => ({ setCurrentModalId }))
  )

  const [isLoading, setIsLoading] = useState(false)

  const handleClose = useCallback(() => {
    setCurrentModalId(null)
    modal?.onClose?.()
  }, [modal, setCurrentModalId])

  const handleConfirm = useCallback(async () => {
    setIsLoading(true)
    await modal?.onConfirm?.()
    handleClose()
    setIsLoading(false)
  }, [modal, handleClose])

  return (
    <Dialog open={Boolean(modal)} onClose={handleClose} fullWidth data-test="alert-modal">
      {modal && (
        <Box p={4}>
          <Box mb={2} display="flex" alignItems="center" gap={1}>
            <WarningAmberRoundedIcon fontSize="large" color="error" />
            <Typography variant="dialogHeader">{modal.title}</Typography>
          </Box>
          {modal.content}
          <Box display="flex" justifyContent="flex-end" mt={2} gap={1}>
            <Button onClick={handleClose} color="secondary" data-test="close-button">
              {modal.closeLabel}
            </Button>
            {modal.confirmLabel && (
              <Button onClick={handleConfirm} color="primary" disabled={isLoading} data-test="confirm-button">
                {modal.confirmLabel}
                {isLoading && <CircularProgress size={20} color="secondary" />}
              </Button>
            )}
          </Box>
        </Box>
      )}
    </Dialog>
  )
}
