import { QueryKey } from "@tanstack/react-query"
import { handleEmptyResponse } from "api/utils"
import { REQUEST_METHODS, makeApiCall } from "apiHelper"
import { CaseDataDto, CaseFormFields } from "cases/types/caseEdit"
import { CaseFlagDto } from "cases/types/caseFlags"
import { queryKeys, QueryKeys } from "react-query/constants"
import { DOCUMENT_TYPES } from "requests/enums"

// In the future: Refactor this into `service.ts` similar to the other services
export const fetchCaseFlags = async ({
  demandId,
  published,
}: {
  demandId: PrimaryKey
  published?: boolean
}) => {
  let url = `/matter/case-flag/?case_id=${demandId}`
  if (published) {
    url += `&published=${published}`
  }
  const options = {
    path: url,
    method: REQUEST_METHODS.GET,
  }

  return handleEmptyResponse(makeApiCall<CaseFlagDto[]>(options))
}

// TODO use UUIDv4
export const queryKeyFetchCase = (caseId: string): QueryKey => [queryKeys.casesV2, caseId]

export const fetchCase = async ({ queryKey }: { queryKey: [QueryKeys, string] }) => {
  const [, caseId] = queryKey
  const options = {
    path: `/v2/cases/${caseId}/`,
    method: REQUEST_METHODS.GET,
  }

  return handleEmptyResponse(makeApiCall<CaseDataDto>(options))
}

export const createCase = async ({ data }: { data: CaseFormFields }) => {
  const options = {
    path: `/v2/cases/`,
    method: REQUEST_METHODS.POST,
    data,
  }

  return handleEmptyResponse(makeApiCall<CaseDataDto>(options))
}

export const editCase = async ({ caseId, data }: { caseId: string; data: CaseFormFields }) => {
  const options = {
    path: `/v2/cases/${caseId}/`,
    method: REQUEST_METHODS.PATCH,
    data,
  }

  return handleEmptyResponse(makeApiCall<CaseDataDto>(options))
}

export const createCaseRequest = async (caseId: string, documentType: DOCUMENT_TYPES) => {
  const options = {
    path: `/v2/cases/${caseId}/request/`,
    method: REQUEST_METHODS.POST,
    data: {
      type: documentType,
    },
  }

  // It actually returns the entire Request object, but the only thing we need is the matter_id and pk
  return handleEmptyResponse(makeApiCall<{ pk: number; matter_id: string }>(options))
}
