import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { StyledButton } from "../styled"
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined"
import { Typography } from "@mui/material"
import { cloneElement, isValidElement, ReactElement, ReactNode } from "react"
import { useAction } from "../../ActionButton"
import { exhibitBuilderService } from "exhibit-builder/api/service"
import { useAlertModal } from "exhibit-builder/AlertModal"

export function Reset({ component, onClick }: { component?: ReactNode; onClick?: () => void }) {
  const documentId = useExhibitBuilderStore(state => state.document.documentId)
  const type = useExhibitBuilderStore(state => state.exhibitBuilderType)

  const { handleAction: resetChanges } = useAction({
    name: "reset all changes",
    action: async () => {
      await exhibitBuilderService.resetChanges({ documentId })
      return exhibitBuilderActions.initialize({ documentId, type })
    },
  })

  const { openModal } = useAlertModal({
    title: "Reset all Exhibits",
    content: (
      <Typography variant="body1">
        Please note, this action will revert all exhibit and file manipulations (extracts, deletes, combines)
        back to its original supporting files. All changes will be permanently lost. Annotation data will
        remain unchanged.
      </Typography>
    ),
    confirmLabel: "Confirm",
    onConfirm: () => {
      resetChanges()
      onClick?.()
    },
  })

  if (component && isValidElement(component)) {
    return cloneElement(component as ReactElement, { onClick: openModal })
  }

  return (
    <StyledButton startIcon={<CachedOutlinedIcon />} onClick={openModal} data-test="reset-button">
      Reset All Changes
    </StyledButton>
  )
}
