import { TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { useDocumentStore } from "documents/store"
import { StyledFileName } from "./styled"
import { StyledTableHeadCell, StyledViewTable } from "documents/styled"
import { isEmpty } from "lodash"
import splitFileName from "common/form-components/files/splitFileName"
import { useExhibitPreview } from "documents/exhibits/useExhibitPreview"
import { useUserExhibitPreview } from "documents/exhibits/useUserExhibitPreview"

interface ExhibitListItemProps {
  exhibitId: string | number
  exhibitNo: number
  exhibitBuilderOn: boolean
}

const UserExhibitFileName = ({ exhibitId }: { exhibitId: string }) => {
  const userExhibits = useDocumentStore(state => state.userExhibitMap)
  const openUserExhibitPreview = useUserExhibitPreview()

  return (
    <StyledFileName onClick={() => openUserExhibitPreview(exhibitId)}>
      {splitFileName(userExhibits[exhibitId].name)[0]}
    </StyledFileName>
  )
}

const ExhibitFileName = ({ exhibitId }: { exhibitId: number }) => {
  const exhibits = useDocumentStore(state => state.exhibits)
  const openPreview = useExhibitPreview(exhibitId)

  return (
    <StyledFileName onClick={() => openPreview()}>
      {splitFileName(exhibits[exhibitId as number].name)[0]}
    </StyledFileName>
  )
}

const ExhibitListItem = ({ exhibitId, exhibitNo, exhibitBuilderOn }: ExhibitListItemProps): JSX.Element => {
  return (
    <TableRow key={exhibitId}>
      <TableCell>{exhibitNo}</TableCell>
      <TableCell>
        {exhibitBuilderOn ? (
          <UserExhibitFileName exhibitId={exhibitId as string} />
        ) : (
          <ExhibitFileName exhibitId={exhibitId as number} />
        )}
      </TableCell>
    </TableRow>
  )
}

export const ExhibitList = ({ exhibitBuilderOn }: { exhibitBuilderOn: boolean }): JSX.Element => {
  const exhibitsOrder = useDocumentStore(state => state.exhibitsOrder)
  const userExhibitOrder = useDocumentStore(state => state.userExhibitOrder)

  const order = exhibitBuilderOn ? userExhibitOrder : exhibitsOrder

  return (
    <StyledViewTable>
      <TableHead>
        <TableRow>
          <StyledTableHeadCell width="20%">Exhibit No.</StyledTableHeadCell>
          <StyledTableHeadCell width="80%">Description</StyledTableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isEmpty(order) && (
          <TableRow>
            <TableCell align="center" colSpan={2}>
              <b>There are no exhibits</b>
            </TableCell>
          </TableRow>
        )}
        {order.map((exhibitId, index) => (
          <ExhibitListItem
            key={exhibitId}
            exhibitId={exhibitId}
            exhibitNo={index + 1}
            exhibitBuilderOn={exhibitBuilderOn}
          />
        ))}
      </TableBody>
    </StyledViewTable>
  )
}
