import { Box, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material"
import { DropdownContainer, DropdownLabel } from "./styled"
import { Plaintiff, UserExhibit } from "exhibit-builder/store/types"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { filesSelectors } from "exhibit-builder/store/files/filesSelectors"
import { plaintiffsSelectors } from "exhibit-builder/store/plaintiffs"
import { useShallow } from "zustand/react/shallow"
import { useCallback, useEffect, useState } from "react"
import { ApiError } from "apiHelper"
import * as Sentry from "@sentry/react"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { useAlertModal } from "exhibit-builder/AlertModal"
import { DropdownItemValue } from "./DropdownItemValue"

export function PlaintiffDropdown({ id }: { id: UserExhibit["id"] }) {
  const userExhibitMap = useExhibitBuilderStore(state => state.userExhibitMap)
  const userExhibit = useExhibitBuilderStore(filesSelectors.getUserExhibitById(id))
  const plaintiffs = useExhibitBuilderStore(plaintiffsSelectors.getPlaintiffs)
  const isReadOnly = useExhibitBuilderStore(useShallow(state => state.isReadOnly))
  const { showErrorMessage } = useHandleMessages()
  const [plaintiffError, setPlaintiffError] = useState<{
    plaintiff: Plaintiff
    userExhibit: UserExhibit
  } | null>(null)

  const handlePlaintiffChange = useCallback(
    async (event: SelectChangeEvent<string>) => {
      try {
        await exhibitBuilderActions.updateUserExhibit({ id, plaintiffId: event.target.value })
      } catch (error) {
        if (
          error instanceof ApiError &&
          error.validationErrors &&
          error.validationErrors.plaintiff_id &&
          error.validationErrors.user_exhibit_id
        ) {
          setPlaintiffError({
            plaintiff: plaintiffs[error.validationErrors.plaintiff_id],
            userExhibit: userExhibitMap[error.validationErrors.user_exhibit_id],
          })
          return
        }

        Sentry.captureException(error, {
          extra: {
            userExhibitId: id,
            plaintiffId: event.target.value,
          },
        })
        showErrorMessage("Failed to update plaintiff, please try again.")
      }
    },
    [id, showErrorMessage, plaintiffs, userExhibitMap]
  )

  const { openModal } = useAlertModal({
    title: "Overlapping Plaintiff Records Found",
    content: (
      <Box>
        <Typography variant="body1" mb={3}>
          We’ve detected multiple relations records for the same plaintiff. To ensure accuracy, please review
          and adjust the plaintiff&apos;s name to the correct relation.
        </Typography>
        {plaintiffError && (
          <Typography variant="body1" mb={2}>
            <b>Exhibit {plaintiffError.userExhibit.index + 1}</b>: {plaintiffError.plaintiff.name} <br />
            <b>Exhibit {userExhibit.index + 1}</b>: {plaintiffError!.plaintiff.name}
          </Typography>
        )}
      </Box>
    ),
    onClose: () => setPlaintiffError(null),
  })

  useEffect(() => {
    if (plaintiffError) {
      openModal()
    }
  }, [plaintiffError, openModal])

  return (
    <DropdownContainer>
      <DropdownLabel>Plaintiff:</DropdownLabel>
      <Select
        value={userExhibit.plaintiffId ?? ""}
        onChange={handlePlaintiffChange}
        data-test="user-exhibit-plaintiff-dropdown"
        readOnly={isReadOnly}
        displayEmpty
        renderValue={value => <DropdownItemValue name={plaintiffs[value]?.name} value={value} />}
      >
        {Object.values(plaintiffs).map((plaintiff, index) => (
          <MenuItem key={index} value={plaintiff.id}>
            {plaintiff.name}
          </MenuItem>
        ))}
        <MenuItem value="">Unassigned</MenuItem>
      </Select>
    </DropdownContainer>
  )
}
