export enum FEATURE_PERMISSIONS {
  CREATE_BASIC_PLUS = "create_basic_plus",
  REQUEST_REVAMP = "request_revamp",
  LIBRARY_DOCUMENT_STRUCTURES = "library_document_structures",
  BULLET_POINT_SUMMARY = "bullet_point_summary",
  ASYNC_ZIP_AND_DOWNLOAD = "async_zip_and_download",
  INTERNAL_RECEIPT_GENERATION = "internal_receipt_generation",
  REQUEST_REVAMP_SINGLE_PAGE_FORM = "request_revamp_single_page_form",
  SMART_ADVOCATE_INTEGRATION = "smart_advocate_integration",
  GENERATE_RECEIPT = "can_generate_receipts",
  CAN_RESET_DEMAND = "can_reset_demand",
  MEDICAL_SUMMARY = "medical_summary",
  MULTI_PLAINTIFF = "multi_plaintiff",
  MEDICAL_SUMMARY_FLAGS = "medical_summary_flags",
  CAN_OVERRIDE_REQUEST_STATUS = "can_override_request_status",
  SHOW_MULTI_DOCUMENT_SURVEY = "show_multi_document_survey",
  SHOW_MULTI_POLICY_SURVEY = "show_multi_policy_survey",
  CITATION_HIGHLIGHTS = "citation_highlights",
  SHOW_BEAMER = "show_beamer",
  INTAKE_FILES_REVAMP = "intake_files_revamp",
  EXHIBIT_BUILDER_TOGGLE = "exhibit_builder_toggle",
  CASE_SECTION_REVIEW = "case_section_review",
  CAN_IMPERSONATE_USERS = "can_impersonate_users",
  CREATE_SIMPLE = "create_simple",

  // settlement repo flags
  CAN_VIEW_SETTLEMENTS = "can_view_settlements",
  ESTIMATE_FLOW_V2 = "estimate_flow_v2",
  SETTLEMENTS_DEMO_VIEW = "settlements_demo_view",
  CAN_POST_SETTLEMENTS = "can_post_settlements",
  CAN_VIEW_EXECUTIVE_ANALYTICS = "can_view_executive_analytics",

  SHOW_REPORT_ISSUE = "show_report_issue",
  SETTINGS_BILLING_TAB = "settings_billing_tab",
  INTRODUCTION_CONCLUSION_TEMPLATES = "introduction_conclusion_templates",
  CAN_EDIT_OR_DELETE_EXPIRED_CONTRACTS = "can_edit_or_delete_expired_contracts",

  // Cases
  SHOW_CASE_INSIGHTS = "show_case_insights",
  SHOW_CASE_FLAGS_FORM = "show_case_flags_form",
  SUSPEND_CASE_FLAGS = "suspend_case_flags",
  PRE_DEMAND_CASE_VIEW = "pre_demand_case_view",
  ENABLE_CASE_QA = "enable_case_qa",
  CASE_CHECK = "case_check",
  CASE_CHECK_MISSING_DOCS = "case_check_missing_docs",
  SYNC_CASE_TEMPLATED_SECTIONS_BANNER = "sync_case_templated_sections_banner",
  CASE_COPILOT_ICD_CODE_PRIORITIZATION = "case_copilot_prioritized_icd_codes",

  // Collaborators
  COLLABORATOR_SELECTION = "collaborator_selection",

  // Telemetry
  DISABLE_TELEMETRY_SENTRY = "disable_telemetry_sentry",
  DISABLE_TELEMETRY_FULLSTORY = "disable_telemetry_fullstory",
  DISABLE_TELEMETRY_AMPLITUDE = "disable_telemetry_amplitude",

  // Medchron
  MEDCHRON_APPOINTMENT_TAGS = "medchron_appointment_tags",
  MEDCHRON_PROVIDER_RECONCILIATION = "medchron_provider_reconciliation",
  MEDICAL_CHRONOLOGY_PREMIUM = "medical_chronology_premium",
  MEDICAL_CHRONOLOGY_AUTO_ANNOTATION = "medical_chronology_auto_annotation",
  MEDICAL_CHRONOLOGY_ICD_CODE_TABLE = "medical_chronology_icd_code_table",
  MEDCHRON_EXHIBIT_BUILDER_TOGGLE = "medchron_exhibit_builder_toggle",
  PREMIUM_MED_CHRON_MEDICAL_PROFESSIONAL = "premium_med_chron_medical_professional",

  // Microsoft SSO
  CAN_MAP_STYTCH_MICROSOFT_ACCOUNT = "can_map_stytch_microsoft_account",

  // 0-click summaries
  ENABLE_ZERO_CLICK_SUMMARIES = "enable_zero_click_summaries",
}
