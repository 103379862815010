import {
  MedicalBillDto,
  ExhibitBuilderDto,
  ExhibitPartitionDto,
  ProviderDto,
  ExhibitDto,
  PlaintiffDto,
  MedicalRecordDto,
  UserExhibitDto,
  DocTypeMappingsDto,
  CombineActionPayloadDto,
  DeleteActionPayloadDto,
  ArrangeExhibitOptionsDto,
} from "./types"
import {
  ExhibitBuilderData,
  Provider,
  MedicalBill,
  ExhibitPartition,
  ExhibitFile,
  Plaintiff,
  MedicalRecord,
  UserExhibit,
  UserExhibitPayload,
  DocTypes,
  CombineActionPayload,
  ArrangeExhibitOptions,
} from "../store/types"
import { isUndefined, omitBy } from "lodash"
import { filesSelectors } from "exhibit-builder/store/files/filesSelectors"
import { USER_EXHIBIT_TAG } from "exhibit-builder/constants"

export class ExhibitBuilderDeserializer {
  static fromJSON({
    exhibit_partitions: exhibitPartitions,
    exhibits: files,
    medical_bills: bills,
    medical_records: records,
    plaintiffs,
    providers,
    doc_types: docTypes,
    user_exhibits: userExhibits,
    exhibits_annotation_status: annotationStatus,
  }: ExhibitBuilderDto): ExhibitBuilderData {
    const data: ExhibitBuilderData = {
      exhibitPartitionMap: {},
      exhibitPartitionOrder: {},
      files: {},
      plaintiffs: {},
      providers: {},
      docTypes: ExhibitBuilderDeserializer.docTypeMappingsFromJSON(docTypes),
      userExhibitMap: {},
      userExhibitOrder: [],
      recordsAndBillsMap: {},
      annotationStatus: annotationStatus ?? {},
      recordsAndBillsOrder: {},
    }

    for (const userExhibit of userExhibits) {
      data.userExhibitMap[userExhibit.id] = ExhibitBuilderDeserializer.userExhibitFromJSON(userExhibit)
      data.userExhibitOrder.push(userExhibit.id)
    }

    for (const record of records) {
      data.recordsAndBillsMap[record.id] = ExhibitBuilderDeserializer.recordFromJSON(record)
    }
    for (const bill of bills) {
      data.recordsAndBillsMap[bill.id] = ExhibitBuilderDeserializer.billFromJSON(bill)
    }

    for (const exhibitPartition of exhibitPartitions) {
      const partition = ExhibitBuilderDeserializer.exhibitPartitionFromJSON(exhibitPartition)

      if (data.userExhibitMap[partition.userExhibitId]) {
        data.userExhibitMap[partition.userExhibitId].partitionIds.push(partition.id)
      }

      data.exhibitPartitionMap[exhibitPartition.id] = partition
      data.recordsAndBillsOrder[exhibitPartition.id] = filesSelectors.getSortedRecordsAndBills({
        exhibitPartition: partition,
        recordsAndBillsMap: data.recordsAndBillsMap,
      })
    }

    data.exhibitPartitionOrder = filesSelectors.getExhibitPartitionOrder(data.exhibitPartitionMap)

    for (const file of files) {
      data.files[file.pk] = ExhibitBuilderDeserializer.fileFromJSON(file)
    }

    for (const plaintiff of plaintiffs) {
      data.plaintiffs[plaintiff.pk] = ExhibitBuilderDeserializer.plaintiffFromJSON(plaintiff)
    }

    for (const provider of providers) {
      data.providers[provider.id] = ExhibitBuilderDeserializer.providerFromJSON(provider)
    }

    return data
  }

  static billFromJSON(bill: MedicalBillDto): MedicalBill {
    return {
      id: bill.id.toString(),
      fileId: bill.exhibit_id,
      providerId: bill.provider_id,
      dateOfFirstService: bill.date_of_first_service,
      dateOfLastService: bill.date_of_last_service,
      startPage: bill.start_page,
      endPage: bill.end_page,
      type: "Medical Bill",
    }
  }

  static exhibitPartitionFromJSON(exhibitPartition: ExhibitPartitionDto): ExhibitPartition {
    return {
      id: exhibitPartition.id,
      fileId: exhibitPartition.exhibit_id,
      startPage: exhibitPartition.start_page,
      endPage: exhibitPartition.end_page,
      userExhibitId: exhibitPartition.user_exhibit_id,
      index: exhibitPartition.user_exhibit_index,
      userExhibitStartPage: exhibitPartition.user_exhibit_start_page,
      userExhibitEndPage: exhibitPartition.user_exhibit_end_page,
      medicalBills: exhibitPartition.medical_bills,
      medicalRecords: exhibitPartition.medical_records,
    }
  }

  static fileFromJSON(file: ExhibitDto): ExhibitFile {
    return {
      id: file.pk,
      name: file.name,
      numberOfPages: file.number_of_pages,
      unableToParse: file.unable_to_parse,
    }
  }

  static plaintiffFromJSON(plaintiff: PlaintiffDto): Plaintiff {
    return {
      id: plaintiff.pk.toString(),
      name: `${plaintiff.first_name} ${plaintiff.last_name}`,
    }
  }

  static providerFromJSON(provider: ProviderDto): Provider {
    return {
      id: provider.id,
      name: provider.name,
      color: provider.color ? `#${provider.color}` : "#e0e0e0",
    }
  }

  static recordFromJSON(record: MedicalRecordDto): MedicalRecord {
    const transformedRecord: MedicalRecord = {
      id: record.id.toString(),
      fileId: record.exhibit_id,
      providerId: record.provider_id,
      dateOfService: record.date_of_service,
      startPage: record.start_page,
      endPage: record.end_page,
      type: "Medical Record",
    }

    if (record.user_exhibits) {
      transformedRecord.userExhibitPages = record.user_exhibits.map(userExhibit => ({
        id: userExhibit.user_exhibit_id,
        partitionId: userExhibit.partition_id,
        startPage: userExhibit.user_exhibit_start_page,
        endPage: userExhibit.user_exhibit_end_page,
      }))
    }

    return transformedRecord
  }

  // TODO: Remove when backend updates the "update Record" endpoint
  static recordWithoutUserExhibitPagesFromJSON(record: MedicalRecordDto): MedicalRecord {
    return {
      id: record.id.toString(),
      fileId: record.exhibit_id,
      providerId: record.provider_id,
      dateOfService: record.date_of_service,
      startPage: record.start_page,
      endPage: record.end_page,
      type: "Medical Record",
    }
  }

  static docTypeMappingsFromJSON(docTypeMappings: DocTypeMappingsDto): DocTypes {
    const docTypes: DocTypes = {}
    for (const { key, name, sub_doc_types } of docTypeMappings) {
      docTypes[key] = { key, name, subDocTypes: sub_doc_types ?? null }
    }

    return docTypes
  }

  static userExhibitFromJSON(userExhibit: UserExhibitDto | { user_exhibit: UserExhibitDto }): UserExhibit {
    const exhibit = "user_exhibit" in userExhibit ? userExhibit.user_exhibit : userExhibit

    return {
      id: exhibit.id,
      name: exhibit.name,
      plaintiffId: exhibit.plaintiff_id,
      docType: exhibit.doctype,
      subDocType: exhibit.subdoctype,
      tag: exhibit.action_tag as USER_EXHIBIT_TAG | null,
      index: exhibit.index,
      processingStatus: exhibit.processing_status as UserExhibit["processingStatus"],
      sortingProviderId: exhibit.sorting_provider_id,
      fileType: exhibit.file_type,
      partitionIds: [],
      pageCount: exhibit.number_of_pages ?? "N/A",
    }
  }

  static userExhibitOrderFromJSON(data: { user_exhibits: UserExhibitDto["id"][] }): string[] {
    return data.user_exhibits
  }

  static extractActionFromJSON(data: {
    user_exhibits: UserExhibitDto[]
    user_exhibit_ids: UserExhibitDto["id"][]
    exhibit_partitions: ExhibitPartitionDto[]
  }) {
    const userExhibitMap: Record<string, UserExhibit> = {}
    data.user_exhibits.forEach(userExhibit => {
      userExhibitMap[userExhibit.id] = ExhibitBuilderDeserializer.userExhibitFromJSON(userExhibit)
    })

    return {
      userExhibitOrder: data.user_exhibit_ids,
      userExhibitMap,
      exhibitPartitions: data.exhibit_partitions.map(ExhibitBuilderDeserializer.exhibitPartitionFromJSON),
    }
  }

  static combineActionFromJSON(data: {
    exhibit_partitions: ExhibitPartitionDto[]
    user_exhibits_ids: UserExhibitDto["id"][]
    user_exhibit: UserExhibitDto
  }) {
    return {
      exhibitPartitions: data.exhibit_partitions.map(ExhibitBuilderDeserializer.exhibitPartitionFromJSON),
      userExhibitOrder: data.user_exhibits_ids,
      userExhibit: ExhibitBuilderDeserializer.userExhibitFromJSON(data.user_exhibit),
    }
  }

  static deleteActionFromJSON(data: {
    exhibit_partitions: ExhibitPartitionDto[]
    user_exhibit_ids: UserExhibitDto["id"][]
  }) {
    return {
      exhibitPartitions: data.exhibit_partitions.map(ExhibitBuilderDeserializer.exhibitPartitionFromJSON),
      userExhibitOrder: data.user_exhibit_ids,
    }
  }

  static exhibitPartitionOrderFromJSON(data: { exhibit_partitions: ExhibitPartitionDto["id"][] }) {
    return data.exhibit_partitions
  }

  static duplicateActionFromJSON(data: {
    new_user_exhibit: UserExhibitDto
    exhibit_partitions: ExhibitPartitionDto[]
    user_exhibits_ids: UserExhibitDto["id"][]
  }) {
    return {
      userExhibit: ExhibitBuilderDeserializer.userExhibitFromJSON(data.new_user_exhibit),
      exhibitPartitions: data.exhibit_partitions.map(ExhibitBuilderDeserializer.exhibitPartitionFromJSON),
      userExhibitOrder: data.user_exhibits_ids,
    }
  }

  static createUserExhibitFromJSON(data: {
    user_exhibit: UserExhibitDto
    user_exhibit_ids: UserExhibitDto["id"][]
    exhibit_partition: ExhibitPartitionDto
    exhibit: ExhibitDto
  }) {
    return {
      userExhibit: ExhibitBuilderDeserializer.userExhibitFromJSON(data.user_exhibit),
      userExhibitOrder: data.user_exhibit_ids,
      exhibitPartition: ExhibitBuilderDeserializer.exhibitPartitionFromJSON(data.exhibit_partition),
      exhibit: ExhibitBuilderDeserializer.fileFromJSON(data.exhibit),
    }
  }

  static reassignExhibitPartitionFromJSON(data: {
    source_exhibit_partition_ids: ExhibitPartitionDto["id"][]
    target_exhibit_partition_ids: ExhibitPartitionDto["id"][]
    updated_user_exhibit_ids: UserExhibitDto["id"][] | null
  }) {
    return {
      sourceExhibitPartitionOrder: data.source_exhibit_partition_ids,
      targetExhibitPartitionOrder: data.target_exhibit_partition_ids,
      userExhibitOrder: data.updated_user_exhibit_ids,
    }
  }

  static arrangeExhibitsFromJSON(data: {
    user_exhibits: UserExhibitDto[]
    exhibit_partitions: ExhibitPartitionDto[]
  }) {
    return {
      userExhibits: data.user_exhibits.map(ExhibitBuilderDeserializer.userExhibitFromJSON),
      exhibitPartitions: data.exhibit_partitions.map(ExhibitBuilderDeserializer.exhibitPartitionFromJSON),
    }
  }

  static deleteUserExhibitFromJSON(data: { user_exhibit_ids: UserExhibitDto["id"][] }) {
    return {
      userExhibitOrder: data.user_exhibit_ids,
    }
  }

  static shouldRegenerateAppointmentsFromJSON(data: { result: boolean }) {
    return {
      shouldRegenerateAppointments: data.result,
    }
  }

  static regenerateAppointmentsFromJSON(data: { result: boolean }) {
    return data
  }
}

export class ExhibitBuilderSerializer {
  static userExhibitToJSON(userExhibit: UserExhibitPayload): Partial<UserExhibitDto> {
    return omitBy(
      {
        id: userExhibit.id,
        name: userExhibit.name,
        plaintiff_id: userExhibit.plaintiffId,
        doctype: userExhibit.docType,
        subdoctype: userExhibit.subDocType,
        tag: userExhibit.tag,
        sorting_provider_id: userExhibit.sortingProviderId,
      },
      isUndefined
    )
  }

  static medicalBillToJSON(data: MedicalBill) {
    return {
      id: data.id,
      provider_id: data.providerId || "",
      date_of_first_service: data.dateOfFirstService,
      date_of_last_service: data.dateOfLastService,
    }
  }

  static medicalRecordToJSON(data: MedicalRecord) {
    return { id: data.id, provider_id: data.providerId || "", date_of_service: data.dateOfService }
  }
  static combineActionToJSON(data: CombineActionPayload): CombineActionPayloadDto {
    return {
      anchor_user_exhibit_id: data.anchorUserExhibitId,
      user_exhibit_ids_to_combine: data.userExhibitsToCombine,
      delete_original_exhibits_after_combination: data.deleteOriginal,
    }
  }

  static deleteActionToJSON(data: {
    partitionId: string
    pageRanges: { startPage: string | number; endPage: string | number }[]
  }): DeleteActionPayloadDto {
    return {
      partition_id: data.partitionId,
      page_ranges: data.pageRanges.map(({ startPage, endPage }) => ({
        start_page: Number(startPage),
        end_page: Number(endPage),
      })),
    }
  }

  static arrangeExhibitsToJSON(data: ArrangeExhibitOptions): ArrangeExhibitOptionsDto {
    return {
      exhibit_sorting_option: data.sortingOption,
      exhibit_grouping_option: data.groupingOption,
    }
  }
}
