import React, { useCallback, useContext, useMemo } from "react"
import { RenderElementProps } from "slate-react"
import { CitationElement } from "../CustomEditor"
import { CitationsContext } from "../features/citations/CitationsContext"
import { ExhibitCitation } from "common/citation/ExhibitCitation"

interface CitationProps extends RenderElementProps {
  element: CitationElement
}

export const Citation: React.FC<CitationProps> = React.memo(function Citation({
  attributes,
  element,
  children,
}) {
  const { references, onClick } = useContext(CitationsContext)

  const index = useMemo(
    () =>
      references.find(reference => {
        if (reference.userExhibitId) {
          return reference.userExhibitId === element.user_exhibit_id
        }

        return reference.exhibitId === element.exhibit
      })?.index || 0,
    [element, references]
  )

  const pages = element.user_exhibit_id ? element.user_exhibit_pages! : element.pages

  const handleClick = useCallback(
    (page: Nullable<number>) => {
      onClick(element, page)
    },
    [element, onClick]
  )

  return (
    <span {...attributes} data-citation data-pages={pages} data-exhibit={element.exhibit}>
      {children}
      <ExhibitCitation index={index} pages={pages} onClick={handleClick} />
    </span>
  )
})
