import { Theme } from "@mui/material/styles"

export type ButtonColor = "blue" | "grey" | "secondary" | "red" | "primaryBlue"

const getTextColorFromTheme = ({ theme, textColor }: { theme: Theme; textColor?: ButtonColor }): string => {
  switch (textColor) {
    case "grey":
      return theme.palette.grey[700]
    case "secondary":
      return theme.palette.secondary.main
    case "red":
      return theme.palette.red.warning
    case "primaryBlue":
      return theme.palette.blue.primary
    case "blue":
    default:
      return theme.palette.blue.main
  }
}

export default getTextColorFromTheme
