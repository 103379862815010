import { exhibitBuilderService } from "exhibit-builder/api/service"
import { DocumentSlice } from "../document"
import { ProvidersSlice } from "../providers"
import {
  ArrangeExhibitOptions,
  ExhibitFile,
  ExhibitPartition,
  GetState,
  MedicalBill,
  MedicalRecord,
  SetState,
  UserExhibit,
  UserExhibitPayload,
} from "../types"
import { FilesSlice } from "./filesSlice"
import { difference, isEqual, omit } from "lodash"
import { filesSelectors } from "./filesSelectors"
import * as Sentry from "@sentry/react"
import { exhibitService } from "api/services/exhibit"
import { formatDate } from "utils"
import { actions, ExhibitBuilder } from "../exhibitBuilder"
import { EB_DOC_TYPE } from "exhibit-builder/types"

export const filesActions = (
  set: SetState<FilesSlice & ExhibitBuilder>,
  get: GetState<FilesSlice & DocumentSlice & ProvidersSlice>
) => {
  const setUserExhibit = (id: UserExhibit["id"], userExhibit: Partial<UserExhibit>) => {
    set(state => ({
      userExhibitMap: {
        ...state.userExhibitMap,
        [id]: {
          ...state.userExhibitMap[id],
          ...userExhibit,
        },
      },
    }))
  }

  const updateMedicalRecordOrBill = async (
    data: MedicalRecord | MedicalBill,
    userExhibitId: UserExhibit["id"]
  ) => {
    const documentId = get().document.documentId

    let updatedRecordOrBill: MedicalRecord | MedicalBill

    if (data.type === "Medical Bill") {
      updatedRecordOrBill = await exhibitBuilderService.updateMedicalBill({ data, documentId, userExhibitId })
    } else {
      updatedRecordOrBill = await exhibitBuilderService.updateMedicalRecord({
        data,
        documentId,
        userExhibitId,
      })
      toggleUpdateSummaries(true)
    }

    set(state => ({
      recordsAndBillsMap: {
        ...state.recordsAndBillsMap,
        [data.id]: {
          ...state.recordsAndBillsMap[data.id],
          ...updatedRecordOrBill,
        },
      },
    }))
  }

  const toggleUpdateSummaries = (shouldUpdateSummaries: boolean) => {
    set({ shouldUpdateSummaries })
  }

  const checkShouldRegenerateAppointments = ({ documentId }: { documentId: string }) => {
    exhibitBuilderService
      .getShouldRegenerateAppointments({ documentId })
      .then(({ shouldRegenerateAppointments }: { shouldRegenerateAppointments: boolean }) => {
        toggleUpdateSummaries(shouldRegenerateAppointments)
      })
  }

  const regenerateSummaries = async ({ documentId }: { documentId: string }) => {
    try {
      await exhibitBuilderService.regenerateAppointments({ documentId })
      toggleUpdateSummaries(false)
    } catch (error) {
      toggleUpdateSummaries(true)
      throw error
    }
  }

  const highlightUserExhibit = (id: UserExhibit["id"]) => {
    const HIGHLIGHT_DURATION = 10_000

    setUserExhibit(id, { isHighlighted: true })
    setTimeout(() => {
      setUserExhibit(id, { isHighlighted: false })
    }, HIGHLIGHT_DURATION)
  }

  const updateUserExhibit = async (data: UserExhibitPayload) => {
    const updatedUserExhibit = await exhibitBuilderService.updateUserExhibit({
      data,
      documentId: get().document.documentId,
    })

    setUserExhibit(data.id, updatedUserExhibit)
  }

  const updateUserExhibitOrder = async (userExhibitOrder: UserExhibit["id"][]) => {
    set({ userExhibitOrder })

    defer(() =>
      set(state => {
        const updatedUserExhibitMap: FilesSlice["userExhibitMap"] = {}
        userExhibitOrder.forEach((userExhibitId, index) => {
          updatedUserExhibitMap[userExhibitId] = {
            ...state.userExhibitMap[userExhibitId],
            index,
          }
        })

        return {
          userExhibitMap: {
            ...state.userExhibitMap,
            ...updatedUserExhibitMap,
          },
        }
      })
    )

    // delete user exhibits that are not in the new exhibit order
    const isRemoving = userExhibitOrder.length < get().userExhibitOrder.length
    if (isRemoving) {
      const deletedUserExhibits = difference(get().userExhibitOrder, userExhibitOrder)
      set(state => {
        return {
          userExhibitMap: Object.fromEntries(
            Object.entries(state.userExhibitMap).filter(([id]) => !deletedUserExhibits.includes(id))
          ),
        }
      })
    }
  }

  const reorderUserExhibit = async (id: UserExhibit["id"], newIndex: number) => {
    const originalOrder = get().userExhibitOrder
    const newOrder = originalOrder.filter(userExhibitId => userExhibitId !== id)
    newOrder.splice(newIndex, 0, id)

    updateUserExhibitOrder(newOrder)

    try {
      const userExhibitOrder = await exhibitBuilderService.reorderUserExhibit({
        id,
        newIndex,
        documentId: get().document.documentId,
      })

      if (!isEqual(newOrder, userExhibitOrder)) {
        updateUserExhibitOrder(userExhibitOrder)

        Sentry.captureMessage("User exhibit order mismatch", {
          level: "warning",
          extra: {
            originalOrder,
            expectedOrder: newOrder,
            actualOrder: userExhibitOrder,
            userExhibitId: id,
            newIndex,
          },
        })
      }
    } catch (err) {
      set({
        userExhibitOrder: originalOrder,
      })

      throw err
    }
  }

  const updateExhibitPartitionOrder = (
    exhibitPartitionOrder: ExhibitPartition["id"][],
    userExhibitId: UserExhibit["id"]
  ) => {
    set(state => {
      const updatedExhibitPartitionMap: FilesSlice["exhibitPartitionMap"] = {}
      exhibitPartitionOrder.forEach(partitionId => {
        updatedExhibitPartitionMap[partitionId] = {
          ...state.exhibitPartitionMap[partitionId],
          index: exhibitPartitionOrder.indexOf(partitionId),
        }
      })

      return {
        exhibitPartitionOrder: {
          ...state.exhibitPartitionOrder,
          [userExhibitId]: exhibitPartitionOrder,
        },
        exhibitPartitionMap: {
          ...state.exhibitPartitionMap,
          ...updatedExhibitPartitionMap,
        },
      }
    })
  }

  const reorderExhibitPartitionWithinUserExhibit = async (
    id: ExhibitPartition["id"],
    newIndex: number,
    userExhibitId: UserExhibit["id"]
  ) => {
    const originalOrder = get().exhibitPartitionOrder[userExhibitId]
    const currentIndex = get().exhibitPartitionMap[id].index

    if (newIndex === currentIndex) {
      return
    }

    if (!originalOrder) {
      throw new Error("Partition order not found")
    }

    const newOrder = [...originalOrder].filter(partitionId => partitionId !== id)
    newOrder.splice(newIndex, 0, id)
    updateExhibitPartitionOrder(newOrder, userExhibitId)

    try {
      const partitionOrder = await exhibitBuilderService.reorderExhibitPartition({
        documentId: get().document.documentId,
        id,
        newIndex,
        userExhibitId,
      })

      setUserExhibit(userExhibitId, { processingStatus: "in_progress" })

      if (!isEqual(newOrder, partitionOrder)) {
        updateExhibitPartitionOrder(partitionOrder, userExhibitId)

        Sentry.captureMessage("Exhibit partition order mismatch", {
          level: "warning",
          extra: {
            originalOrder,
            expectedOrder: newOrder,
            actualOrder: partitionOrder,
            userExhibitId,
            exhibitPartitionId: id,
            newIndex,
          },
        })
      }
    } catch (err) {
      set(state => ({
        exhibitPartitionOrder: {
          ...state.exhibitPartitionOrder,
          [userExhibitId]: originalOrder,
        },
      }))

      throw err
    }
  }

  const reassignExhibitPartitionToUserExhibit = async (
    id: ExhibitPartition["id"],
    newExhibitId: UserExhibit["id"],
    index: number
  ) => {
    const oldUserExhibitId = get().exhibitPartitionMap[id].userExhibitId
    const oldSourceExhibitPartitionOrder = get().exhibitPartitionOrder[oldUserExhibitId]
    const oldTargetExhibitPartitionOrder = get().exhibitPartitionOrder[newExhibitId]
    const oldUserExhibitOrder = get().userExhibitOrder
    const oldExhibitPartition = get().exhibitPartitionMap[id]
    const oldUserExhibit = get().userExhibitMap[oldUserExhibitId]

    const expectedSourceExhibitPartitionOrder =
      get().exhibitPartitionOrder[oldUserExhibitId]?.filter(partitionId => partitionId !== id) || []
    const expectedTargetExhibitPartitionOrder = get().exhibitPartitionOrder[newExhibitId] || []
    expectedTargetExhibitPartitionOrder?.splice(index, 0, id)

    const isOldUserExhibitEmpty = expectedSourceExhibitPartitionOrder.length === 0
    const expectedUserExhibitOrder = isOldUserExhibitEmpty
      ? get().userExhibitOrder.filter(id => id !== oldUserExhibitId)
      : get().userExhibitOrder

    // optimistic update orders for better UX
    updateExhibitPartitionOrder(expectedSourceExhibitPartitionOrder, oldUserExhibitId)
    updateExhibitPartitionOrder(expectedTargetExhibitPartitionOrder, newExhibitId)
    updateUserExhibitOrder(expectedUserExhibitOrder)
    highlightUserExhibit(newExhibitId)

    if (!isOldUserExhibitEmpty) {
      highlightUserExhibit(oldUserExhibitId)
    }

    set(state => ({
      exhibitPartitionMap: {
        ...state.exhibitPartitionMap,
        [id]: {
          ...state.exhibitPartitionMap[id],
          userExhibitId: newExhibitId,
        },
      },
    }))

    try {
      const { sourceExhibitPartitionOrder, targetExhibitPartitionOrder, userExhibitOrder } =
        await exhibitBuilderService.reassignExhibitPartition({
          documentId: get().document.documentId,
          partitionId: id,
          userExhibitId: oldUserExhibitId,
          index,
          newExhibitId,
        })

      let hasMismatch = false
      if (!isEqual(expectedSourceExhibitPartitionOrder, sourceExhibitPartitionOrder)) {
        updateExhibitPartitionOrder(sourceExhibitPartitionOrder, oldUserExhibitId)
        hasMismatch = true
      }

      if (!isEqual(expectedTargetExhibitPartitionOrder, targetExhibitPartitionOrder)) {
        updateExhibitPartitionOrder(targetExhibitPartitionOrder, newExhibitId)
        hasMismatch = true
      }

      if (userExhibitOrder && !isEqual(expectedUserExhibitOrder, userExhibitOrder)) {
        updateUserExhibitOrder(userExhibitOrder)
        hasMismatch = true
      }

      if (hasMismatch) {
        Sentry.captureMessage("order mismatch after reassigning to new user exhibit", {
          level: "warning",
          extra: {
            expectedSourceExhibitPartitionOrder,
            expectedTargetExhibitPartitionOrder,
            expectedUserExhibitOrder,
            sourceExhibitPartitionOrder,
            targetExhibitPartitionOrder,
            userExhibitOrder,
            exhibitPartitionId: id,
            oldUserExhibitId,
            newExhibitId,
            index,
          },
        })
      }

      setUserExhibit(newExhibitId, { processingStatus: "in_progress" })
      if (sourceExhibitPartitionOrder.length === 0) {
        setUserExhibit(oldUserExhibitId, { processingStatus: "in_progress" })
      }
    } catch (error) {
      // revert the changes when the request fails
      set(state => ({
        exhibitPartitionMap: {
          ...state.exhibitPartitionMap,
          [id]: oldExhibitPartition,
        },
        exhibitPartitionOrder: {
          ...state.exhibitPartitionOrder,
          [oldUserExhibitId]: oldSourceExhibitPartitionOrder,
          [newExhibitId]: oldTargetExhibitPartitionOrder,
        },
        userExhibitOrder: oldUserExhibitOrder,
        userExhibitMap: {
          ...state.userExhibitMap,
          [oldUserExhibitId]: oldUserExhibit,
        },
      }))

      throw error
    }
  }

  const reorderExhibitPartition = async (
    id: ExhibitPartition["id"],
    newIndex: number,
    userExhibitId: UserExhibit["id"]
  ) => {
    const partitionUserExhibit = get().exhibitPartitionMap[id].userExhibitId

    if (partitionUserExhibit !== userExhibitId) {
      return reassignExhibitPartitionToUserExhibit(id, userExhibitId, newIndex)
    }

    return reorderExhibitPartitionWithinUserExhibit(id, newIndex, userExhibitId)
  }

  // defer the update, so it can perform delete after components stop referencing the old data
  const defer = (callback: () => void) => {
    Promise.resolve().then(callback)
  }

  const addExhibitPartitions = (exhibitPartitions: ExhibitPartition[]) => {
    set(state => {
      const exhibitPartitionMap: Record<string, ExhibitPartition> = {}
      const recordsAndBillsOrder: FilesSlice["recordsAndBillsOrder"] = {}

      exhibitPartitions.forEach(exhibitPartition => {
        exhibitPartitionMap[exhibitPartition.id] = exhibitPartition
        recordsAndBillsOrder[exhibitPartition.id] = filesSelectors.getSortedRecordsAndBills({
          exhibitPartition,
          recordsAndBillsMap: state.recordsAndBillsMap,
        })
      })

      const combinedExhibitPartitionMap = {
        ...state.exhibitPartitionMap,
        ...exhibitPartitionMap,
      }

      return {
        exhibitPartitionOrder: filesSelectors.getExhibitPartitionOrder(combinedExhibitPartitionMap),
        exhibitPartitionMap: combinedExhibitPartitionMap,
        recordsAndBillsOrder: { ...state.recordsAndBillsOrder, ...recordsAndBillsOrder },
      }
    })
  }

  const deleteExhibitPartitions = (partitions: ExhibitPartition["id"][]) => {
    set(state => {
      const partitionsToDelete = partitions.map(id => state.exhibitPartitionMap[id])
      const updatedExhibitPartitionOrder = { ...state.exhibitPartitionOrder }
      partitionsToDelete.forEach(partition => {
        const exhibitPartitionOrder = updatedExhibitPartitionOrder[partition.userExhibitId]

        if (!exhibitPartitionOrder) {
          return
        }

        updatedExhibitPartitionOrder[partition.userExhibitId] = [...exhibitPartitionOrder].filter(
          id => id !== partition.id
        )
      })

      return {
        exhibitPartitionOrder: updatedExhibitPartitionOrder,
      }
    })

    defer(() =>
      set(state => {
        return {
          exhibitPartitionMap: omit(state.exhibitPartitionMap, partitions),
        }
      })
    )
  }

  const extractPartition = async (data: {
    userExhibitId: UserExhibit["id"]
    partitionId: ExhibitPartition["id"]
    pageRanges: { start_page: number; end_page: number }[]
    deleteOriginal: boolean
    combineExtraction: boolean
  }) => {
    const oldUserExhibitOrder = get().userExhibitOrder
    const { userExhibitOrder, userExhibitMap, exhibitPartitions } =
      await exhibitBuilderService.extractPartition({
        documentId: get().document.documentId,
        ...data,
      })

    set(state => ({
      userExhibitMap: {
        ...state.userExhibitMap,
        ...userExhibitMap,
      },
    }))

    addExhibitPartitions(exhibitPartitions)
    updateUserExhibitOrder(userExhibitOrder)

    Object.values(userExhibitMap).forEach(userExhibit => highlightUserExhibit(userExhibit.id))

    if (data.deleteOriginal) {
      deleteExhibitPartitions([data.partitionId])
    }

    const newUserExhibitIds = difference(userExhibitOrder, oldUserExhibitOrder)

    return newUserExhibitIds.map(id => userExhibitMap[id])
  }

  const combineUserExhibits = async (data: {
    anchorUserExhibitId: UserExhibit["id"]
    userExhibitsToCombine: UserExhibit["id"][]
    deleteOriginal: boolean
  }) => {
    const { exhibitPartitions, userExhibitOrder, userExhibit } = await exhibitBuilderService.combineExhibits({
      data,
      documentId: get().document.documentId,
    })

    addExhibitPartitions(exhibitPartitions)
    highlightUserExhibit(data.anchorUserExhibitId)
    setUserExhibit(userExhibit.id, userExhibit)

    if (data.deleteOriginal) {
      updateUserExhibitOrder(userExhibitOrder)
    }
  }

  const deletePageRangesFromPartition = async (data: {
    partitionId: ExhibitPartition["id"]
    pageRanges: { startPage: string | number; endPage: string | number }[]
    userExhibitId: UserExhibit["id"]
  }) => {
    const { exhibitPartitions, userExhibitOrder } = await exhibitBuilderService.deletePageRanges({
      data: { partitionId: data.partitionId, pageRanges: data.pageRanges },
      userExhibitId: data.userExhibitId,
      documentId: get().document.documentId,
    })

    addExhibitPartitions(exhibitPartitions)
    deleteExhibitPartitions([data.partitionId])
    set(state => ({
      userExhibitMap: {
        ...state.userExhibitMap,
        [data.userExhibitId]: {
          ...state.userExhibitMap[data.userExhibitId],
          processingStatus: "in_progress",
        },
      },
    }))
    updateUserExhibitOrder(userExhibitOrder)
    highlightUserExhibit(data.userExhibitId)
  }

  const duplicateUserExhibit = async (id: UserExhibit["id"]) => {
    const { userExhibit, userExhibitOrder, exhibitPartitions } =
      await exhibitBuilderService.duplicateUserExhibit({
        userExhibitId: id,
        documentId: get().document.documentId,
      })

    setUserExhibit(userExhibit.id, userExhibit)
    addExhibitPartitions(exhibitPartitions)
    highlightUserExhibit(userExhibit.id)
    updateUserExhibitOrder(userExhibitOrder)
  }

  const regenerateUserExhibitPDF = async (id: UserExhibit["id"]) => {
    await exhibitService.generateUserExhibit(id)

    setUserExhibit(id, { processingStatus: "in_progress" })
  }

  const checkUserExhibitPDFStatus = async (id: UserExhibit["id"]) => {
    const oldStatus = get().userExhibitMap[id].processingStatus
    const documentId = get().document.documentId
    const exhibitBuilderType = get().exhibitBuilderType
    const status = await exhibitBuilderService.getUserExhibitPDFStatus({
      userExhibitId: id,
      documentId,
    })

    if (oldStatus !== status) {
      setUserExhibit(id, { processingStatus: status })

      if (status === "complete" && exhibitBuilderType === EB_DOC_TYPE.MEDCHRON) {
        const statuses = Object.values(get().userExhibitMap).map(userExhibit => userExhibit.processingStatus)
        if (!statuses.some(s => s === "in_progress")) {
          // TODO: // Fetch only exhibits that have been changed instead of fetching `intake_files` again
          actions(set).initialize({ documentId, type: exhibitBuilderType })
          // source UE and destination UE -- source === dest? just fetch that one UE
          // also get UE order?
        }
      }
    }

    return status
  }

  const createUserExhibit = async (exhibitId: ExhibitFile["id"], isNewExhibit: boolean) => {
    const { userExhibit, exhibitPartition, userExhibitOrder, exhibit } =
      await exhibitBuilderService.createUserExhibit({
        documentId: get().document.documentId,
        exhibitId,
        isNewExhibit,
      })

    setUserExhibit(userExhibit.id, userExhibit)
    addExhibitPartitions([exhibitPartition])
    updateUserExhibitOrder(userExhibitOrder)
    highlightUserExhibit(userExhibit.id)
    set(state => ({
      files: { ...state.files, [exhibit.id]: exhibit },
    }))
  }

  const arrangeExhibits = async (data: ArrangeExhibitOptions) => {
    const { userExhibits, exhibitPartitions } = await exhibitBuilderService.arrangeExhibits({
      data,
      documentId: get().document.documentId,
    })

    set(state => {
      const userExhibitMap: FilesSlice["userExhibitMap"] = {}
      const userExhibitOrder: FilesSlice["userExhibitOrder"] = []
      const exhibitPartitionMap: FilesSlice["exhibitPartitionMap"] = {}
      const recordsAndBillsOrder: FilesSlice["recordsAndBillsOrder"] = {}

      userExhibits.forEach(userExhibit => {
        userExhibitMap[userExhibit.id] = userExhibit
        userExhibitOrder.push(userExhibit.id)
      })

      exhibitPartitions.forEach(exhibitPartition => {
        exhibitPartitionMap[exhibitPartition.id] = exhibitPartition
        recordsAndBillsOrder[exhibitPartition.id] = filesSelectors.getSortedRecordsAndBills({
          exhibitPartition,
          recordsAndBillsMap: state.recordsAndBillsMap,
        })
      })

      const exhibitPartitionOrder = filesSelectors.getExhibitPartitionOrder(exhibitPartitionMap)

      return {
        userExhibitMap,
        userExhibitOrder,
        exhibitPartitionMap,
        exhibitPartitionOrder,
        recordsAndBillsOrder,
      }
    })
  }

  const deleteUserExhibit = async (id: UserExhibit["id"]) => {
    const oldUserExhibitOrder = get().userExhibitOrder
    const userExhibitPartitions = Object.values(get().exhibitPartitionMap)
      .filter(partition => partition.userExhibitId === id)
      .map(partition => partition.id)
    const newUserExhibitOrder = oldUserExhibitOrder.filter(userExhibitId => userExhibitId !== id)

    updateUserExhibitOrder(newUserExhibitOrder)
    deleteExhibitPartitions(userExhibitPartitions)

    const { userExhibitOrder } = await exhibitBuilderService.deleteUserExhibit({
      userExhibitId: id,
      documentId: get().document.documentId,
    })

    if (!isEqual(newUserExhibitOrder, userExhibitOrder)) {
      updateUserExhibitOrder(userExhibitOrder)

      Sentry.captureMessage("User exhibit order mismatch after deleting user exhibit", {
        level: "warning",
        extra: {
          oldUserExhibitOrder,
          newUserExhibitOrder,
          userExhibitOrder,
          userExhibitId: id,
        },
      })
    }
  }

  const generateExhibitNames = () => {
    const { providers, userExhibitMap, recordsAndBillsMap } = get()
    // for each user exhibit - generate name
    Object.values(userExhibitMap).forEach(userExhibit => {
      // based on "sorting/exhibit provider"
      let providerName = ""
      if (userExhibit.sortingProviderId) {
        providerName = providers[userExhibit.sortingProviderId]?.name
      }
      // exhibit date range
      const recordsAndBills = filesSelectors.getRecordsAndBillsByUserExhibitId(userExhibit.id)(get())
      let startDate: string | undefined
      let endDate: string | undefined

      recordsAndBills.forEach(recordOrBillId => {
        const item = recordsAndBillsMap[recordOrBillId] as MedicalRecord
        const itemStartDate = item?.dateOfService
        const itemEndDate = item?.dateOfService

        if (!itemStartDate || !itemEndDate) {
          return
        }

        if (!startDate || new Date(itemStartDate) < new Date(startDate)) {
          startDate = itemStartDate
        }
        if (!endDate || new Date(itemEndDate) > new Date(endDate)) {
          endDate = itemEndDate
        }
      })

      const newName = `${providerName} - ${formatDate(startDate, "MM/dd/yyyy", true)} - ${formatDate(endDate, "MM/dd/yyyy", true)} (Records)`

      updateUserExhibit({ id: userExhibit.id, name: newName })
    })
  }

  return {
    updateMedicalRecordOrBill,
    updateUserExhibit,
    reorderUserExhibit,
    reorderExhibitPartition,
    extractPartition,
    combineUserExhibits,
    deletePageRangesFromPartition,
    duplicateUserExhibit,
    regenerateUserExhibitPDF,
    checkUserExhibitPDFStatus,
    createUserExhibit,
    arrangeExhibits,
    deleteUserExhibit,
    generateExhibitNames,
    toggleUpdateSummaries,
    regenerateSummaries,
    checkShouldRegenerateAppointments,
  }
}
