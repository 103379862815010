import { Box, Button, Typography, colors } from "@mui/material"
import { useExhibitBuilderStore } from "../store"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { useTheme } from "@emotion/react"
import { getDeletedPageRanges } from "./getDeletedPages"
import { RenderPdfPayload } from "message-broker/types"
import { ExhibitFile } from "exhibit-builder/store/types"
import { MESSAGE_TOPIC } from "message-broker/topics"
import { getPdfPreviewPath } from "pdf-preview/utils"
import { openWindow } from "common/windows"
import { usePublish } from "message-broker/usePublish"
import { useGetExhibitUrl } from "exhibit-builder/hooks/useGetExhibitUrl"
import styled from "@emotion/styled"
import { PartitionListHeader } from "exhibit-builder/Exhibit/PartitionListHeader"
import { ExhibitName } from "exhibit-builder/Exhibit/styled"

export const DeletedPartition = styled(PartitionListHeader)(({ theme }) => ({
  [`${ExhibitName}`]: {
    color: theme.palette.blue.primary,
  },
}))

export function DeleteFiles() {
  const files = useExhibitBuilderStore(state => state.files)
  const type = useExhibitBuilderStore(state => state.exhibitBuilderType)
  const deletedPageRanges = useExhibitBuilderStore(state => getDeletedPageRanges(state))
  const caseId = useExhibitBuilderStore(state => state.caseId)
  const { getDownloadUrl } = useGetExhibitUrl()
  const theme = useTheme()
  const publish = usePublish()

  const preview = (fileId: ExhibitFile["id"], startPage: number, endPage: number) => {
    const file = files[fileId]

    const payload: RenderPdfPayload = {
      url: getDownloadUrl({ caseId, fileId, startPage, endPage }),
      fileName: file.name,
      pageNumberAdjustment: startPage - 1,
      page: startPage,
      deletedPages: [[startPage, endPage]],
      withCredentials: true,
    }

    publish(MESSAGE_TOPIC.RENDER_PDF, payload).catch(() => {
      const path = getPdfPreviewPath(payload)
      if (path) {
        openWindow(`${path.base}/${path.route}`)
      }
    })
  }

  if (!deletedPageRanges.length) {
    return null
  }

  return (
    <Box data-test="deleted-files" mt={10} mb={4}>
      <Box color={colors.red[700]} pb={0.5} mb={1} borderBottom={`1px solid ${colors.red[700]}`}>
        <Box display="flex" alignItems="center" gap={1}>
          <InfoOutlinedIcon fontSize="small" />
          <Typography variant="body1SemiBold" display="flex">
            Deleted Original Files
          </Typography>
        </Box>
        <Typography variant="body2" mt={1}>
          The pages listed below, originally included during the intake process, are now permanently deleted
          from the exhibit builder flow.
          <br />
          Please confirm that this is intentional and not an error.
        </Typography>
      </Box>

      {deletedPageRanges.flatMap(item => {
        const file = files[item.fileId]
        const range = item.pageRange
        return (
          <Box
            key={`${file.id}-${range?.startPage}-${range?.endPage}`}
            padding={theme.spacing(0.25, 1.5)}
            borderRadius={theme.spacing(0.5)}
            border={`1px solid ${theme.palette.divider}`}
            mb={1}
            data-test="deleted-file"
          >
            <DeletedPartition
              type={type}
              fileName={file.name}
              onPreview={() => range && preview(item.fileId, range.startPage, range.endPage)}
              renderCustomContent={() => {
                return (
                  <Box key={`${range?.startPage}-${range?.endPage}`} textAlign="right">
                    {range && (
                      <Typography variant="caption" mr={2} data-test="deleted-page-range">
                        Page range: ({range?.startPage} - {range?.endPage})/{file.numberOfPages}
                      </Typography>
                    )}
                    <Button
                      size="small"
                      download
                      href={getDownloadUrl({
                        caseId,
                        fileId: file.id,
                        startPage: range?.startPage,
                        endPage: range?.endPage,
                        asAttachment: true,
                      })}
                    >
                      Download File
                    </Button>
                  </Box>
                )
              }}
            />
          </Box>
        )
      })}
    </Box>
  )
}
