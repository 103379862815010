import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { Box, Autocomplete, TextField, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { VerticalCenterBox } from "common"
import { isEqual, range } from "lodash"
import { useDocumentStore } from "documents/store"
import { IcdCodeRelation, Exhibit, Partition } from "documents/types"
import { v4 } from "uuid"
import { InputBox } from "./styled"
import { useQuery } from "@tanstack/react-query"
import { queryKeys, SILENT_QUERY_PARAMS } from "react-query/constants"
import { documentsService } from "api/services/documents"
import { useDocumentContext } from "documents/context"
import { ExhibitPartition, UserExhibit } from "exhibit-builder/store/types"

interface RelationFieldsProps {
  relation: Nullable<IcdCodeRelation>
  relations: Nullable<IcdCodeRelation>[]
  onChange: (relation: Nullable<IcdCodeRelation>) => void
  onDelete?: () => void
}

interface ExhibitRelationFieldsProps extends RelationFieldsProps {
  exhibits: Record<Exhibit["id"], Exhibit>
  exhibitsOrder: Exhibit["id"][]
  partitions: Record<Partition["id"], Partition>
}

interface UserExhibitRelationFieldsProps extends RelationFieldsProps {
  partitions: Record<ExhibitPartition["id"], ExhibitPartition>
  exhibitsOrder: UserExhibit["id"][]
  exhibits: Record<UserExhibit["id"], UserExhibit>
}

export function RelationFields({ relations, relation, onChange, onDelete }: RelationFieldsProps) {
  const { documentId } = useDocumentContext()
  const exhibits = useDocumentStore(state => state.exhibits)
  const exhibitsOrder = useDocumentStore(state => state.exhibitsOrder)
  const partitions = useDocumentStore(state => state.partitions)
  const userExhibitMap = useDocumentStore(state => state.userExhibitMap)
  const userExhibitOrder = useDocumentStore(state => state.userExhibitOrder)
  const userExhibitPartitionMap = useDocumentStore(state => state.userExhibitPartitionMap)

  const { data: medchronTile } = useQuery(
    [queryKeys.documentMedchronTile, documentId],
    () => documentsService.getMedchronTile({ documentId }),
    {
      ...SILENT_QUERY_PARAMS,
      enabled: !!documentId,
    }
  )

  const isExhibitBuilder = medchronTile?.useExhibitBuilder

  if (isExhibitBuilder) {
    return (
      <UserExhibitRelationFields
        relations={relations}
        relation={relation}
        onChange={onChange}
        onDelete={onDelete}
        exhibits={userExhibitMap}
        exhibitsOrder={userExhibitOrder}
        partitions={userExhibitPartitionMap}
      />
    )
  }

  return (
    <ExhibitRelationFields
      relations={relations}
      relation={relation}
      onChange={onChange}
      onDelete={onDelete}
      exhibits={exhibits}
      exhibitsOrder={exhibitsOrder}
      partitions={partitions}
    />
  )
}

function UserExhibitRelationFields({
  relations,
  relation,
  onChange,
  onDelete,
  exhibits,
  exhibitsOrder,
  partitions,
}: UserExhibitRelationFieldsProps) {
  const uuid = useMemo(() => v4(), [])

  const getOptionLabel = useCallback(
    (exhibitId: UserExhibit["id"]) => {
      const index = exhibitsOrder.indexOf(exhibitId) + 1
      const exhibit = exhibits[exhibitId]
      return `Exhibit ${index} - ${exhibit.name}`
    },
    [exhibitsOrder, exhibits]
  )

  const [exhibitId, setExhibitId] = useState(relation ? partitions[relation.partitionId].userExhibitId : null)
  const [page, setPage] = useState(relation ? relation.page : null)

  const pageOptions = useMemo(() => {
    if (!exhibitId) return []
    const exhibit = exhibits[exhibitId]
    if (typeof exhibit.pageCount !== "number") return []
    return range(1, exhibit.pageCount + 1)
  }, [exhibits, exhibitId])

  const partitionId = useMemo(() => {
    if (!exhibitId) return null
    const partitionIds = exhibits[exhibitId].partitionIds
    const pageToFind = page ?? 1
    const partitionId = partitionIds.find((id: string) => {
      const partition = partitions[id]
      return (
        partition &&
        partition.userExhibitStartPage &&
        partition.userExhibitEndPage &&
        partition.userExhibitStartPage <= pageToFind &&
        partition.userExhibitEndPage >= pageToFind
      )
    })
    return partitionId ?? null
  }, [exhibitId, exhibits, partitions, page])

  useEffect(() => {
    const nextRelation: Nullable<IcdCodeRelation> =
      partitionId && exhibitId ? { partitionId: Number(partitionId), page } : null
    if (!isEqual(nextRelation, relation)) onChange(nextRelation)
  }, [relation, partitionId, page, onChange, exhibitId])

  const handleExhibitChange = useCallback(
    (_: React.SyntheticEvent, exhibitId: Nullable<UserExhibit["id"]>) => {
      setExhibitId(exhibitId)
      setPage(null)
    },
    []
  )

  return (
    <Box gap={2} display="flex">
      <InputBox mb={3.75}>
        <Box mb={1} fontWeight={600}>
          Exhibit
        </Box>
        <Autocomplete
          size="small"
          disablePortal
          sx={{ width: 260 }}
          id={`Exhibit_${uuid}`}
          options={exhibitsOrder}
          renderInput={params => <TextField {...params} label="Enter exhibit name/number" />}
          getOptionLabel={getOptionLabel}
          renderOption={(props, option) => (
            <li {...props} style={{ whiteSpace: "nowrap" }}>
              {getOptionLabel(option)}
            </li>
          )}
          selectOnFocus
          clearOnBlur
          value={exhibitId}
          onChange={handleExhibitChange}
        />
      </InputBox>
      <InputBox mb={2}>
        <Box mb={1} fontWeight={600}>
          Page number
        </Box>
        <Autocomplete
          size="small"
          disablePortal
          sx={{ width: 135 }}
          id={`Page-number_${uuid}`}
          options={pageOptions}
          renderInput={params => <TextField {...params} label="Enter page number" />}
          selectOnFocus
          clearOnBlur
          value={page}
          getOptionLabel={option => String(option)}
          onChange={(_, value) => setPage(value)}
          disabled={!pageOptions.length}
          getOptionDisabled={pageNumber => {
            return relations.some(
              relation => relation?.page === pageNumber && relation?.partitionId === Number(partitionId)
            )
          }}
        />
      </InputBox>
      {onDelete && (
        <VerticalCenterBox mb={1}>
          <IconButton onClick={onDelete} size="small">
            <CloseIcon />
          </IconButton>
        </VerticalCenterBox>
      )}
    </Box>
  )
}

function ExhibitRelationFields({
  relations,
  relation,
  onChange,
  onDelete,
  exhibits,
  exhibitsOrder,
  partitions,
}: ExhibitRelationFieldsProps): ReactNode {
  const uuid = useMemo(() => v4(), [])

  const getOptionLabel = useCallback(
    (exhibitId: Exhibit["id"]) => {
      const index = exhibitsOrder.indexOf(exhibitId) + 1
      const exhibit = exhibits[exhibitId]
      return `Exhibit ${index} - ${exhibit.name}`
    },
    [exhibitsOrder, exhibits]
  )

  const [exhibitId, setExhibitId] = useState(relation ? partitions[relation.partitionId].exhibitId : null)
  const [page, setPage] = useState(relation ? relation.page : null)

  const pageOptions = useMemo(() => {
    if (!exhibitId) return []
    const exhibit = exhibits[exhibitId]
    if (typeof exhibit.pageCount !== "number") return []
    return range(1, exhibit.pageCount + 1)
  }, [exhibits, exhibitId])

  const partitionId = useMemo(() => {
    if (!exhibitId) return null
    const partitionIds = exhibits[exhibitId].partitionIds
    const pageToFind = page ?? 1
    const partitionId = partitionIds.find(
      (id: number) => partitions[id].startPage <= pageToFind && partitions[id].endPage >= pageToFind
    )
    return partitionId ?? null
  }, [exhibitId, exhibits, partitions, page])

  useEffect(() => {
    const nextRelation: Nullable<IcdCodeRelation> = partitionId && exhibitId ? { partitionId, page } : null
    if (!isEqual(nextRelation, relation)) onChange(nextRelation)
  }, [relation, partitionId, page, onChange, exhibitId])

  const handleExhibitChange = useCallback((_: React.SyntheticEvent, exhibitId: Nullable<Exhibit["id"]>) => {
    setExhibitId(exhibitId)
    setPage(null)
  }, [])

  return (
    <Box gap={2} display="flex">
      <InputBox mb={3.75}>
        <Box mb={1} fontWeight={600}>
          Exhibit
        </Box>
        <Autocomplete
          size="small"
          disablePortal
          sx={{ width: 260 }}
          id={`Exhibit_${uuid}`}
          options={exhibitsOrder}
          renderInput={params => <TextField {...params} label="Enter exhibit name/number" />}
          getOptionLabel={getOptionLabel}
          renderOption={(props, option) => (
            <li {...props} style={{ whiteSpace: "nowrap" }}>
              {getOptionLabel(option)}
            </li>
          )}
          selectOnFocus
          clearOnBlur
          value={exhibitId}
          onChange={handleExhibitChange}
        />
      </InputBox>
      <InputBox mb={2}>
        <Box mb={1} fontWeight={600}>
          Page number
        </Box>
        <Autocomplete
          size="small"
          disablePortal
          sx={{ width: 135 }}
          id={`Page-number_${uuid}`}
          options={pageOptions}
          renderInput={params => <TextField {...params} label="Enter page number" />}
          selectOnFocus
          clearOnBlur
          value={page}
          getOptionLabel={option => String(option)}
          onChange={(_, value) => setPage(value)}
          disabled={!pageOptions.length}
          getOptionDisabled={pageNumber => {
            return relations.some(
              relation => relation?.page === pageNumber && relation?.partitionId === partitionId
            )
          }}
        />
      </InputBox>
      {onDelete && (
        <VerticalCenterBox mb={1}>
          <IconButton onClick={onDelete} size="small">
            <CloseIcon />
          </IconButton>
        </VerticalCenterBox>
      )}
    </Box>
  )
}
