import { useRequestContext } from "requests/context"
import { RequestFormModule } from "./RequestFormModule"
import { RequestFileManagement } from "./RequestFileManagement"

export const UploadFiles = () => {
  const { requestId, matterId, isUploadingFiles, setIsUploadingFiles } = useRequestContext()

  if (!requestId) {
    return <></>
  }

  return (
    <RequestFormModule title="Supporting Files">
      <RequestFileManagement
        requestId={requestId}
        matterId={matterId}
        isUploadingFiles={isUploadingFiles}
        setIsUploadingFiles={setIsUploadingFiles}
      />
    </RequestFormModule>
  )
}
