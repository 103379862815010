import { Box, Grid, Typography } from "@mui/material"
import styled from "@emotion/styled"
import { theme } from "app/theme"
import { REQUEST_METHODS } from "apiHelper"
import { download } from "api"
import { useFileUploader } from "common/file-uploader"
import FormFiles from "./FormFiles"
import { DeleteFileMutation, FileMetadata, SaveFileMutation } from "./types"

const StyledGrid = styled(Box)({
  display: "grid",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
  alignItems: "start",
})

interface FileManagementProps {
  title?: string
  isUploadingFiles: boolean
  setIsUploadingFiles: (isUploadingFiles: boolean) => void
  saveFileMutation: SaveFileMutation
  deleteFileMutation: DeleteFileMutation
}

export const FileManagement = ({
  title,
  isUploadingFiles,
  setIsUploadingFiles,
  saveFileMutation,
  deleteFileMutation,
}: FileManagementProps) => {
  const fileUploader = useFileUploader()

  const handleDeleteFile = (file: FileMetadata) => {
    const pk = file.pk || file.id
    if (!pk) return
    deleteFileMutation.mutate({ fileId: pk })
    fileUploader.unassignUploaded(pk)
  }

  const handleViewFile = async (file: FileMetadata) => {
    window.open(file.file_url + "?as_attachment=false", "_blank")
  }

  const handleDownloadFile = async (file: FileMetadata) => {
    const options = {
      customUrl: file.file_url,
      method: REQUEST_METHODS.GET,
      blob: true,
      json: false,
    }
    return download(options, file.name)
  }

  return (
    <StyledGrid>
      <Grid container alignContent="center">
        <Typography variant="body1SemiBold" lineHeight={2}>
          {title}
        </Typography>
      </Grid>
      <FormFiles
        onDeleteFile={handleDeleteFile}
        fileUploader={fileUploader}
        saveFileMutation={saveFileMutation}
        isUploadingFiles={isUploadingFiles}
        setIsUploadingFiles={setIsUploadingFiles}
        onViewFile={handleViewFile}
        onDownloadFile={handleDownloadFile}
      />
    </StyledGrid>
  )
}
