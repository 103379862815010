import { ReactNode } from "react"
import {
  ExhibitName,
  HeaderContainer,
  ExhibitLink,
  FileIcon,
  CustomContent,
  MedChronHeaderContainer,
  MedChronGridHeader,
  MedChronCustomContent,
} from "./styled"
import { EB_DOC_TYPE } from "exhibit-builder/types"

interface PartitionListHeaderProps {
  fileName: string
  startPage?: number
  endPage?: number
  totalPages?: number | null
  onPreview: () => void
  hideDocumentType?: boolean
  hideDateOfService?: boolean
  renderCustomContent?: () => ReactNode
  disabled?: boolean
  type?: Nullable<EB_DOC_TYPE>
}

export function PartitionListHeader({
  fileName,
  startPage,
  endPage,
  totalPages,
  onPreview,
  hideDocumentType,
  hideDateOfService,
  renderCustomContent,
  disabled,
  type = EB_DOC_TYPE.DEMAND,
  ...props
}: PartitionListHeaderProps) {
  if (type === EB_DOC_TYPE.DEMAND) {
    return (
      <HeaderContainer {...props} data-test="demand-partition-list-header">
        <ExhibitName>
          <FileIcon fontSize="small" />
          <ExhibitLink onClick={onPreview} disabled={disabled}>
            {fileName}
          </ExhibitLink>
        </ExhibitName>

        {renderCustomContent ? (
          <CustomContent>{renderCustomContent()}</CustomContent>
        ) : (
          <>
            {!hideDocumentType && <div />}
            {hideDateOfService ? <div /> : <div>Date of service</div>}
            {startPage && endPage && (
              <div>
                Pages {`(${startPage} - ${endPage})`}
                {Boolean(totalPages) && `/${totalPages}`}
              </div>
            )}
          </>
        )}
      </HeaderContainer>
    )
  }

  if (type === EB_DOC_TYPE.MEDCHRON) {
    return (
      <MedChronHeaderContainer
        {...props}
        renderCustomContent={renderCustomContent}
        data-test="medchron-partition-list-header"
      >
        <ExhibitName>
          <FileIcon fontSize="small" />
          <ExhibitLink onClick={onPreview} disabled={disabled}>
            {fileName}
          </ExhibitLink>
        </ExhibitName>
        {renderCustomContent ? (
          <MedChronCustomContent>{renderCustomContent()}</MedChronCustomContent>
        ) : (
          <MedChronGridHeader>
            <span>Provider</span>
            <span>Type</span>
            <span>Date of Service</span>
            <span>
              {" "}
              Pages {`(${startPage} - ${endPage})`}
              {Boolean(totalPages) && `/${totalPages}`}
            </span>
          </MedChronGridHeader>
        )}
      </MedChronHeaderContainer>
    )
  }

  return null
}
