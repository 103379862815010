import { useCallback } from "react"
import { StyledAppointmentProviderCell, StyledAppointmentProviderName } from "../styled"
import { Appointment, Provider } from "documents/types"
import { documentActions, useDocumentStore } from "documents/store"
import { providersSelectors } from "documents/store/providers"
import { useShallow } from "zustand/react/shallow"
import { BlackTooltip } from "common/Tooltip"
import { EditableSelect } from "evenup-ui/EditableSelect"
import { noop } from "lodash"
import { MedicalProfessionalDropdown } from "documents/components/MedicalProfessionalDropdown"
import { Box } from "@mui/material"
import { MedicalProfessional } from "common/types/medicalProfessional"
import { appointmentsSelectors } from "documents/store/appointments"
import { MedicalProfessionalsList } from "documents/components/MedicalProfessionalsList"
import { DateCell } from "./DateCell"
import { usePermissions } from "permissions/usePermissions"
import { StyledApartmentIcon } from "./styled"

interface ProviderCellProps {
  providerId: Provider["id"]
  appointmentId: Appointment["id"]
  readOnly?: boolean
}

export const ProviderCell = ({ providerId, appointmentId, readOnly }: ProviderCellProps) => {
  const { name, color } = useDocumentStore(providersSelectors.getProviderById(providerId))
  const appointment = useDocumentStore(appointmentsSelectors.getAppointmentById(appointmentId))
  const handleProviderCreate = useCallback((name: string) => documentActions.createProvider({ name }), [])
  const providers = useDocumentStore(useShallow(providersSelectors.getProviders))
  const { premiumMedChronMedicalProfessionalEnabled } = usePermissions()

  const handleProviderRename = useCallback(
    (id: Provider["id"], name: Provider["name"]) => documentActions.renameProvider({ id, name }),
    []
  )

  const handleProviderChange = useCallback(
    (providerId: Provider["id"]) => {
      documentActions.setAppointmentProvider({ appointmentId, providerId })
    },
    [appointmentId]
  )

  const handleChangeMedicalProfessionals = useCallback(
    (medicalProfessionalIds: MedicalProfessional["id"][]) => {
      const isLenghtEqual = appointment.medicalProfessionals.length === medicalProfessionalIds.length
      const isEqual =
        isLenghtEqual && appointment.medicalProfessionals.every(id => medicalProfessionalIds.includes(id))

      if (isEqual) return

      documentActions.setAppointmentMedicalProfessionals({ appointmentId, medicalProfessionalIds })
    },
    [appointmentId, appointment]
  )

  return (
    <StyledAppointmentProviderCell>
      <DateCell readOnly={readOnly} appointmentId={appointmentId} />
      <Box position="relative" pl={3.5} mt={1}>
        <StyledApartmentIcon />
        <EditableSelect
          itemType="provider"
          onRename={handleProviderRename}
          onCreate={handleProviderCreate}
          sx={{ width: "100%", marginTop: "10px" }}
          value={null}
          customInputRenderer={({ onClick }) => (
            <BlackTooltip arrow placement="bottom" title={name}>
              <span>
                <StyledAppointmentProviderName
                  onClick={readOnly ? noop : onClick}
                  color={color}
                  style={{ cursor: readOnly ? "default" : "pointer" }}
                  label={name}
                />
              </span>
            </BlackTooltip>
          )}
          disableEmpty
          onChange={handleProviderChange}
          options={providers}
        />
      </Box>
      {premiumMedChronMedicalProfessionalEnabled && (
        <Box mt={1.5}>
          <MedicalProfessionalDropdown
            readOnly={readOnly}
            initialSelectedIds={appointment.medicalProfessionals}
            onSave={handleChangeMedicalProfessionals}
            controlRenderer={MedicalProfessionalsList}
          />
        </Box>
      )}
    </StyledAppointmentProviderCell>
  )
}
