import React, { useCallback, useMemo, useRef, useState } from "react"
import { makeStyles } from "tss-react/mui"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { CaseInfo } from "api/services/case/types"
import { AttributeFiltersData } from "common/attributes-filter"
import { getAttributeValues, getJurisdictionByState } from "common/attributes-filter/utils"
import { Attributes } from "common/types/attributes"
import Box from "@mui/material/Box"
import { TemplateAttribute } from "./TemplateAttribute"
import { AttributesGroup } from "./styled"
import { queryKeys } from "react-query/constants"
import { sectionTemplateService } from "api/services/section-template"
import { TemplateList } from "./TemplateList"
import { caseService } from "api/services/case"
import { useOutletContext } from "react-router-dom"
import { Loading } from "common"
import { first, isEmpty, isEqual } from "lodash"
import { MatchingTemplates } from "api/services/section-template/types"
import { CaseAlert } from "demand/components/CaseAlert"
import { FirmDto } from "settings/Firm/Firm"
import Switch from "@mui/material/Switch"
import { LawFirm } from "demand/LawFirm/LawFirm"
import { fetchLawFirmConfig, updateCase } from "api"
import { FEATURES, useFeatures } from "hooks/useFeatures"

interface TemplatesProps {
  attributes: Attributes
  caseInfo: CaseInfo
  firmInfo?: FirmDto
}

const useStyles = makeStyles()(() => ({
  headerCell: {
    display: "flex",
    flexDirection: "column",
  },
}))

export function Templates({ attributes, caseInfo, firmInfo }: TemplatesProps): JSX.Element {
  const { classes } = useStyles()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { saveRef } = useOutletContext<{ saveRef: React.MutableRefObject<(...args: any[]) => void> }>()
  const { data: caseFirm } = useQuery(
    [queryKeys.lawFirmConfig, caseInfo.pk],
    async () => await fetchLawFirmConfig(caseInfo.pk)
  )
  const { isFeatureEnabled } = useFeatures()

  const firmId = (isFeatureEnabled(FEATURES.FIRM_TEMPLATE) && caseFirm?.firm?.pk) || null
  const initialValues = useMemo(() => {
    if (isEmpty(caseInfo.attributeValues)) {
      const jurisdictionAttribute = getJurisdictionByState(attributes, first(firmInfo?.operating_in_states))

      return { attributes: jurisdictionAttribute, firmId }
    }

    return { firmId, attributes: getAttributeValues(attributes, caseInfo.attributeValues) }
  }, [attributes, caseInfo, firmInfo, firmId])
  const [values, setValues] = useState(initialValues.attributes)
  const templateAttributes = useMemo(() => attributes.getAttributesTree(values), [attributes, values])
  const queryClient = useQueryClient()
  const latestMatchingTemplates = useRef<MatchingTemplates>()
  const { isFetching: areTemplatesLoading } = useQuery(
    [queryKeys.sectionTemplates, values, firmId],
    () => sectionTemplateService.getMatchingTemplates({ data: values, firmId }),
    {
      meta: {
        disableLoader: true,
      },
      enabled: !isEqual(initialValues, { firmId, attributes: values }),
      onSuccess: matchingTemplates => {
        latestMatchingTemplates.current = matchingTemplates
      },
    }
  )

  const { data: caseTemplates, isFetching: areCaseTemplatesLoading } = useQuery(
    [queryKeys.caseTemplates, caseInfo],
    () => caseService.getMatchingTemplates({ caseId: caseInfo.pk }),
    {
      meta: {
        disableLoader: true,
      },
    }
  )

  const { mutateAsync: updateAttributes, isLoading: isSaving } = useMutation(
    async (...args: Parameters<typeof caseService.updateCaseAttributes>) => {
      const response = await caseService.updateCaseAttributes(...args)

      queryClient.setQueryData([queryKeys.case, response.pk, "-serialized"], response)
      queryClient.invalidateQueries([queryKeys.steps, caseInfo.pk])
      queryClient.invalidateQueries([queryKeys.caseSyncedTemplates, caseInfo.pk])

      return response
    }
  )

  const updateCaseMutation = useMutation(updateCase, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.case])
    },
  })

  const handleSave = useCallback(async () => {
    await updateAttributes({ caseId: caseInfo.pk, data: values })

    queryClient.invalidateQueries([queryKeys.caseVariables])
  }, [updateAttributes, caseInfo, values, queryClient])

  const handleChange = useCallback(
    (newValues: AttributeFiltersData) => {
      setValues(currentValues =>
        getAttributeValues(attributes, {
          ...currentValues,
          ...newValues,
        })
      )
    },
    [attributes, setValues]
  )

  saveRef.current = handleSave

  const isLoading = areCaseTemplatesLoading || areTemplatesLoading
  const templates = isEqual(initialValues.attributes, values)
    ? caseTemplates
    : latestMatchingTemplates.current

  const userActionRequired = caseInfo.templatedSections.some(section => section.userActionRequired)

  const handleOnChange = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      updateCaseMutation.mutate({
        id: caseInfo.pk,
        updates: {
          page_reference_enabled: checked,
        },
      })
    },
    [caseInfo.pk, updateCaseMutation]
  )

  return (
    <>
      <Box className={classes.headerCell}>
        <b>Page Reference Enabled</b>
        <Switch onChange={handleOnChange} checked={caseInfo.pageReferenceEnabled} color="primary" />
      </Box>
      <LawFirm />
      {isSaving && <Loading show label="Updating matching templates..." />}
      {userActionRequired && (
        <CaseAlert
          canHide
          title="New templates have been added to reflect the selected attributes."
          message="Revise templates that are flagged in the side navigation to confirm desired changes."
        />
      )}
      <div>
        {templateAttributes.map(attribute => (
          <AttributesGroup key={attribute.attribute.id}>
            <TemplateAttribute attribute={attribute} onChange={handleChange} isLoading={isLoading} />
          </AttributesGroup>
        ))}
      </div>
      <TemplateList templates={templates ?? {}} attributes={attributes} isLoading={isLoading} />
    </>
  )
}
