import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined"
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined"
import FilterAltIcon from "@mui/icons-material/FilterAlt"
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined"
import Inventory2Icon from "@mui/icons-material/Inventory2"
import OpenInNew from "@mui/icons-material/OpenInNew"
import DriveFileRenameOutline from "@mui/icons-material/DriveFileRenameOutline"
import { Filters } from "./Filters/Filters"
import { useCallback, useState } from "react"
import {
  ActionContainer,
  ButtonContainer,
  StyledButton,
  StyledActionsMenuItem,
  StyledDialog,
  StyledDialogHeader,
  StyledDialogDescription,
} from "./styled"
import { NewExhibit } from "./NewExhibit/NewExhibit"
import { SortMenu } from "./SortMenu/SortMenu"
import { DownloadAll, useDownloadAllExhibits } from "./DownloadAll"
import { config } from "common/config"
import { CircularProgress, DialogActions, DialogContent, Menu, Tooltip } from "@mui/material"
import { Reset } from "./Reset/Reset"
import { EB_DOC_TYPE } from "exhibit-builder/types"
// import { SwapVert } from "@mui/icons-material"
import MoreVert from "@mui/icons-material/MoreVert"
import Download from "@mui/icons-material/Download"
import Loop from "@mui/icons-material/Loop"
import Button from "evenup-ui/Button"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"

enum ACTION {
  NEW = "NEW",
  FILTER = "FILTER",
  SORT = "SORT",
}

function DemandActions({
  selectedAction,
  handleClick,
  caseId,
}: {
  selectedAction: Nullable<ACTION>
  handleClick: (action: ACTION) => void
  caseId?: string
}) {
  return (
    <div data-test="demand-eb-actions">
      <Tooltip
        title="Create a new exhibit: choose from existing files or upload new ones"
        arrow
        placement="top"
      >
        <StyledButton
          isActive={selectedAction === ACTION.NEW}
          startIcon={<FileUploadOutlinedIcon />}
          onClick={() => handleClick(ACTION.NEW)}
        >
          New Exhibit
        </StyledButton>
      </Tooltip>
      <Tooltip
        title="Filter your exhibits below. This will not affect generated demand or other sections of the portal"
        arrow
        placement="top"
      >
        <StyledButton
          isActive={selectedAction === ACTION.FILTER}
          startIcon={selectedAction === ACTION.FILTER ? <FilterAltIcon /> : <FilterAltOutlinedIcon />}
          onClick={() => handleClick(ACTION.FILTER)}
          data-test="filter-button"
        >
          Filters
        </StyledButton>
      </Tooltip>
      <Tooltip title="Sort and group your exhibits to meet firm requirements" arrow placement="top">
        <StyledButton
          isActive={selectedAction === ACTION.SORT}
          startIcon={selectedAction === ACTION.SORT ? <Inventory2Icon /> : <Inventory2OutlinedIcon />}
          onClick={() => handleClick(ACTION.SORT)}
          data-test="sort-menu-button"
        >
          Open Exhibit Sorting Menu
        </StyledButton>
      </Tooltip>
      {caseId ? (
        <Tooltip
          title="Download all exhibits below. The files will be downloaded with no annotations,
 and in the exact state they appear in generated demand"
          arrow
          placement="top"
        >
          <DownloadAll caseId={caseId} />
        </Tooltip>
      ) : null}
      <Tooltip
        title="Reset all exhibit manipulations and return to original intake view "
        arrow
        placement="top"
      >
        <Reset />
      </Tooltip>
      <Tooltip title="Opens to the Kili annotation program " arrow placement="top">
        <StyledButton
          startIcon={<OpenInNew />}
          target="_blank"
          href={`${config.api.host}/case/${caseId}/kili/`}
        >
          Annotation Tool
        </StyledButton>
      </Tooltip>
    </div>
  )
}

function MedChronActions({
  selectedAction,
  handleClick,
}: {
  selectedAction: Nullable<ACTION>
  handleClick: (action: ACTION) => void
}) {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const documentId = useExhibitBuilderStore(state => state.document.documentId)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleMenuToggleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const { handleDownloadExhibits, downloadingExhibits } = useDownloadAllExhibits({ documentId })

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div data-test="medchron-eb-actions">
      {/* NOTE: Sort may not be necessary since all records are sorted by DoS by default */}
      {/* <Tooltip title="Sort exhibits by first date of service per provider" arrow placement="top">
        <StyledButton startIcon={<SwapVert />} onClick={() => {}} data-test="sort-button">
          Sort: By Date
        </StyledButton>
      </Tooltip> */}
      <Tooltip
        title={
          'Automatically change exhibit names to "[Exhibit Provider] - [Appointment Date Range] - [Sub Doc Type]" format'
        }
        arrow
        placement="top"
      >
        <StyledButton
          startIcon={<DriveFileRenameOutline />}
          onClick={() => {
            setIsDialogOpen(true)
          }}
          data-test="generate-exhibit-names"
        >
          Generate Exhibit Names
        </StyledButton>
      </Tooltip>
      <GenerateExhibitNameConfirmationDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
      <Tooltip
        title="Create a new exhibit: choose from existing files or upload new ones"
        arrow
        placement="top"
      >
        <StyledButton
          isActive={selectedAction === ACTION.NEW}
          startIcon={<FileUploadOutlinedIcon />}
          onClick={() => handleClick(ACTION.NEW)}
        >
          New Exhibit
        </StyledButton>
      </Tooltip>
      <StyledButton startIcon={<MoreVert />} onClick={handleMenuToggleClick}>
        More
      </StyledButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: -10,
          horizontal: "left",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <StyledActionsMenuItem
          onClick={() => {
            handleDownloadExhibits()
            handleClose()
          }}
          disabled={downloadingExhibits}
        >
          {downloadingExhibits ? (
            <CircularProgress size={16} sx={{ marginRight: "1.5rem" }} />
          ) : (
            <Download sx={{ marginRight: "1rem" }} />
          )}{" "}
          Download All Exhibits
        </StyledActionsMenuItem>
        <Reset
          onClick={handleClose}
          component={
            <StyledActionsMenuItem>
              <Loop sx={{ marginRight: "1rem", transform: "rotate(90deg)" }} />
              Reset All Exhibit Actions
            </StyledActionsMenuItem>
          }
        />
      </Menu>
    </div>
  )
}

function GenerateExhibitNameConfirmationDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const confirmAction = useCallback(() => {
    exhibitBuilderActions.generateExhibitNames()
    onClose()
  }, [onClose])

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <StyledDialogHeader variant="dialogHeader" component="h3">
          Generate Exhibit Names Confirmation
        </StyledDialogHeader>
        <StyledDialogDescription component="p">
          This action will only rename Exhibits that are Provider Document Types. Naming convention will
          appear as:
        </StyledDialogDescription>
        <StyledDialogDescription component="p">
          [Exhibit Provider] - [Appointment Date Range] - Records
        </StyledDialogDescription>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button color="primary" onClick={confirmAction}>
          Confirm Updates
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export function Actions({ caseId, type }: { caseId?: string; type: EB_DOC_TYPE }) {
  const [selectedAction, setSelectedAction] = useState<Nullable<ACTION>>(null)

  const handleClick = (action: ACTION) => {
    setSelectedAction(action === selectedAction ? null : action)
  }

  return (
    <div>
      <ButtonContainer>
        {type === EB_DOC_TYPE.DEMAND && (
          <DemandActions selectedAction={selectedAction} handleClick={handleClick} caseId={caseId} />
        )}
        {type === EB_DOC_TYPE.MEDCHRON && (
          <MedChronActions selectedAction={selectedAction} handleClick={handleClick} />
        )}
      </ButtonContainer>
      {selectedAction && (
        <ActionContainer>
          {selectedAction === ACTION.FILTER && <Filters />}
          {selectedAction === ACTION.SORT && <SortMenu />}
          {selectedAction === ACTION.NEW && <NewExhibit />}
        </ActionContainer>
      )}
    </div>
  )
}
