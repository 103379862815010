import { ReactNode, useCallback, useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { documentsService } from "api/services/documents"
import { useDocumentStore } from "documents/store"
import { queryKeys, SILENT_QUERY_PARAMS } from "react-query/constants"
import { MedicalProfessionalSelect } from "./MedicalProfessionalSelect"
import { MedicalProfessional } from "common/types/medicalProfessional"

interface MedicalProfessionalDropdownProps {
  onSave?: (ids: MedicalProfessional["id"][]) => void
  initialSelectedIds?: MedicalProfessional["id"][]
  readOnly?: boolean
  controlRenderer?: (props: {
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void
    selectedIds: MedicalProfessional["id"][]
    readOnly?: boolean
  }) => ReactNode
}

export function MedicalProfessionalDropdown({
  onSave,
  initialSelectedIds,
  readOnly,
  controlRenderer,
}: MedicalProfessionalDropdownProps): ReactNode {
  const documentId = useDocumentStore(state => state.documentId)
  const [selectedIds, setSelectedIds] = useState<MedicalProfessional["id"][]>(initialSelectedIds ?? [])

  const { data } = useQuery(
    [queryKeys.documentMedicalProfessionals, documentId],
    () => documentsService.getMedicalProfessionals({ documentId }),
    {
      enabled: !!documentId,
      ...SILENT_QUERY_PARAMS,
    }
  )

  const handleChangeSelection = useCallback((ids: MedicalProfessional["id"][]) => {
    setSelectedIds(ids)
  }, [])

  const handleSave = useCallback(() => {
    onSave?.(selectedIds)
  }, [onSave, selectedIds])

  useEffect(() => {
    if (!initialSelectedIds) return

    setSelectedIds(initialSelectedIds)
  }, [initialSelectedIds])

  if (!data) return null

  return (
    <MedicalProfessionalSelect
      readOnly={readOnly}
      selectedIds={selectedIds}
      controlRenderer={controlRenderer}
      onChangeSelection={handleChangeSelection}
      onSave={handleSave}
      medicalProfessionals={data.medicalProfessionals}
      label="Medical Professional"
      searchLabel="Search/Create Medical Professional"
    />
  )
}
