import { useMemo } from "react"
import { useProviderSummariesStore } from "./store"
import { Typography } from "@mui/material"
import { CitationsContainer } from "./styled"
import { BulletPointResult } from "api/services/summaries/types"
import { CitationButton } from "../Annotation/InjuryDetails/BulletPointSummary"

export function CitationsTab({ dateOfService }: { dateOfService: string }) {
  const dosCitations = useProviderSummariesStore(
    state =>
      state.injuryDetails.get(dateOfService)?.dos_summary?.summary?.result?.result as
        | BulletPointResult[]
        | undefined
  )
  const caseId = useProviderSummariesStore(state => state.caseId)
  const citations = useProviderSummariesStore(
    state => state.medicalSummaries.get(dateOfService)?.summaryCitations
  )

  const valuableCitations = useMemo(() => {
    const data = citations || dosCitations

    return data ?? []
  }, [citations, dosCitations])

  if (!valuableCitations.length) return null

  return (
    <CitationsContainer>
      {valuableCitations.map(citation => (
        <div key={citation.header}>
          <Typography variant="helper" sx={{ fontWeight: "bold" }}>
            {citation.header}
          </Typography>
          <ul>
            {citation.values?.length ? (
              citation.values.map((value, idx) => (
                <li key={`${value.text}_${idx}`}>
                  <Typography variant="helper">{value.text}</Typography>
                  {value.citations?.map((citation, citationIndex) => {
                    return (
                      <CitationButton
                        key={`${value.text}_${idx}-${citationIndex}`}
                        caseId={caseId}
                        exhibitId={citation.exhibit_id}
                        partitionId={citation.partition_id}
                        page={citation.page}
                        userExhibitId={citation.user_exhibit_id}
                        sx={{ width: "16px", height: "16px" }}
                      />
                    )
                  })}
                </li>
              ))
            ) : (
              <li key={`${citation.header}_no_value`}>
                <Typography variant="helper" fontStyle="italic">
                  Not Specified
                </Typography>
              </li>
            )}
          </ul>
        </div>
      ))}
    </CitationsContainer>
  )
}
