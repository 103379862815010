import { range } from "lodash"
import { useCallback, useRef } from "react"
import { v4 } from "uuid"
import { RelationFields } from "./RelationFields"
import { IcdCodeRelation } from "documents/types"
import { Box } from "@mui/system"
import TextButton from "common/buttons/TextButton"

function getRelationIds(id: string | undefined, count: number = 1) {
  return {
    id,
    uuids: range(0, Math.max(count, 1)).map(() => `${id}_${v4()}`),
  }
}

interface RelationsProps {
  relations: Nullable<IcdCodeRelation>[]
  id?: string
  onChange: (relations: Nullable<IcdCodeRelation>[]) => void
}

export function Relations({ relations, id, onChange }: RelationsProps) {
  const canAddRelations = !relations.some(relation => !relation)
  const relationUuids = useRef(getRelationIds(id, relations.length))

  const handleDeleteRelation = (idx: number) => () => {
    const nextRelations = [...relations]
    nextRelations.splice(idx, 1)

    relationUuids.current.uuids.splice(idx, 1)
    onChange(nextRelations)
  }

  const handleAddRelation = useCallback(() => {
    onChange([...relations, null])
    relationUuids.current.uuids.push(v4())
  }, [relations, onChange])

  const handleRelationChange = (idx: number) => (relation: Nullable<IcdCodeRelation>) => {
    const nextRelations = [...relations]
    nextRelations.splice(idx, 1, relation)

    onChange(nextRelations)
  }

  return (
    <>
      <Box>
        {relations.map((relation, idx) => (
          <RelationFields
            key={relationUuids.current.uuids[idx]}
            relation={relation}
            relations={relations}
            onDelete={relations.length > 1 ? handleDeleteRelation(idx) : undefined}
            onChange={handleRelationChange(idx)}
          />
        ))}
      </Box>
      <Box>
        <TextButton disabled={!canAddRelations} onClick={handleAddRelation}>
          + Add Another Reference Exhibit
        </TextButton>
      </Box>
    </>
  )
}
