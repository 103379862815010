import { QueryObserverOptions } from "@tanstack/react-query"

export const queryKeys = {
  user: "user",
  users: "users",
  trial: "trial",
  session: "session",
  searchResults: "searchResults",
  cases: "cases",
  case: "case",
  caseWithAnnotation: "caseWithAnnotation",
  favoriteCases: "favoriteCases",
  documents: "documents",
  formSections: "formSections",
  steps: "steps",
  plaintiff: "plaintiff",
  plaintiffs: "plaintiffs",
  incomeLoss: "incomeLoss",
  incomeLossCalculation: "incomeLossCalculation",
  carrier: "carrier",
  providers: "providers",
  icdCodes: "icd",
  cptCodes: "cpt",
  attorneys: "attorneys",
  lawFirmConfig: "lawFirmConfig",
  firms: "firms",
  firmsUserManage: "firmsUserManage",
  firm: "firm",
  firmMinimal: "firmMinimal",
  attorney: "attorney",
  firmLogo: "firmLogo",
  household: "household",
  calculateHousehold: "calcHousehold",
  householdRelevantDates: "householdRelevantDate",
  nonEconomic: "nonEconomic",
  calculateNonEconomic: "calculateNonEconomic",
  facts: "facts",
  property: "property",
  validateDemand: "validateDemand",
  usStates: "usStates",
  eduLevel: "eduLevel",
  sectionTypes: "sectionTypes",
  damagesSections: "damagesSections",
  requests: "requests",
  isInSquad: "isInSquad",
  documentGenerations: "documentGenerations",
  request: "request",
  requestEdit: "requestEdit",
  requestFiles: "requestFiles",
  calculateCredits: "calculateCredits",
  intakeAsset: "intakeAsset",
  userAccount: "userAccount",
  internalUsers: "internalUsers",
  externalUsers: "externalUsers",
  submitters: "submittersSearch",
  futureExpense: "futureExpense",
  annotationRequests: "annotationRequests",
  userSettings: "userSettings",
  medicalAssociations: "medicalAssociations",
  exhibits: "exhibits",
  annotated_exhibits: "annotated_exhibits",
  partition_providers: "partition_providers",
  nonEconomicValidation: "nonEconomicValidation",
  householdValidation: "householdValidation",
  incomeLossColumns: "incomeLossColumns",
  caseVariables: "caseVariables",
  settlements: "settlements",
  introduction: "introduction",
  conclusion: "conclusion",
  painAndSuffering: "painAndSuffering",
  dosSummaryStatus: "dosSummaryStatus",
  userExhibitPDFStatus: "userExhibitPDFStatus",
  caseSyncedTemplates: "caseSyncedTemplates",
  // Demand generation
  generateDemand: "generateDemand",
  allGeneratedDemands: "allGeneratedDemands",
  missingExhibits: "missingExhibits",
  missingExhibitEvents: "missingExhibitEvents",
  providersMinimal: "providersMinimal",
  firmContracts: "firmContracts",
  firmContract: "firmContract",
  firmFirstContract: "firmFirstContract",
  requestRevision: "requestRevision",
  requestRevisionEvents: "requestRevisionEvents",
  providerUpdates: "providerUpdates",
  // Library
  attributes: "attributes",
  sectionTemplates: "sectionTemplates",
  sectionTemplatesByFirm: "sectionTemplatesByFirm",
  providerTemplates: "providerTemplates",
  documentStructureNames: "documentStructureNames",
  documentStructuresList: "listDocumentStructures",
  documentStructure: "documentStructure",
  archivedDocumentStructures: "archivedDocumentStructures",
  libraryVariables: "libraryVariables",
  libraryVariableGroups: "libraryVariableGroups",
  caseTemplates: "caseTemplates",
  documentStructuresComponents: "caseTemplates",
  defaultVariables: "defaultVariables",
  // Annotated Providers
  annotatedAppointments: "annotatedAppointments",
  annotatedBills: "annotatedBills",
  annotatedIcdCodes: "annotatedIcdCodes",
  annotatedInjuryDetails: "annotatedInjuryDetails",
  // LLM
  generatorKeys: "generatorKeys",
  providerSummary: "providerSummary",
  // Summaries
  summary: "summary",
  generators: "generators",
  // Matter
  flags: "flags",
  permissions: "permissions",
  allPermissions: "allPermissions",
  featurePermissions: "featurePermissions",
  allUsers: "allUsers",
  // Credits
  creditBreakdown: "creditBreakdown",
  // Import Integrations
  smartAdvocateCaseInfo: "smartAdvocateCaseInfo",
  smartAdvocateInsurance: "smartAdvocateInsurance",
  smartAdvocateDocuments: "smartAdvocateDocuments",
  // Document
  document: "document",
  documentPrefetch: "documentPrefetch",
  documentData: "documentData",
  documentProviders: "documentProvider",
  documentExhibitOrder: "documentExhibitOrder",
  documentExhibits: "documentExhibits",
  documentAppointments: "documentAppointments",
  documentPartitions: "documentPartitions",
  documentPlaintiff: "documentPlaintiff",
  documentMedchronTile: "documentMedchronTile",
  documentMedicronCalendarData: "documentMedicronCalendarData",
  documentMatchesDocumentStructure: "documentMatchesDocumentStructure",
  documentStoreUserExhibitData: "documentStoreUserExhibitData",
  documentMedicalProfessionals: "documentMedicalProfessionals",

  // Cases
  casesV2: "casesV2",
  caseFlags: "caseFlags",
  caseFlagsPublished: "caseFlagsPublished",
  caseCheck: "caseCheck",
  caseCheckMissingDocs: "caseCheckMissingDocs",
  creators: "creators",
  caseFiles: "caseFiles",

  // executive analytics
  requestEfficiency: "requestEfficiency",
  missingDocuments: "missingDocuments",

  treatmentContinuity: "treatmentContinuity",
  treatmentVelocity: "treatmentVelocity",

  // Document Structure Content
  documentStructureContentComponents: "documentStructureContentComponents",

  // Case Q&A
  chats: "chats",
}

export type QueryKeys = keyof typeof queryKeys

export const STALE_TIMEOUT = {
  DEFAULT: 120_000,
  SHORT: 60_000,
  STATIC_DATA: 300_000,
  PERMANENT: Infinity,
} as const

export const DEFAULT_QUERY_PARAMS: Pick<QueryObserverOptions, "staleTime" | "keepPreviousData"> = {
  // `staleTime` option enables data sharing between same queries in different places
  // So when data will refetched - it will be shared across other queries with same query keys
  // It works very like context data but without any react binding around it
  staleTime: STALE_TIMEOUT.DEFAULT,
  keepPreviousData: true,
} as const

export const SILENT_QUERY_PARAMS: Pick<QueryObserverOptions, "staleTime" | "keepPreviousData" | "meta"> = {
  // `staleTime` option enables data sharing between same queries in different places
  // So when data will refetched - it will be shared across other queries with same query keys
  // It works very like context data but without any react binding around it
  staleTime: STALE_TIMEOUT.DEFAULT,
  keepPreviousData: true,
  meta: { disableLoader: true },
} as const
