import { useState, useCallback, useEffect } from "react"
import { useQuery, useMutation, useQueryClient, QueryKey } from "@tanstack/react-query"
import { CircularProgress } from "@mui/material"
import { usePermissions } from "permissions/usePermissions"
import { useTheme } from "@mui/material/styles"

import { useHandleMessages } from "common/messages/useHandleMessages"
import { queryKeys, SILENT_QUERY_PARAMS } from "react-query/constants"
import { caseService } from "api/services/case"
import { useDialog } from "hooks/useDialog"
import { getBannerLabel } from "./utils"
import { Banner, BannerContent, SyncButton } from "./styled"

type DemandSyncTemplatedSectionsBannerProps = {
  caseId: PrimaryKey
}

export const DemandSyncTemplatedSectionsBanner = ({ caseId }: DemandSyncTemplatedSectionsBannerProps) => {
  const [label, setLabel] = useState("")
  const {
    isOpen: isOpenSyncTemplatedSectionsBanner,
    openDialog: openSyncTemplatedSectionsBanner,
    closeDialog: closeSyncTemplatedSectionsBanner,
  } = useDialog()

  const { showErrorMessage, showSuccessMessage } = useHandleMessages()
  const { syncCaseTemplatedSectionsBannerEnabled } = usePermissions()

  const theme = useTheme()

  const queryClient = useQueryClient()

  const { data: caseTemplatedSectionsDetails } = useQuery(
    [queryKeys.caseSyncedTemplates, caseId],
    () => caseService.getSyncedTemplates({ caseId }),
    { ...SILENT_QUERY_PARAMS, enabled: syncCaseTemplatedSectionsBannerEnabled }
  )

  const { mutateAsync: syncCaseTemplatedSectionsDetails, isLoading: isSyncingTemplatedSectionsDetails } =
    useMutation(async () => {
      const response = await caseService.syncCaseTemplatedSections({ caseId })

      queryClient.setQueryData([queryKeys.case, caseId, "-serialized"], response)

      const queryKeysForInvalidation: QueryKey[] = [
        // sidebar
        [queryKeys.steps, caseId],

        // case sync templated section details
        [queryKeys.caseSyncedTemplates, caseId],

        // case sections
        [queryKeys.facts, caseId],
        [queryKeys.conclusion, caseId],
        [queryKeys.introduction, caseId],
        [queryKeys.painAndSuffering, caseId],
      ]

      queryKeysForInvalidation.forEach(queryKey => {
        queryClient.invalidateQueries(queryKey)
      })
    })

  useEffect(() => {
    const { caseTemplatedSectionsSyncDetails, newTemplatesIntroduced } = caseTemplatedSectionsDetails || {}

    const caseTemplatedSectionsSyncRequired = caseTemplatedSectionsSyncDetails?.filter(
      ({ syncRequired }) => syncRequired
    )

    const areSectionsUpdated = !!caseTemplatedSectionsSyncRequired?.length

    const caseTemplatedSections = caseTemplatedSectionsSyncDetails
      ?.filter(({ syncRequired }) => syncRequired)
      ?.map(({ section }) => section)

    if (areSectionsUpdated || newTemplatesIntroduced) {
      const newLabel = getBannerLabel({
        areSectionsUpdated,
        newTemplatesIntroduced,
        caseTemplatedSections,
      })

      setLabel(newLabel)

      openSyncTemplatedSectionsBanner()
    } else {
      closeSyncTemplatedSectionsBanner()
    }
  }, [caseTemplatedSectionsDetails, openSyncTemplatedSectionsBanner, closeSyncTemplatedSectionsBanner])

  const handleSync = useCallback(async () => {
    try {
      await syncCaseTemplatedSectionsDetails()
      closeSyncTemplatedSectionsBanner()
      showSuccessMessage({ message: "Document Structure is updated successfully" })
    } catch (error) {
      showErrorMessage({ message: "Error syncing templated sections", error })
    }
  }, [
    syncCaseTemplatedSectionsDetails,
    closeSyncTemplatedSectionsBanner,
    showErrorMessage,
    showSuccessMessage,
  ])

  if (!isOpenSyncTemplatedSectionsBanner || !label) return null

  return (
    <Banner severity="error" role="aside" aria-label="Syncing templated sections banner">
      <BannerContent>
        {isSyncingTemplatedSectionsDetails ? (
          <>
            Syncing templated sections for the case... <CircularProgress size={theme.spacing(3)} />
          </>
        ) : (
          <>
            {label}. Please
            <SyncButton onClick={handleSync}>click here</SyncButton>
            to update your Drafting workflow.
          </>
        )}
      </BannerContent>
    </Banner>
  )
}
