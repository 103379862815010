import { PROVIDER_SUMMARY_STATUS } from "api/services/summaries/types"
import { ProviderSummaryStoreType } from "./types"
import { getAnnotatedInjuryDetails } from "api"
import { DateToPassages } from "../../Annotation/InjuryDetails/types"
import { ProviderMedicalProfessional, ProviderMedicalSummary } from "api/services/provider-summaries/types"
import { providerMedicalSummariesService } from "api/services/provider-summaries"
import { isEqual, omit } from "lodash"
import { IN_PROGRESS_STATES } from "../constants"
import { POLLING_INTERVAL } from "./constants"

export function createActions(
  get: GetState<ProviderSummaryStoreType>,
  set: SetState<ProviderSummaryStoreType>
) {
  let abortController = new AbortController()

  function getInProgressSummaries() {
    const { medicalSummaries } = get()

    return Array.from(medicalSummaries.values()).filter(medicalSummary =>
      IN_PROGRESS_STATES.includes(medicalSummary.status)
    )
  }

  function updateMedicalSummary(summary: ProviderMedicalSummary) {
    const summaries = get().medicalSummaries

    set({
      medicalSummaries: summaries.set(summary.dateOfService, summary),
    })
  }

  function updateChangedSummaries(items: ProviderMedicalSummary[]) {
    const summaries = get().medicalSummaries
    let hasUpdates = false

    for (const item of items) {
      const currentItem = summaries.get(item.dateOfService)

      if (!currentItem || !isEqual(omit(item, ["summary"]), omit(currentItem, ["summary"]))) {
        summaries.set(item.dateOfService, item)
        hasUpdates = true
      }
    }

    if (hasUpdates) {
      set({ medicalSummaries: summaries })
    }
  }

  function fetchMedicalSummaries(forcePollingSignal?: AbortSignal) {
    const { medicalSummariesState, caseId, providerId, medicalSummaries, injuryDetails, injuryDetailsState } =
      get()
    const hasDatesOfService = injuryDetails.size > 0 || injuryDetailsState !== "loaded"
    const shouldForcePoll =
      medicalSummaries.size === 0 && (hasDatesOfService || forcePollingSignal?.aborted === false)

    if (!shouldForcePoll && medicalSummariesState === "loaded" && !getInProgressSummaries().length) {
      return
    }

    if (medicalSummariesState === "loading") return

    if (medicalSummariesState === "initial") {
      set({ medicalSummariesState: "loading" })
    }

    providerMedicalSummariesService.getMedicalSummaries({ caseId, providerId }).then(
      summaries => {
        set({
          medicalSummariesState: "loaded",
        })
        updateChangedSummaries(summaries)
        scheduleMedicalSummariesRefetch(forcePollingSignal)
      },
      () => {
        set({ medicalSummariesState: "error" })
      }
    )
  }

  function scheduleMedicalSummariesRefetch(forcePollingSignal?: AbortSignal) {
    const signal = abortController.signal
    const inProgressSummaries = getInProgressSummaries()

    if (inProgressSummaries.length > 0) {
      setTimeout(() => {
        if (!signal.aborted) {
          fetchMedicalSummaries(forcePollingSignal)
        }
      }, POLLING_INTERVAL)
    }
  }

  async function regenerateMedicalSummary(
    summaryDate: string,
    medicalProfessional: ProviderMedicalProfessional
  ) {
    const { medicalSummaries: summaries, caseId, providerId } = get()
    const summary = summaries.get(summaryDate)

    if (!summary) return

    updateMedicalSummary({
      ...summary,
      status: PROVIDER_SUMMARY_STATUS.PENDING,
      medicalProfessional,
    })

    try {
      await providerMedicalSummariesService.updateSummary({
        summaryId: summary.id,
        medicalProfessional,
        caseId,
        providerId,
      })
      await providerMedicalSummariesService.regenerateSummaries({ caseId, providerId, dates: [summaryDate] })
      scheduleMedicalSummariesRefetch()
    } catch (error) {
      updateMedicalSummary(summary)
      throw error
    }
  }

  function updateMedicalSummaries(
    summaries: Map<string, ProviderMedicalSummary>,
    status?: PROVIDER_SUMMARY_STATUS
  ) {
    const nextSummaries = new Map(
      status ? [...summaries].map(([date, summary]) => [date, { ...summary, status }]) : [...summaries]
    )

    set({
      medicalSummaries: nextSummaries,
    })
  }

  async function regenerateMedicalSummaries() {
    const { medicalSummaries: summaries, caseId, providerId } = get()

    updateMedicalSummaries(summaries, PROVIDER_SUMMARY_STATUS.PENDING)

    try {
      await providerMedicalSummariesService.regenerateSummaries({ caseId, providerId })
      scheduleMedicalSummariesRefetch()
    } catch (error) {
      updateMedicalSummaries(summaries)
      throw error
    }
  }

  const fetchInjuryDetails = async () => {
    const { injuryDetailsState, caseId, providerId } = get()

    if (injuryDetailsState === "loaded") {
      return
    }

    return getAnnotatedInjuryDetails(caseId, providerId).then(
      (data: DateToPassages) => {
        set({
          injuryDetailsState: "loaded",
          injuryDetails: new Map(Object.entries(data)),
        })
      },
      () => set({ injuryDetailsState: "error" })
    )
  }

  const cancelPolling = () => {
    abortController.abort()
    abortController = new AbortController()
  }

  const setExpandAction = (action?: ProviderSummaryStoreType["action"]) => {
    if (get().action !== action) {
      set({ action })
    }
  }

  return {
    fetchMedicalSummaries,
    regenerateMedicalSummary,
    regenerateMedicalSummaries,

    fetchInjuryDetails,

    cancelPolling,
    setExpandAction,
  }
}
