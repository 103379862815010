import { EditorRoot, ListElement, ParagraphElement } from "common/form-components/rich-text/CustomEditor"
import {
  ProviderMedicalProfessional,
  ProviderMedicalProfessionalDto,
  ProviderMedicalSummary,
  ProviderMedicalSummaryDto,
  ProviderMedicalSummaryField,
  ProviderSummaryFieldDto,
  ProviderSummaryResultDto,
} from "./types"
import {
  createList,
  createListItem,
  createListItemContent,
  createParagraph,
  createText,
  createVariable,
} from "common/form-components/rich-text/create"
import { LIST_BLOCK_ELEMENTS } from "common/form-components/rich-text/elements"

export class ProviderMedicalSummariesDeserializer {
  static fromListJSON(data: ProviderMedicalSummaryDto[]): ProviderMedicalSummary[] {
    return data.map(ProviderMedicalSummariesDeserializer.fromJSON)
  }

  static fromJSON(data: ProviderMedicalSummaryDto): ProviderMedicalSummary {
    return {
      id: data.id,
      dateOfService: data.date_of_service,
      status: data.status,
      stale: data.is_stale,
      medicalProfessional: ProviderMedicalSummariesDeserializer.medicalProfessionalFromJSON(
        data.selected_medical_professional_data
      ),
      medicalProfessionalOptions: data.available_medical_professionals_data.map(
        ProviderMedicalSummariesDeserializer.medicalProfessionalFromJSON
      ),
      summaryContent: ProviderMedicalSummariesDeserializer.summaryResultToEditorContent(data.summary),
      summaryResultId: data.summary?.result?.pk ?? null,
      summaryCitations: ProviderMedicalSummariesDeserializer.summaryCitationsFromJSON(data.summary?.fields),
    }
  }

  static summaryCitationsFromJSON(
    data: Nullable<ProviderSummaryFieldDto[]> | undefined
  ): Nullable<ProviderMedicalSummaryField[]> {
    if (!data) return null

    const field = data.find(item => item.field === "medical_information")

    if (!field) return null

    try {
      return JSON.parse(field.value)
    } catch {
      return null
    }
  }

  static medicalProfessionalFromJSON(data: ProviderMedicalProfessionalDto = {}): ProviderMedicalProfessional {
    return {
      title: data.title ?? "",
      firstName: data.first_name ?? "",
      lastName: data.last_name ?? "",
      designation: data.designation ?? "",
    }
  }

  static summaryResultToEditorContent(data: Nullable<ProviderSummaryResultDto>): Nullable<EditorRoot> {
    if (!data?.result?.result) return null

    const editorNodes: EditorRoot = []
    const lines = data.result.result.split("\n")

    let listStructure: ListElement[] = []
    const listRegex = /^(\t*)- (.*)$/
    const variableRegex = /\[\[(\w+)]]/

    function getParagraphNode(text: string): ParagraphElement {
      const node = createParagraph()
      const chunks = text.split(variableRegex)

      node.children = chunks.map((chunk, idx) => {
        return idx % 2 === 0 ? createText({ text: chunk }) : createVariable(chunk)
      })

      return node
    }

    for (const line of lines) {
      const match = line.match(listRegex)

      if (!match) {
        editorNodes.push(getParagraphNode(line))
        listStructure = []
        continue
      }

      if (!listStructure.length) {
        const listNode = createList([], LIST_BLOCK_ELEMENTS.UNORDERED_LIST)
        listStructure.push(listNode)
        editorNodes.push(listNode)
      }

      const depth = Math.min(listStructure.length + 1, match[1].length + 1)

      if (listStructure.length > depth) {
        listStructure = listStructure.slice(0, depth)
      } else if (listStructure.length < depth && listStructure[listStructure.length - 1].children.length) {
        const newListNode = createList([], LIST_BLOCK_ELEMENTS.UNORDERED_LIST)
        const listNode = listStructure[listStructure.length - 1]
        const listItem = listNode.children[listNode.children.length - 1]

        listItem.children[1] = newListNode
        listStructure.push(newListNode)
      }

      const listItem = createListItem([createListItemContent([getParagraphNode(match[2])])])
      const list = listStructure[listStructure.length - 1]

      list.children.push(listItem)
    }

    return editorNodes
  }
}
