import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Box, Button, Dialog, Typography } from "@mui/material"
import SaveIcon from "@mui/icons-material/SaveOutlined"
import { LoadingButton } from "@mui/lab"
import { RichTextEditor } from "common/form-components/rich-text/RichTextEditor"
import { theme } from "app/theme"
import { useShallow } from "zustand/react/shallow"
import { documentActions, useDocumentStore } from "documents/store"
import { StyledEditorMedicalSummaryWrapper } from "../styled"
import { Appointment, IcdCode, IcdCodeRelation } from "documents/types"
import { useDocumentStateStore } from "documents/store/documentState"
import { IcdCodesStack, IcdCodesStackProps } from "./IcdCodesStack"
import { v4 } from "uuid"
import { useExhibitsPreview } from "documents/exhibits/useExhibitPreview"
import { useUserExhibitPreview } from "documents/exhibits/useUserExhibitPreview"
import { previewCitationReference, getCitationReferences } from "documents/medical-summary/utils"
import { CitationElement } from "common/form-components/rich-text/CustomEditor"
import { exhibitSelectors } from "documents/store/exhibits"
import { userExhibitsSelectors } from "documents/store/userExhibits"
import { ProviderInputs } from "./ProviderInputs"
import { Relations } from "./Relations"
import {
  createList,
  createListItem,
  createParagraph,
  createText,
} from "common/form-components/rich-text/create"
import { LIST_BLOCK_ELEMENTS } from "common/form-components/rich-text/elements"

const DEFAULT_VALUE = [
  "Patient Complaints & Limitations",
  "HPI & Prior Medical History",
  "Past Tests & Findings",
  "Diagnostic Tests & Findings From This Visit",
  "Medical Diagnoses",
  "Past Treatments",
  "Treatments Administered During This Visit",
  "Treatment Recommendations",
  "Treatment Objectives & Goals",
  "Referrals",
  "Discharge / Discontinuation in Care",
  "Statements of Causality",
  "All Other Information",
].flatMap(text => [
  createParagraph([createText({ text, bold: true })]),
  createList([createListItem()], LIST_BLOCK_ELEMENTS.UNORDERED_LIST),
])

interface AddNewMedicalSummaryProps {
  open: boolean
  providerId?: Appointment["providerId"]
  handleClose: () => void
}

export const AddNewMedicalSummary = ({
  open,
  handleClose,
  providerId: initialProviderId = null,
}: AddNewMedicalSummaryProps) => {
  // store
  const isSaving = useDocumentStateStore(state => state.isSaving)
  const exhibits = useDocumentStore(useShallow(exhibitSelectors.getExhibitList))
  const userExhibits = useDocumentStore(useShallow(userExhibitsSelectors.getUserExhibitsList))

  const exhibitReferences = useMemo(
    () => getCitationReferences({ exhibits, userExhibits }),
    [exhibits, userExhibits]
  )

  // fields
  const [providerId, setProvider] = useState<Nullable<Appointment["providerId"]>>(initialProviderId)
  const [dateOfService, setDateOfService] = useState<Nullable<Appointment["dateOfService"]>>(null)
  const [icdCodes, setIcdCodes] = useState<IcdCode[]>([])
  const [relations, setRelations] = useState<Nullable<IcdCodeRelation>[]>([null])
  const summary = useRef(DEFAULT_VALUE)

  const isRelationsValid = useMemo(() => {
    return (
      relations.every(relation => relation && relation.page !== null && relation.partitionId !== null) &&
      relations.length > 0
    )
  }, [relations])
  const [isProviderValid, setIsProviderValid] = useState(false)
  const isValid = isProviderValid && isRelationsValid

  const handleProviderInputsChange = ({
    isValid,
    providerId,
    dateOfService,
  }: {
    isValid: boolean
    providerId: Nullable<string>
    dateOfService: Nullable<string>
  }) => {
    setProvider(providerId)
    setDateOfService(dateOfService)
    setIsProviderValid(isValid)
  }
  const clearState = useCallback(() => {
    setProvider(initialProviderId)
    setDateOfService(null)
    setRelations([null])
    summary.current = DEFAULT_VALUE
  }, [initialProviderId])

  // handlers
  const handleAppointmentCreate = async () => {
    if (!isValid) return

    await documentActions.createAppointment({
      dateOfService: dateOfService as string,
      providerId: providerId as string,
      icdCodes,
      summary: summary.current,
      relations: relations.filter(Boolean) as IcdCodeRelation[],
    })

    clearState()
    handleClose()
  }

  const handleIcdCodeChange = useCallback<IcdCodesStackProps["onChange"]>(({ id, ...value }) => {
    if (!id) {
      return setIcdCodes(icdCodes => [
        ...icdCodes,
        { id: v4(), ...value, appointmentId: "", description: "" },
      ])
    }
    return setIcdCodes(icdCodes =>
      icdCodes.map(icdCode =>
        icdCode.id === id ? { ...value, id, appointmentId: "", description: "" } : icdCode
      )
    )
  }, [])

  const handleIcdCodeDelete = useCallback<IcdCodesStackProps["onDelete"]>(icdCodeId => {
    return setIcdCodes(icdCodes => icdCodes.filter(icdCode => icdCode.id !== icdCodeId))
  }, [])

  const previewExhibit = useExhibitsPreview()
  const previewUserExhibit = useUserExhibitPreview()

  const handleExhibitPreview = useCallback(
    (citation: CitationElement, page: Nullable<number>) => {
      previewCitationReference({
        citation,
        page,
        previewExhibit,
        previewUserExhibit,
      })
    },
    [previewExhibit, previewUserExhibit]
  )

  // effects
  useEffect(() => {
    setProvider(initialProviderId)
  }, [initialProviderId])

  return (
    <Dialog maxWidth="md" open={open} onClose={!isSaving ? handleClose : undefined}>
      <Box p={2.5} width={745} borderRadius={1}>
        <Typography variant="h5" fontWeight={700}>
          Add New Medical Summary
        </Typography>
        <Box pt={3.75}>
          <Box mb={3.75} width={550} gap={3.75} display="flex" justifyContent="space-between" flexWrap="wrap">
            <ProviderInputs
              onChange={handleProviderInputsChange}
              initialProviderId={initialProviderId}
              renderProviderInput={input => (
                <Box width={260}>
                  <Box mb={0.5} fontWeight={700}>
                    Provider
                  </Box>
                  {input}
                </Box>
              )}
              renderDateOfServiceInput={input => (
                <Box width={260}>
                  <Box mb={0.5} fontWeight={700}>
                    Date of service
                  </Box>
                  {input}
                </Box>
              )}
              renderError={error => <Box width={550}>{error}</Box>}
            />
          </Box>
          <Box mb={3.75} width={550}>
            <Box mb={0.5} fontWeight={700}>
              ICD code
            </Box>
            <IcdCodesStack
              icdCodes={icdCodes}
              onChange={handleIcdCodeChange}
              onDelete={handleIcdCodeDelete}
            />
          </Box>
          <Box mb={3.75}>
            <Relations relations={relations} onChange={setRelations} />
          </Box>
          <StyledEditorMedicalSummaryWrapper>
            <RichTextEditor
              value={summary.current}
              onChange={value => (summary.current = value)}
              keepValue
              withCitations
              citations={exhibitReferences}
              onCitationClick={handleExhibitPreview}
            />
          </StyledEditorMedicalSummaryWrapper>
        </Box>
        <Box mt={3.75} gap={1.5} display="flex" justifyContent="flex-end">
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="secondary"
            sx={{ background: theme.palette.blue.primary }}
            disabled={!isValid}
            onClick={handleAppointmentCreate}
            loading={isSaving}
            loadingPosition={isSaving ? "start" : undefined}
            startIcon={isSaving && <SaveIcon />}
            data-test="add-medical-summary-button"
          >
            <span>Add Medical Summary</span>
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  )
}
