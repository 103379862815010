import { useCallback, useEffect, useId, useMemo } from "react"
import { useAlertModalStore } from "./store"
import { useShallow } from "zustand/react/shallow"

interface UseAlertModalProps {
  title: string
  content: React.ReactNode
  closeLabel?: string
  confirmLabel?: string
  onConfirm?: () => void | Promise<void>
  onClose?: () => void
}

export const useAlertModal = ({
  title,
  content,
  closeLabel = "Close",
  confirmLabel,
  onConfirm,
  onClose,
}: UseAlertModalProps) => {
  const modalId = useId()
  const { setModalContent, setCurrentModalId } = useAlertModalStore(
    useShallow(({ setModalContent, setCurrentModalId }) => ({ setModalContent, setCurrentModalId }))
  )

  const openModal = useCallback(() => {
    setCurrentModalId(modalId)
  }, [setCurrentModalId, modalId])

  useEffect(() => {
    setModalContent(modalId, {
      title,
      content,
      closeLabel,
      confirmLabel,
      onClose,
      onConfirm,
    })
  }, [setModalContent, title, content, closeLabel, confirmLabel, modalId, onConfirm, onClose])

  return useMemo(() => ({ openModal }), [openModal])
}
