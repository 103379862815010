import { useForm } from "react-hook-form"
import * as Sentry from "@sentry/browser"
import { useProviderSummariesActions, useProviderSummariesStore } from "./store"
import { ProviderMedicalProfessional } from "api/services/provider-summaries/types"
import { MedicalProfessionalForm } from "./MedicalProfessionalForm"
import { MedicalSummaryResult } from "./MedicalSummaryResult"
import { InlineRegenerateButton, SummaryButtonsContainer, SummaryTabContent } from "./styled"
import { useCallback, useRef } from "react"
import { Alert, Box, Stack, Typography } from "@mui/material"
import Button from "evenup-ui/Button"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { IN_PROGRESS_STATES } from "./constants"
import { copyToClipboardEditorText } from "common/helpers/copy"
import { CustomEditor } from "common/form-components/rich-text/CustomEditor"
import { PROVIDER_SUMMARY_STATUS } from "api/services/summaries/types"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"

export function MedicalSumaryTab({
  dateOfService,
  showStatus,
}: {
  dateOfService: string
  showStatus: boolean
}) {
  const medicalProfessional = useProviderSummariesStore(
    state => state.medicalSummaries.get(dateOfService)?.medicalProfessional
  )
  const hasGeneratedSummary = useProviderSummariesStore(
    state => !!state.medicalSummaries.get(dateOfService)?.summaryContent
  )
  const hasSummary = useProviderSummariesStore(
    state => !!state.medicalSummaries.get(dateOfService)?.summaryResultId
  )
  const isStale = useProviderSummariesStore(state => !!state.medicalSummaries.get(dateOfService)?.stale)
  const status = useProviderSummariesStore(state => state.medicalSummaries.get(dateOfService)?.status)
  const { regenerateMedicalSummary, regenerateMedicalSummaries } = useProviderSummariesActions()
  const { showErrorMessage, showSuccessMessage } = useHandleMessages()

  const editorRef = useRef<Nullable<CustomEditor>>(null)

  const { control, handleSubmit, reset } = useForm<ProviderMedicalProfessional>({
    defaultValues: medicalProfessional ?? {
      title: "",
      designation: "",
      lastName: "",
      firstName: "",
    },
  })

  const setMedicalProfessional = useCallback(
    (data: ProviderMedicalProfessional) => {
      reset(data, { keepDefaultValues: true })
    },
    [reset]
  )

  const handleSummaryRegenerate = handleSubmit(medicalProfessional => {
    regenerateMedicalSummary(dateOfService, medicalProfessional).catch(error => {
      Sentry.captureMessage("Failed to schedule summary regenration", error)
      showErrorMessage("Error occurred while trying to regenerate summary")
    })
  })

  const handleSummariesRegenerate = handleSubmit(() => {
    regenerateMedicalSummaries().catch(error => {
      Sentry.captureMessage("Failed to schedule summary regenration", error)
      showErrorMessage("Error occurred while trying to regenerate summary")
    })
  })

  const handleCopyClick = useCallback(() => {
    copyToClipboardEditorText(
      editorRef.current,
      () => {
        showSuccessMessage("Summary copied")
      },
      () => {
        showErrorMessage("There was an error copying the summary")
      }
    )
  }, [showErrorMessage, showSuccessMessage])

  return (
    <Stack spacing={1.5}>
      {!hasSummary && (
        <Alert severity="warning" sx={{ py: 0 }} icon={<AccessTimeIcon />}>
          <Typography variant="helper">
            Summary was not requested.
            <InlineRegenerateButton onClick={handleSummariesRegenerate}>
              Generate Missing Summaries
            </InlineRegenerateButton>
          </Typography>
        </Alert>
      )}
      {(status === PROVIDER_SUMMARY_STATUS.HUNG || status === PROVIDER_SUMMARY_STATUS.ERROR) && (
        <Alert severity="error" sx={{ py: 0 }}>
          <Typography variant="helper">
            Summary Could Not Generate.{" "}
            <InlineRegenerateButton onClick={handleSummaryRegenerate}>Regenerate</InlineRegenerateButton>
          </Typography>
        </Alert>
      )}
      {status && IN_PROGRESS_STATES.includes(status) && (
        <Alert severity="warning" sx={{ py: 0 }} icon={<AccessTimeIcon />}>
          <Typography variant="helper">Summary Generation Pending.</Typography>
        </Alert>
      )}
      {status === PROVIDER_SUMMARY_STATUS.SUCCESS && showStatus && (
        <Alert severity="success" sx={{ py: 0 }} icon={<AccessTimeIcon />}>
          <Typography variant="helper">New Summary Generated</Typography>
        </Alert>
      )}
      {status === PROVIDER_SUMMARY_STATUS.SUCCESS && !showStatus && isStale && (
        <Alert severity="warning" sx={{ py: 0 }} icon={<ErrorOutlineIcon />}>
          <Typography variant="helper">
            New Summary available.{" "}
            <InlineRegenerateButton onClick={handleSummaryRegenerate}>
              Generate New Summary
            </InlineRegenerateButton>
          </Typography>
        </Alert>
      )}
      <SummaryTabContent>
        <MedicalProfessionalForm
          control={control}
          dateOfService={dateOfService}
          setMedicalProfessional={setMedicalProfessional}
        />
        <Box sx={{ flexGrow: 1 }}>
          <MedicalSummaryResult
            dateOfService={dateOfService}
            editorRef={editor => (editorRef.current = editor)}
          />
          <SummaryButtonsContainer>
            <Button
              disableElevation
              color="primary"
              size="small"
              onClick={handleSummaryRegenerate}
              disabled={!status || IN_PROGRESS_STATES.includes(status)}
            >
              Regenerate
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              disabled={!hasGeneratedSummary}
              onClick={handleCopyClick}
            >
              Copy Text
            </Button>
          </SummaryButtonsContainer>
        </Box>
      </SummaryTabContent>
    </Stack>
  )
}
