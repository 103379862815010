import { PropsWithChildren, useEffect } from "react"
import { useDocumentContext } from "./context"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { documentActions, resetDocumentData, setDocumentData } from "./store"
import { useSuspenseQuery } from "@suspensive/react-query"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { documentsService } from "api/services/documents"
import { exhibitBuilderService } from "exhibit-builder/api/service"

export function DocumentData({ children }: PropsWithChildren): Nullable<JSX.Element> {
  const { documentId } = useDocumentContext()
  const queryClient = useQueryClient()

  const { data } = useSuspenseQuery(
    [queryKeys.documentData, documentId],
    () => documentsService.getDocumentEntities({ documentId }),
    { meta: SILENT_QUERY_PARAMS.meta }
  )

  const { data: medchronTile } = useQuery(
    [queryKeys.documentMedchronTile, documentId],
    () => documentsService.getMedchronTile({ documentId: documentId }),
    {
      ...SILENT_QUERY_PARAMS,
      enabled: !!documentId,
    }
  )

  const tileUseExhibitBuilder = !!medchronTile?.useExhibitBuilder

  const { data: userExhibitData } = useQuery(
    [queryKeys.documentStoreUserExhibitData, documentId],
    () => exhibitBuilderService.getExhibitBuilderData({ documentId }),
    {
      enabled: tileUseExhibitBuilder,
    }
  )

  useEffect(() => {
    documentActions.setDocumentId(documentId)
  }, [documentId])

  useEffect(() => {
    setDocumentData(data)
  }, [data])

  useEffect(() => {
    if (userExhibitData) {
      const {
        userExhibitMap,
        userExhibitOrder,
        exhibitPartitionMap: userExhibitPartitionMap,
        exhibitPartitionOrder: userExhibitPartitionOrder,
      } = userExhibitData
      documentActions.setUserExhibitData({
        userExhibitMap,
        userExhibitOrder,
        userExhibitPartitionMap,
        userExhibitPartitionOrder,
      })
    }
  }, [userExhibitData])

  useEffect(() => {
    if (documentId) {
      return () => {
        resetDocumentData()
        requestAnimationFrame(() => {
          queryClient.removeQueries([queryKeys.documentData, documentId])
        })
      }
    }
  }, [queryClient, documentId])

  return <>{children}</>
}
