import { ReactNode } from "react"
import Checkbox from "@mui/material/Checkbox"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import { Box, IconButton } from "@mui/material"

interface MedicalProfessionalRowProps {
  children: React.ReactNode
  checked: boolean
  handleClick: () => void
  handleEdit: () => void
  disabledEditing: boolean
  hideEditButton?: boolean
}

export function MedicalProfessionalRow({
  children,
  checked,
  handleClick,
  handleEdit,
  disabledEditing,
  hideEditButton = false,
}: MedicalProfessionalRowProps): ReactNode {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center" gap={1.5} overflow="hidden" ml={-0.5}>
        <Checkbox
          onClick={handleClick}
          checked={checked}
          size="small"
          sx={{ borderRadius: "3px", width: "24px", height: "24px" }}
        />
        {children}
      </Box>
      {!hideEditButton && (
        <IconButton
          size="small"
          sx={{ borderRadius: "3px", width: "24px", height: "24px" }}
          onClick={handleEdit}
          disabled={disabledEditing}
        >
          <EditOutlinedIcon sx={{ fontSize: "16px" }} />
        </IconButton>
      )}
    </Box>
  )
}
