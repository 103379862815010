import { RevisionRequestEventList } from "common/models/revision-request-event"
import { User } from "common/models/user"
import { canUserCreateRevisionRequest, canUserViewRevisions } from "requests/permissions/requestAction"
import { DefendantDto, RequestViewDto } from "./types"
import { PartialDefendant } from "./ViewRequestRevamp/types"
import { FileToUpload } from "common/file-management/types"
import { DEMAND_TYPES, DOCUMENT_TYPES, MED_CHRON_TYPES } from "requests/enums"

export function canUserViewRevisionsTab(
  request: RequestViewDto,
  user: User,
  events: RevisionRequestEventList | undefined,
  areRevisionsLoading: boolean
): boolean {
  // User should have permission at least to view revisions
  if (!canUserViewRevisions(user.role)) return false

  // In case we have no request there is nothing to show
  if (!request) return false

  // User can create revision in current state
  if (canUserCreateRevisionRequest(user.role, request)) return true

  // User can not create revision (above) and request has no revisions
  if (request.revised) return true

  // Request has revisions but events are not loaded
  if (areRevisionsLoading) return true

  // There is active (not completed or cancelled) revision event
  return events != undefined && events.hasActiveEvent
}

// BI and self-insured policy coverage types:
// - use first name, last name and pronoun for individual type defendant, as individuals typically have a first name, last name and pronoun
// - use name for entity type defendant, since entities don't have a first and last name
// UM and UIM policy coverage types:
// - use last name for individual type defendant (not sure why it was implemented this way)
// - use last name for entity type defendant
// Note: UM and UIM are typically entities, but we don't prevent a user from picking individual type of defendant for UM and UIM
// Note: The current implementation is not ideal, and we probaly should be using a single name field to collect the name for all
//       defendant types to simplify the logic
export function getDefendantName(defendant: PartialDefendant | DefendantDto): string {
  if (defendant.name) {
    return defendant.name
  } else if (defendant.first_name && defendant.last_name) {
    return `${defendant.first_name} ${defendant.last_name}`
  } else if (defendant.last_name) {
    return defendant.last_name
  }

  return EMPTY_DEFENDANT_NAME
}

export const EMPTY_DEFENDANT_NAME = "-"

export const isNameInUploadFile = (file: FileToUpload, firstName: string, lastName: string): boolean => {
  const filename = file.file.name.toLowerCase()
  const firstNameSplit = firstName.trim().toLocaleLowerCase().split(" ")
  const lastNameSplit = lastName.trim().toLocaleLowerCase().split(" ")

  const allFirstNamesIncluded = firstNameSplit.every(namePart => filename.includes(namePart))
  const allLastNamesIncluded = lastNameSplit.every(namePart => filename.includes(namePart))

  return allFirstNamesIncluded && allLastNamesIncluded
}

export const isRequestDemandType = (requestType: DOCUMENT_TYPES): boolean => {
  return DEMAND_TYPES.includes(requestType)
}

export const isRequestMedChronType = (requestType: DOCUMENT_TYPES): boolean => {
  return MED_CHRON_TYPES.includes(requestType)
}
