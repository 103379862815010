import React, { useCallback, useMemo, useState } from "react"
import { ReactEditor, useSlate } from "slate-react"
import { DraggablePopover } from "evenup-ui/DraggablePopover"
import { Editor } from "common/form-components/rich-text/Editor"
import { Editor as WithCitationsEditor } from "common/form-components/rich-text/features/citations/Editor"
import { AutocompleteWrapper, StyledToolbarGroup } from "../styled"
import TextButton from "common/buttons/TextButton"
import { PreviewDemandIcon } from "common/assets"
import { Autocomplete, Box, Button, TextField } from "@mui/material"
import { Citation, CitationSource } from "common/form-components/rich-text/features/citations/types"
import { range } from "lodash"
import { SortableRangeInput } from "evenup-ui/SortableRangeInput/SortableRangeInput"
import { RangeItem } from "evenup-ui/SortableRangeInput/types"

const EMPTY_REFERENCES: CitationSource[] = []

export function AddCitationButton({
  isCitationsEnabled,
}: {
  isCitationsEnabled: Nullable<boolean>
}): Nullable<JSX.Element> {
  const editor = useSlate()
  const disabled = !isCitationsEnabled

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [reference, setReference] = useState<Nullable<CitationSource>>(null)
  const [pages, setPages] = useState<Nullable<number>[]>([])

  const handleReferenceChange = useCallback((_: React.SyntheticEvent, value: Nullable<CitationSource>) => {
    setReference(value)
    setPages([])
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
    setReference(null)
    setPages([])
    requestAnimationFrame(() => ReactEditor.focus(editor))
  }, [editor])

  const handleOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const isCitationsEditor = Editor.isCitationsEditor(editor)

  const references = isCitationsEditor ? editor.citations.getItems() : EMPTY_REFERENCES

  // Assuming, that  if user exhibit refs exist, we should use only them
  const isUserExhibitRefExist = useMemo(() => references.some(ref => ref.userExhibitId), [references])
  const filteredReferences = useMemo(
    () => (isUserExhibitRefExist ? references.filter(ref => ref.userExhibitId) : references),
    [references, isUserExhibitRefExist]
  )

  const options = useMemo(
    () => filteredReferences.filter(reference => !!reference.pagesCount),
    [filteredReferences]
  )
  const rangeOptions = useMemo(() => reference && range(1, reference.pagesCount + 1), [reference])
  const getOptionLabel = useCallback((reference: CitationSource) => reference.name, [])

  const canAdd = !!reference && pages.some(page => !!page)

  const handleAddCitation = useCallback(() => {
    if (!canAdd || !Editor.isCitationsEditor(editor)) return

    const newCitation = { pages: pages.filter((page): page is number => !!page) } as Citation

    if (reference.exhibitId) {
      newCitation.exhibit = reference.exhibitId
    }

    if (reference.userExhibitId) {
      newCitation.userExhibitId = reference.userExhibitId
    }

    WithCitationsEditor.insertCitation(editor, newCitation)

    handleClose()
  }, [handleClose, editor, canAdd, reference, pages])

  const changeRange = useCallback((ranges: RangeItem[]) => {
    const pagesArray: number[] = []
    ranges.forEach((range: RangeItem) => {
      for (let i = Number(range.startPage); i <= Number(range.endPage); i++) {
        pagesArray.push(i)
      }
    })
    setPages(pagesArray)
  }, [])

  const currentRange = useMemo(() => {
    if (rangeOptions === null) return { start: 1, end: 1 }
    return { start: rangeOptions[0], end: rangeOptions[rangeOptions.length - 1] }
  }, [rangeOptions])

  return useMemo(() => {
    if (!Editor.isCitationsEditor(editor) || isCitationsEnabled === null) {
      return null
    }

    return (
      <>
        <StyledToolbarGroup>
          <TextButton
            size="small"
            disabled={disabled}
            onClick={handleOpen}
            startIcon={<PreviewDemandIcon height={20} />}
          >
            Add Exhibit Reference
          </TextButton>
        </StyledToolbarGroup>

        <DraggablePopover
          open={!!anchorEl}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
          title="Add Exhibit Reference"
          titleIcon={<PreviewDemandIcon height={30} />}
        >
          <AutocompleteWrapper sx={{ minHeight: "300px" }}>
            <Box mb={3.75}>
              <Box>
                <Box mb={1} fontWeight={600}>
                  Exhibit
                </Box>
                <Autocomplete
                  size="small"
                  disablePortal
                  sx={{ width: 460 }}
                  id="Exhibit_reference"
                  options={options}
                  renderInput={params => <TextField {...params} label="Enter exhibit name/number" />}
                  getOptionLabel={getOptionLabel}
                  renderOption={(props, option) => (
                    <li {...props} style={{ whiteSpace: "nowrap" }}>
                      {getOptionLabel(option)}
                    </li>
                  )}
                  selectOnFocus
                  clearOnBlur
                  value={reference}
                  onChange={handleReferenceChange}
                />
              </Box>
            </Box>
            {rangeOptions && (
              <Box mb={3.75}>
                <Box width={260}>
                  <Box mb={1} fontWeight={600}>
                    Pages
                  </Box>
                  <SortableRangeInput range={currentRange} onChange={changeRange} />
                </Box>
              </Box>
            )}
          </AutocompleteWrapper>
          <Box mt={3.75} gap={1.5} display="flex" justifyContent="flex-end">
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" color="secondary" disabled={!canAdd} onClick={handleAddCitation}>
              Add Exhibit Reference
            </Button>
          </Box>
        </DraggablePopover>
      </>
    )
  }, [
    editor,
    disabled,
    anchorEl,
    reference,
    handleReferenceChange,
    handleOpen,
    handleClose,
    options,
    rangeOptions,
    getOptionLabel,
    canAdd,
    handleAddCitation,
    isCitationsEnabled,
    changeRange,
    currentRange,
  ])
}
