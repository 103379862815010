import { Box, FormControlLabel, Switch, Typography } from "@mui/material"
import { Container, InputContainer } from "./styled"
import { useForm, useWatch } from "react-hook-form"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { useShallow } from "zustand/react/shallow"
import { useFirm } from "hooks/useFirm"
import useCase from "hooks/useCase"
import { EXHIBIT_GROUPING_VALUES, EXHIBIT_SORTING_VALUES } from "settings/Firm/enums"
import { SelectInput } from "common/form-components"
import { EXHIBIT_GROUPING_LABELS, EXHIBIT_SORTING_OPTIONS } from "settings/Firm/constants"
import { ActionButton, useAction } from "exhibit-builder/ActionButton"
import { ArrangeExhibitOptions } from "exhibit-builder/store/types"
import { useEffect } from "react"
import { checkValidUserExhibit } from "exhibit-builder/utils"
import { useMutation } from "@tanstack/react-query"
import { caseService } from "api/services/case"
import { updateShouldCombineExhibits } from "api"
import { UnhandledExhibitsBanner } from "./components"
import { useUnhandledExhibits } from "./hooks"
import { useAlertModal } from "exhibit-builder/AlertModal"

const GROUPING_OPTIONS = [
  EXHIBIT_GROUPING_VALUES.PER_PROVIDER_AND_FILETYPE,
  EXHIBIT_GROUPING_VALUES.PER_PROVIDER,
  EXHIBIT_GROUPING_VALUES.ONE_FILE,
]
const GROUPING_OPTIONS_ITEMS = GROUPING_OPTIONS.map(option => ({
  key: option,
  display: EXHIBIT_GROUPING_LABELS[option],
}))

function shouldShowOneFilePerProviderOption(sortingOption: EXHIBIT_SORTING_VALUES) {
  return [
    EXHIBIT_SORTING_VALUES.PER_PROVIDER_RECORDS_AND_BILLS,
    EXHIBIT_SORTING_VALUES.PER_PROVIDER_BILLS_AND_RECORDS,
  ].includes(sortingOption)
}

export function SortMenu() {
  const caseId = useExhibitBuilderStore(useShallow(state => state.caseId))
  const { caseObj, updateCache } = useCase(caseId)

  const { firm } = useFirm(caseObj?.firm?.pk)
  const invalidUserExhibits = useExhibitBuilderStore(state => {
    const exhibits: {
      index: number
      missingPlaintiff?: boolean
      missingSubDocType?: boolean
      missingSortingProvider?: boolean
    }[] = []

    state.userExhibitOrder.forEach(exhibitId => {
      const exhibit = state.userExhibitMap[exhibitId]

      const { isValid, missingPlaintiff, missingSubDocType, missingSortingProvider } =
        checkValidUserExhibit(exhibit)

      if (isValid) return

      exhibits.push({
        index: exhibit.index,
        missingPlaintiff,
        missingSubDocType,
        missingSortingProvider,
      })
    })

    return exhibits
  })

  const {
    isOpenUnhandledExhibitsBanner,
    handleCloseUnhandledExhibitsBanner,
    unhandledUserExhibits,
    handleFilterUnhandledExhibits,
    unhandledExhibitsBannerActionText,
  } = useUnhandledExhibits()

  const firmSortingOption = firm?.exhibit_sorting_option as ArrangeExhibitOptions["sortingOption"] | undefined
  const firmGroupingOption = firm?.exhibit_grouping_option as
    | ArrangeExhibitOptions["groupingOption"]
    | undefined

  const { control, setValue } = useForm({
    defaultValues: {
      sortingOption: firmSortingOption ?? EXHIBIT_SORTING_VALUES.PER_PROVIDER_RECORDS_AND_BILLS,
      groupingOption:
        firmGroupingOption && GROUPING_OPTIONS.includes(firmGroupingOption)
          ? firmGroupingOption
          : EXHIBIT_GROUPING_VALUES.PER_PROVIDER_AND_FILETYPE,
      addTitlePage: false,
      turnCaseFactsPhotographs: false,
      includeInlineImages: false,
    },
  })

  const selectedSortingOption = useWatch({ control, name: "sortingOption" })
  const selectedGroupingOption = useWatch({ control, name: "groupingOption" })

  useEffect(() => {
    if (firmSortingOption) {
      setValue("sortingOption", firmSortingOption)
    }

    if (firmGroupingOption && GROUPING_OPTIONS.includes(firmGroupingOption)) {
      setValue("groupingOption", firmGroupingOption)
    }
  }, [firm, setValue, firmSortingOption, firmGroupingOption])

  const { isLoading, handleAction } = useAction({
    name: "arrange exhibits",
    action: async () =>
      exhibitBuilderActions.arrangeExhibits({
        groupingOption: selectedGroupingOption,
        sortingOption: selectedSortingOption,
      }),
  })

  const updateCombineExhibits = () => {
    if (selectedGroupingOption === EXHIBIT_GROUPING_VALUES.ONE_FILE && !caseObj?.combine_exhibits) {
      return updateShouldCombineExhibits({ caseId, ce: true })
    }

    if (selectedGroupingOption !== EXHIBIT_GROUPING_VALUES.ONE_FILE && caseObj?.combine_exhibits) {
      return updateShouldCombineExhibits({ caseId, ce: false })
    }
  }

  const onFilterInvalidExhibits = () => {
    exhibitBuilderActions.setOnlyInvalidExhibits(true)
  }

  const { openModal } = useAlertModal({
    title: "Unable to Sort Exhibits",
    content: (
      <Box>
        <Typography variant="body1" lineHeight={1.5}>
          Missing information required for sorting: <br />
          {invalidUserExhibits.map(exhibit => (
            <Box key={exhibit.index}>
              <b>{`Exhibit ${exhibit.index + 1}: `}</b>
              {[
                exhibit.missingPlaintiff && "Plaintiff not assigned",
                exhibit.missingSubDocType && "Sub-doc type not assigned",
                exhibit.missingSortingProvider && "Sorting Provider not assigned",
              ]
                .filter(Boolean)
                .join(", ")}
            </Box>
          ))}
          <Box mt={4}>
            Please complete the missing information before attempting to sort and arrange the exhibits again
          </Box>
        </Typography>
      </Box>
    ),
    confirmLabel: "Review Affected Exhibits",
    onConfirm: onFilterInvalidExhibits,
  })

  const arrangeExhibit = async () => {
    if (invalidUserExhibits.length) {
      return openModal()
    }

    exhibitBuilderActions.setIsLoading(true)
    await handleAction()
    await updateCombineExhibits()
    exhibitBuilderActions.setIsLoading(false)
  }

  const { mutateAsync: mutateAddTitlePage } = useMutation(caseService.updateAddTitlePage, {
    onSuccess: data => {
      updateCache({ add_title_page: data.addTitlePage })
    },
  })

  useEffect(() => {
    if (
      !shouldShowOneFilePerProviderOption(selectedSortingOption) &&
      selectedGroupingOption === EXHIBIT_GROUPING_VALUES.PER_PROVIDER
    ) {
      setValue("groupingOption", EXHIBIT_GROUPING_VALUES.PER_PROVIDER_AND_FILETYPE)
    }
  }, [selectedSortingOption, selectedGroupingOption, setValue])

  const groupingOption = GROUPING_OPTIONS_ITEMS.filter(
    option =>
      option.key !== EXHIBIT_GROUPING_VALUES.PER_PROVIDER ||
      shouldShowOneFilePerProviderOption(selectedSortingOption)
  )

  useEffect(() => {
    if (!invalidUserExhibits?.length) exhibitBuilderActions.setOnlyInvalidExhibits(false)
  }, [invalidUserExhibits?.length])

  useEffect(() => () => exhibitBuilderActions.setOnlyInvalidExhibits(false), [])

  if (!caseObj) {
    return "no case found"
  }

  return (
    <Container>
      {isOpenUnhandledExhibitsBanner && (
        <UnhandledExhibitsBanner
          exhibitsCount={unhandledUserExhibits.length}
          onAction={handleFilterUnhandledExhibits}
          actionText={unhandledExhibitsBannerActionText}
          onClose={handleCloseUnhandledExhibitsBanner}
        />
      )}
      <InputContainer>
        <SelectInput
          size="small"
          label="Exhibit Sorting Rule"
          control={control}
          name="sortingOption"
          options={EXHIBIT_SORTING_OPTIONS}
          data-test="sorting-option"
        />

        <SelectInput
          size="small"
          label="Group Exhibit Option"
          control={control}
          name="groupingOption"
          options={groupingOption}
          data-test="grouping-option"
        />

        <FormControlLabel
          control={
            <Switch
              color="secondary"
              checked={Boolean(caseObj.add_title_page)}
              onChange={event =>
                mutateAddTitlePage({ caseId: caseObj.pk, data: { addTitlePage: event.target.checked } })
              }
            />
          }
          label={<Typography variant="caption">Add Title Page to Every Exhibit</Typography>}
        />
      </InputContainer>

      <ActionButton
        label="Sort and Group Exhibits"
        isLoading={isLoading}
        onClick={arrangeExhibit}
        data-test="sort-button"
      />
    </Container>
  )
}
