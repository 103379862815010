import { PlaintiffProps } from "requests/RequestForm/AdditionalInfoRevamp"
import { StyledInformationWrapper, StyledPlaintiffAdditionalInfo } from "../styled"
import { INJURY_IMPACTS_KEYS } from "requests/constants"
import { removeInjuryInInjuryImpactKey } from "requests/RequestForm/AdditionalInfoRevamp/utils"
import { InfoContainer } from "./InfoContainer"
import { NonEconomicDamagesChip } from "./NonEconomicDamagesChip"
import { DOCUMENT_TYPES } from "requests/enums"

export const NonEconomicDamages = ({
  plaintiff,
  documentType,
}: {
  plaintiff: PlaintiffProps
  documentType: DOCUMENT_TYPES
}) => {
  const isStandardDemand = documentType === "standard"
  return (
    <>
      <StyledInformationWrapper>
        {Object.values(INJURY_IMPACTS_KEYS).map(({ key, display, assetKey }) => {
          const nonEconomicDamageKey = removeInjuryInInjuryImpactKey(key)
          const nonEconomicDamage = plaintiff[nonEconomicDamageKey]

          return (
            nonEconomicDamage &&
            nonEconomicDamage?.length > 0 && (
              <InfoContainer
                key={key}
                field={display}
                value={
                  <NonEconomicDamagesChip
                    nonEconomicDamage={plaintiff[nonEconomicDamageKey] as string[]}
                    assetKey={assetKey ?? ""}
                  />
                }
              />
            )
          )
        })}
      </StyledInformationWrapper>

      <StyledPlaintiffAdditionalInfo>
        <InfoContainer field="Plaintiff's Ongoing Complaints" value={plaintiff.ongoing_complaints} />

        <InfoContainer field="Future Treatments" value={plaintiff.future_treatments} />
        {isStandardDemand && <InfoContainer field="Other Impacts" value={plaintiff.impacts_other} />}
      </StyledPlaintiffAdditionalInfo>
    </>
  )
}
