import { Tooltip } from "@mui/material"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { ComponentPropsWithRef, useCallback } from "react"
import { DialogActions, DialogContent } from "@mui/material"
import Button from "evenup-ui/Button"
import { StyledDialog, StyledDialogDescription, StyledDialogHeader } from "./styled"

interface UpdateSummariesButtonProps extends ComponentPropsWithRef<typeof Button> {
  size?: "small" | "medium" | "large"
  documentId: string
}

type UpdateSummariesConfirmationDialogProps = {
  open: boolean
  onClose: () => void
  navigateToMedicalSummary: () => void
  documentId: string
}

export const UpdateSummariesConfirmationDialog = ({
  open,
  onClose,
  documentId,
  navigateToMedicalSummary,
}: UpdateSummariesConfirmationDialogProps) => {
  const confirmAction = useCallback(() => {
    exhibitBuilderActions.regenerateSummaries({ documentId })
    navigateToMedicalSummary()
  }, [documentId, navigateToMedicalSummary])

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <StyledDialogHeader variant="dialogHeader" component="h3">
          Confirm to Update Summaries
        </StyledDialogHeader>
        <StyledDialogDescription component="p">
          Some changes made require appointments to be updated on the Medical Summary page. Confirm to update
          appointments and proceed to the Medical Summary.
        </StyledDialogDescription>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button color="primary" onClick={confirmAction}>
          Confirm Updates
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export function UpdateSummariesButton({ size = "medium", documentId, ...rest }: UpdateSummariesButtonProps) {
  const shouldUpdateSummaries = useExhibitBuilderStore(state => state.shouldUpdateSummaries)

  const handleClick = useCallback(() => {
    if (shouldUpdateSummaries) {
      exhibitBuilderActions.regenerateSummaries({ documentId })
    }
  }, [shouldUpdateSummaries, documentId])

  const button = (
    <Button disabled={!shouldUpdateSummaries} color="primary" size={size} onClick={handleClick} {...rest}>
      Update Summaries
    </Button>
  )

  return (
    <>
      {!shouldUpdateSummaries ? (
        <Tooltip title="Import Provider and Date of Service changes require updates to Medical Summaries">
          <div>{button}</div>
        </Tooltip>
      ) : (
        <>{button}</>
      )}
    </>
  )
}
