import React from "react"
import { Outlet, useNavigate } from "react-router-dom"
import styled from "@emotion/styled"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import LinkButton from "../buttons/LinkButton"
import { usePageStore } from "./pageStore"
import { MEDICAL_SUMMARY_FILTERS_WRAPPER_ID } from "documents/components/MedChronFilters"
import { isNull } from "lodash"

const StyledPage = styled("div")<{ showPaddings: boolean }>(({ theme, showPaddings }) => ({
  padding: theme.spacing(6, showPaddings ? 7 : 0, 2),
  transition: "0.2s",

  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(6, 5, 2),
  },
}))

const StyledPageContent = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 0),
}))

const BackButton = styled(LinkButton)(({ theme }) => ({
  fontSize: "14px",
  display: "inline-flex",
  alignItems: "center",
  textDecoration: "none",
  color: theme.palette.blue.primary,

  marginLeft: "-6px",

  "&:hover": {
    textDecoration: "underline",
  },
}))

export const BASE_OUTER_PAGE_ID = "base_outer_page_id"

export const BasePage = React.memo(function BasePage(): Nullable<JSX.Element> {
  const navigate = useNavigate()
  const { showBack, backHref, backTitle } = usePageStore()
  const medicalSummaryFiltersWrapperElement = document.getElementById(MEDICAL_SUMMARY_FILTERS_WRAPPER_ID)

  return (
    <StyledPage id={BASE_OUTER_PAGE_ID} showPaddings={!!medicalSummaryFiltersWrapperElement}>
      {showBack && !isNull(backTitle) && (
        <BackButton small onClick={() => (backHref ? navigate(backHref) : navigate(-1))}>
          <ChevronLeftIcon sx={{ width: "20px", height: "20px" }} />
          {backTitle || "Back"}
        </BackButton>
      )}
      <StyledPageContent>
        <Outlet />
      </StyledPageContent>
    </StyledPage>
  )
})
