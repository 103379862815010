import { useCallback } from "react"
import { useFormContext } from "react-hook-form"
import { queryKeys } from "react-query/constants"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Box } from "@mui/material"

import { ApiError } from "apiHelper"
import { deleteRequestFile, fetchRequestFiles, uploadRequestFile } from "api"
import { NO_CONTRACT_FOUND_EXCEPTION, NO_CURRENT_CONTRACT_EXCEPTION } from "requests/constants"
import { SUPPORT_EMAIL } from "common/constants"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { FileManagement } from "common/file-management/FileManagement"
import {
  DeleteFileMutation,
  DeleteFileMutationData,
  SaveFileMutation,
  SaveFileMutationData,
} from "common/file-management/types"
import { compileFileErrorMessage, getErrorMessageFromJSONStr } from "common/utils"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { RequestAnalyticEvent, RequestAnalyticsEventTypes } from "infrastructure/apm/events/requestEvents"
import { getFirstPlaintiffName } from "requests/plaintiffUtils"
import { RequestFields } from "requests/RequestForm/types"

interface RequestFileManagementProps {
  requestId: number
  matterId?: string
  isUploadingFiles: boolean
  setIsUploadingFiles: (isUploadingFiles: boolean) => void
}

export const RequestFileManagement = ({
  requestId,
  matterId,
  isUploadingFiles,
  setIsUploadingFiles,
}: RequestFileManagementProps) => {
  const { getValues, setValue } = useFormContext<RequestFields>()
  const { showErrorMessage } = useHandleMessages()
  const queryClient = useQueryClient()

  useQuery([queryKeys.requestFiles, requestId], fetchRequestFiles, {
    onSuccess: data => {
      setValue("files", data)
    },
  })

  const handleUploadError = useCallback(
    (fileName: string, reason: string | null) => {
      const defaultReason = "Please check the files are not empty or password protected"
      const message = compileFileErrorMessage(fileName, reason, defaultReason)
      showErrorMessage(message)
    },
    [showErrorMessage]
  )

  const saveFileMutation: SaveFileMutation = useMutation(
    (params: SaveFileMutationData) => uploadRequestFile({ ...params, requestId }),
    {
      onSuccess: () => {
        const request = getValues()
        queryClient.invalidateQueries([queryKeys.requestFiles, requestId])
        amplitudeApm.trackEvent(
          new RequestAnalyticEvent(RequestAnalyticsEventTypes.UploadedDocumentsToRequest, {
            plaintiff_name: getFirstPlaintiffName(request).fullName,
            request_id: String(requestId),
            matter_id: matterId,
            request_type: request.type,
          })
        )
      },
      onError: (error: ApiError, data: SaveFileMutationData) => {
        const errorType = error?.validationErrors?.type
        if (errorType === NO_CONTRACT_FOUND_EXCEPTION || errorType === NO_CURRENT_CONTRACT_EXCEPTION) {
          return showErrorMessage({
            message: (
              <Box>
                This firm does not have an active contract and is unable to submit requests at this time. If
                this is a mistake please email <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> and we
                will correct this issue as soon as possible.
              </Box>
            ),
          })
        }

        const reason = getErrorMessageFromJSONStr(error.message ?? null)
        handleUploadError(data.name, reason)
      },
    }
  )

  const deleteFileMutation: DeleteFileMutation = useMutation(
    (params: DeleteFileMutationData) => deleteRequestFile({ ...params, requestId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.requestFiles, requestId])
      },
    }
  )

  return (
    <FileManagement
      isUploadingFiles={isUploadingFiles}
      setIsUploadingFiles={setIsUploadingFiles}
      saveFileMutation={saveFileMutation}
      deleteFileMutation={deleteFileMutation}
    />
  )
}
