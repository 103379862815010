export type CamelCase<S extends string> = S extends `${infer P1}_${infer P2}${infer P3}`
  ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
  : Lowercase<S>

type BasicType = string | number | boolean | null | undefined

// Snake case to camel case
export type RawModel<T> = T extends BasicType
  ? T
  : T extends Array<infer U>
    ? RawModel<U>[]
    : // Explicitly handling Flavor here since it extends both BasicType & BaseObject
      T extends Flavor<string, infer V>
      ? Flavor<string, V>
      : T extends BaseObject
        ? { [K in keyof T as CamelCase<string & K>]: RawModel<T[K]> }
        : T

export function toCamelCase<T extends BaseObject>(
  data: T
): T extends (infer U)[] ? RawModel<U>[] : RawModel<T> {
  if (Array.isArray(data)) {
    return data.map(toCamelCase) as T extends (infer U)[] ? RawModel<U>[] : never
  }
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => {
      const [first, ...parts] = key.split("_")
      const camelCasePart: string[] = [
        first.toLowerCase(),
        ...parts.map(part => `${part[0].toUpperCase()}${part.substring(1).toLowerCase()}`),
      ]
      const camelCaseKey = camelCasePart.join("")

      // Recurse
      let newValue = value
      if (typeof value === "object" && value !== null) {
        newValue = toCamelCase(value)
      }
      return [camelCaseKey, newValue]
    })
  ) as T extends (infer U)[] ? RawModel<U>[] : RawModel<T>
}

export type CamelToSnakeCase<S extends string> = S extends `${infer P1}${infer P2}`
  ? `${P1 extends Capitalize<P1> ? "_" : ""}${Lowercase<P1>}${CamelToSnakeCase<P2>}`
  : S

export type KeysToSnakeCase<T> = {
  [K in keyof T as CamelToSnakeCase<string & K>]: T[K]
}

export function toSnakeCaseString(source: string): string {
  return source
    .replace(/([A-Z]+)/g, ",$1")
    .replace(/^,/, "")
    .replaceAll(",", "_")
    .toLowerCase()
}

export function toSnakeCase<T extends BaseObject>(
  data: T
): T extends (infer U)[] ? KeysToSnakeCase<U>[] : KeysToSnakeCase<T> {
  if (Array.isArray(data)) {
    return data.map(toSnakeCase) as T extends (infer U)[] ? KeysToSnakeCase<U>[] : never
  }
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => {
      const snakeCaseKey = toSnakeCaseString(key)
      const snakeCaseValue =
        !Array.isArray(value) && typeof value === "object" && value !== null ? toSnakeCase(value) : value

      return [snakeCaseKey, snakeCaseValue]
    })
  ) as T extends (infer U)[] ? KeysToSnakeCase<U>[] : KeysToSnakeCase<T>
}
