import { ReactNode, useCallback, useState } from "react"
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { isEmpty } from "lodash"
import { KeyboardArrowDown } from "@mui/icons-material"
import { StyledTableHeadCell, StyledViewTable } from "documents/styled"
import { FlagTableObject } from "./types"
import { FlagsTableRow } from "./FlagsTableRow"
import { StyledCollapseButton } from "./styled"

type FlagsTableProps = {
  flags: FlagTableObject[]
  canCollapse?: boolean
  displayControls?: boolean
}

export const FlagsTable = ({
  flags,
  canCollapse = false,
  displayControls = false,
}: FlagsTableProps): ReactNode => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const handleCollapseClick = useCallback(() => {
    setIsCollapsed(collapsed => !collapsed)
  }, [])

  return (
    <StyledViewTable sx={{ position: "relative" }}>
      <TableHead>
        <TableRow>
          <StyledTableHeadCell sx={{ width: "15%", border: isCollapsed ? "none" : undefined }}>
            Date of Service
          </StyledTableHeadCell>
          <StyledTableHeadCell sx={{ width: "15%", border: isCollapsed ? "none" : undefined }}>
            Provider
          </StyledTableHeadCell>
          <StyledTableHeadCell sx={{ width: "20%", border: isCollapsed ? "none" : undefined }}>
            Category
          </StyledTableHeadCell>
          <StyledTableHeadCell sx={{ width: "33%", border: isCollapsed ? "none" : undefined }}>
            Content
          </StyledTableHeadCell>
          <StyledTableHeadCell sx={{ width: "17%", border: isCollapsed ? "none" : undefined }}>
            Reference
          </StyledTableHeadCell>
          {canCollapse && (
            <th>
              <StyledCollapseButton isCollapsed={isCollapsed} onClick={handleCollapseClick}>
                <KeyboardArrowDown />
              </StyledCollapseButton>
            </th>
          )}
        </TableRow>
      </TableHead>
      {!isCollapsed && (
        <TableBody>
          {isEmpty(flags) && (
            <TableRow>
              <TableCell align="center" colSpan={4}>
                <b>There are no flags for the selected filters</b>
              </TableCell>
            </TableRow>
          )}
          {flags.map(flag => {
            return <FlagsTableRow flag={flag} key={flag.id} displayControls={displayControls} />
          })}
        </TableBody>
      )}
    </StyledViewTable>
  )
}
