import React, { createContext, JSXElementConstructor, ReactElement, SyntheticEvent } from "react"
import MuiTable from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Collapse from "@mui/material/Collapse"
import Box from "@mui/material/Box"
import styled from "@emotion/styled"
import { SxProps } from "@mui/material"
import { Theme } from "@emotion/react"

export type Alignment = "left" | "right" | "center"

export interface Column {
  id: string
  title: string
  sortable?: boolean
  align?: Alignment
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cellComponent?: (record: any, rowIndex: number, colIndex: number) => ReactElement | string | number
  hidden?: boolean
  sx?: SxProps<Theme>
}

const EmptyStateContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 1),
  fontWeight: 300,
  fontStyle: "italic",
  fontSize: "14px",
  letterSpacing: "-0.28px",
}))

const FullWidthCollapse = styled(Collapse)({
  width: "100%",
  columnSpan: "all",
})

// note: need to change boolean to number since styled components throws warning
// see: https://maximeblanc.fr/blog/how-to-fix-the-received-true-for-a-non-boolean-attribute-error/
// proper fix: https://styled-components.com/docs/faqs#why-am-i-getting-html-attribute-warnings
const HideWhenEmptyRow = styled(TableRow)(({ hide }: { hide: number }) => ({
  transition: "display 2s",
  padding: 0,
  display: hide ? "hidden" : "table-row",
}))

const ExpandedCell = styled(TableCell)({
  padding: 0,
})

interface Props {
  columns: Column[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  records: any[]
  sortDirection?: "asc" | "desc"
  sortField?: string
  onSortChange?: () => void
  summary?: Column[] | null
  emptyMessage?: string | React.ReactElement
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expandElement?: Nullable<ReactElement<any, string | JSXElementConstructor<any>>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expandedRows?: any[]
  dataTest?: string
  onRowClick?: (e: SyntheticEvent, rowIndex?: number) => void
}

const TableContext = createContext({})

interface ExpandedElementProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  element: ReactElement<any, string | JSXElementConstructor<any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  record: any
}
const ExpandedElement = ({ element, record }: ExpandedElementProps) => {
  const ExpandedElementClone = element ? React.cloneElement(element, { record }) : null

  return ExpandedElementClone
}

const Table = ({
  columns,
  records,
  sortDirection,
  sortField,
  onSortChange,
  summary = null,
  emptyMessage = "No Data",
  expandElement = null,
  expandedRows = [],
  onRowClick,
  dataTest = "table",
}: Props): JSX.Element => {
  return (
    <TableContext.Provider value={{ columns, records }}>
      <MuiTable data-test={dataTest}>
        <TableHead>
          <TableRow>
            {columns.map((column: Column, index: number) => {
              if (column.hidden) {
                return <React.Fragment key={index} />
              }
              const align = column?.align || "left"
              return column?.sortable === true ? (
                <TableCell key={index} align={align} sx={column.sx ?? {}}>
                  <TableSortLabel
                    active={sortField === column.id}
                    direction={sortDirection}
                    onClick={onSortChange}
                  >
                    {column.title}
                  </TableSortLabel>
                </TableCell>
              ) : (
                <TableCell key={index} align={align} sx={column.sx ?? {}}>
                  {column.title}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        {records?.length ? (
          <TableBody>
            {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              records.map((record: any, rowIndex: number) => {
                return (
                  <React.Fragment key={rowIndex}>
                    <TableRow
                      hover
                      sx={onRowClick && { cursor: "pointer" }}
                      onClick={e => onRowClick && onRowClick(e, rowIndex)}
                    >
                      {columns.map((column: Column, cellIndex: number) => {
                        if (column.hidden) {
                          return <React.Fragment key={cellIndex} />
                        }
                        return (
                          <TableCell
                            key={cellIndex}
                            align={column?.align || "left"}
                            data-test={`column-id-${column.id}`}
                          >
                            {column.cellComponent ? (
                              column.cellComponent(record, rowIndex, cellIndex)
                            ) : (
                              <Box>{"N/A"}</Box>
                            )}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                    {/* TOOD: Right now we are using record.pk, probably don't want that long term */}
                    {!!expandElement && (
                      <HideWhenEmptyRow
                        hide={+!expandedRows.includes(record.pk)}
                        sx={{ visibility: expandedRows.includes(record.pk) ? "visible" : "hidden" }}
                      >
                        <ExpandedCell colSpan={columns.length}>
                          <FullWidthCollapse in={expandedRows.includes(record.pk)}>
                            <ExpandedElement element={expandElement} record={record} />
                          </FullWidthCollapse>
                        </ExpandedCell>
                      </HideWhenEmptyRow>
                    )}
                  </React.Fragment>
                )
              })
            }
            {summary && (
              <TableRow>
                {summary.map((column: Column, index: number) => {
                  const align = column?.align || "left"
                  return (
                    <TableCell key={index} align={align}>
                      <b>
                        {column.cellComponent ? column.cellComponent(records, index, 0) : <Box>{"N/A"}</Box>}
                      </b>
                    </TableCell>
                  )
                })}
              </TableRow>
            )}
          </TableBody>
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <EmptyStateContainer>{emptyMessage}</EmptyStateContainer>
            </TableCell>
          </TableRow>
        )}
      </MuiTable>
    </TableContext.Provider>
  )
}

export { Table as default }
