import { ReactNode, useCallback, useState } from "react"
import { MedicalProfessional } from "api/services/documents/types"
import { Box, TextField } from "@mui/material"
import { MedicalProfessionalKeys } from "./MedicalProfessionalSelect"
import TextButton from "common/buttons/TextButton"

interface MedicalProfessionalEditRowProps {
  onSave: (data: Pick<MedicalProfessional, MedicalProfessionalKeys>) => void
  onCancel: () => void
  medicalProfessional?: Pick<MedicalProfessional, MedicalProfessionalKeys>
}

const defaultMedicalProfessional = { firstName: "", lastName: "", title: "" }

export function MedicalProfessionalEditRow({
  onSave,
  onCancel,
  medicalProfessional = defaultMedicalProfessional,
}: MedicalProfessionalEditRowProps): ReactNode {
  const [medProfesional, setMedProffesional] = useState(medicalProfessional)

  const handleSave = useCallback(() => {
    onSave(medProfesional)
  }, [medProfesional, onSave])

  const handleChange = (key: MedicalProfessionalKeys) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setMedProffesional({ ...medProfesional, [key]: event.target.value })
  }

  const disabled = !(medProfesional.firstName && medProfesional.lastName && medProfesional.title)

  return (
    <Box display="flex" gap={1} mt={1}>
      <TextField
        size="small"
        sx={{ width: "200px" }}
        value={medProfesional.firstName}
        onChange={handleChange("firstName")}
        label="First Name"
      />
      <TextField
        size="small"
        sx={{ width: "200px" }}
        value={medProfesional.lastName}
        onChange={handleChange("lastName")}
        label="Last Name"
      />
      <TextField
        size="small"
        sx={{ width: "60px" }}
        value={medProfesional.title}
        onChange={handleChange("title")}
        label="Title"
      />
      <TextButton disabled={disabled} textColor="primaryBlue" onClick={handleSave}>
        SAVE
      </TextButton>
      <TextButton textColor="grey" onClick={onCancel}>
        CANCEL
      </TextButton>
    </Box>
  )
}
