import { MedicalProfessional } from "common/types/medicalProfessional"
import { FiltersData, GetState, MED_SUMMARY_SORTING, SetState } from "./types"

export type FiltersDataSlice = FiltersData

export const filtersDataSlice: FiltersDataSlice = {
  filtersStartDate: null,
  filtersEndDate: null,
  sorting: MED_SUMMARY_SORTING.PROVIDER,
  filtersProviderIds: [],
  filtersMedicalProfessionalIds: [],
  filtersReviewAction: null,
}

// eslint-disable-next-line
export const filtersDataActions = (set: SetState<FiltersDataSlice>, _: GetState<FiltersDataSlice>) => ({
  setFiltersStartDate: (filtersStartDate: FiltersData["filtersStartDate"]) => set({ filtersStartDate }),
  setFiltersEndDate: (filtersEndDate: FiltersData["filtersEndDate"]) => set({ filtersEndDate }),
  setFiltersReviewAction: (filtersReviewAction: FiltersData["filtersReviewAction"]) =>
    set({ filtersReviewAction }),
  setSorting: (sorting: FiltersData["sorting"]) => set({ sorting }),
  setFiltersProviderIds: (filtersProviderIds: FiltersData["filtersProviderIds"]) =>
    set({ filtersProviderIds }),
  setFiltersMedicalProfessionalIds: (filtersMedicalProfessionalIds: MedicalProfessional["id"][]) =>
    set({ filtersMedicalProfessionalIds }),
  resetFilters: () => set(filtersDataSlice),
})
