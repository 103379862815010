import { ReactNode, useRef } from "react"
import { StoreApi } from "zustand"
import { createAlertModalStore, AlertModalContext, type AlertModalStore } from "./store"

export const AlertModalProvider = ({ children }: { children: ReactNode }) => {
  const storeRef = useRef<StoreApi<AlertModalStore>>()

  if (!storeRef.current) {
    storeRef.current = createAlertModalStore()
  }

  return <AlertModalContext.Provider value={storeRef.current}>{children}</AlertModalContext.Provider>
}
