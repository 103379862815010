import styled from "@emotion/styled"
import { Box, Button, IconButton, Paper, Popper, TextField, autocompleteClasses } from "@mui/material"
import { HEADER_NAV_HEIGHT_SPACE_MULTIPLIER } from "app/constants"

interface StyledFiltersWrapperProps {
  collapsed: boolean
}

export const StyledFiltersWrapper = styled("div")<StyledFiltersWrapperProps>(({ theme, collapsed }) => ({
  position: "fixed",
  left: 0,
  padding: theme.spacing(2, 3),
  top: theme.spacing(HEADER_NAV_HEIGHT_SPACE_MULTIPLIER),
  height: "calc(100vh - 80px)",
  width: collapsed ? "60px" : "285px",
  border: "1px solid #EDECEC",
  borderLeftWidth: "0px",
  background: "#FDFDFD",
  transition: "0.2s",
  overflow: "hidden",
  textWrap: "nowrap",
  cursor: collapsed ? "pointer" : "auto",

  ":hover": {
    boxShadow: collapsed ? "1px 0px 6px 0px rgba(0, 0, 0, 0.14)" : "none",
  },
}))

export const StyledTitle = styled("div")(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 600,
  paddingBottom: theme.spacing(3),
}))

export const StyledListWrapper = styled("div")<{ readOnly?: boolean }>(({ theme, readOnly }) => ({
  gap: theme.spacing(1),
  padding: theme.spacing(0.5),
  paddingRight: theme.spacing(1),
  marginLeft: theme.spacing(-0.5),
  display: "flex",
  cursor: readOnly ? undefined : "pointer",
  flexDirection: "column",
  alignItems: "flex-start",
  borderRadius: theme.spacing(0.5),

  ":hover": {
    backgroundColor: readOnly ? undefined : "#091E420F",
  },
}))

export const StyledIconButton = styled(IconButton)({
  position: "absolute",
  top: "5px",
  transition: "0.2s",
})

export const StyledProviderFilterWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
}))

export const ResetButton = styled(Button)(({ theme }) => ({
  fontSize: "13px",
  textDecoration: "underline",
  color: theme.palette.blue.primary,
  marginLeft: "auto",

  ":hover": {
    textDecoration: "underline",
  },
}))

export const StyledPopper = styled(Popper)(({ theme }) => ({
  boxShadow: theme.shadows[5],
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  background: theme.palette.common.white,
  padding: theme.spacing(2, 2.5),

  [`& .${autocompleteClasses.popper}`]: {
    minWidth: "100%",
    maxWidth: "100%",
  },
}))

export const FloatingIndicator = styled(Paper)(({ theme }) => ({
  position: "fixed",
  bottom: 20,
  padding: theme.spacing(1, 2),
  margin: theme.spacing(0, -2),

  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.secondary.contrastText,
}))

export const StyledMedicalProfessionalFullName = styled(Box)(() => ({
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  cursor: "pointer",
  letterSpacing: "0px",
}))

export const StyledTextField = styled(TextField)(() => ({}))
StyledTextField.defaultProps = {
  fullWidth: true,
  size: "small",
  id: "outlined-basic",
  variant: "outlined",
  autoComplete: "off",
}
